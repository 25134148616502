import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress, ClickAwayListener, Dialog as MaterialDialog, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useRouterParam } from 'core/router/router';
import { makeUrl } from 'core/utils/utils';
import { useActionDispatchOnMount } from 'core/store/store';

import { authCanBuySelector } from 'state/auth/selectors';
import { makeStoreSelector } from 'state/store/selectors';
import { makeProductFetchRequestAction } from 'state/product/actions';
import { productStatusSelector } from 'state/product/selectors';

import { Text, TextTypography } from 'components/Text/Text';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';

import { StoreProduct } from './StoreProduct';
import { StoreProductMobile } from './StoreProductMobile';

import { RoutePath } from 'RoutePath';

import { ImageDto, RequestStatus, StoreCurrentState } from 'types';
import { Icon } from '../../../components/Icon/Icon';
import { ReactComponent as CloseIcon } from '../../../assets/icons/main-16/times--16.svg';
import i18n from '../../../../i18n';
import Carousel from 'nuka-carousel';
import { GalleryArrow, GalleryArrowDirection } from 'components/Gallery/GalleryArrow';

const useStyles = makeStyles(theme => {
    const marginTopMobile = theme.spacing(5);
    const marginTopDesktop = theme.spacing(9);
    const marginBottomDesktop = theme.spacing(3);
    return {
        paper: {
            alignSelf: 'flex-start',
            borderRadius: 20,
            marginTop: marginTopDesktop,
            marginBottom: marginBottomDesktop,
            width: 400,
            [theme.breakpoints.down('xs')]: {
                borderTopLeftRadius: theme.spacing(2),
                borderTopRightRadius: theme.spacing(2),
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                alignSelf: 'flex-end',
                margin: 0,
                marginTop: marginTopMobile,
                width: '100vw',
            },
        },
        paperScrollPaper: {
            maxHeight: `calc(100vh - ${marginTopDesktop + marginBottomDesktop}px)`,
            [theme.breakpoints.down('xs')]: {
                maxHeight: `calc(100vh - ${marginTopMobile}px)`,
                height: '100%',
            },
        },

        imageModalContainer: {
            position: 'fixed',
            inset: 0,
            background: 'rgba(36, 49, 67, 0.5)',
            zIndex: 10000,
        },
        imageModalContent: {
            position: 'fixed',
            inset: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        imageModalImage: {
            maxHeight: '85vh',
            maxWidth: '85vh',
            margin: 'auto',
            marginBottom: 32,
            filter: 'drop-shadow(0px 0px 50px rgba(15, 24, 37, 0.15))',
            borderRadius: 30,
        },
        imageModalCloseButton: {
            cursor: 'pointer',
            background: '#FFFFFF',
            borderRadius: 60,
            border: 'none',
            padding: '5px 8px',
            outline: 0,
            width: 101,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    };
});

const canBuyStoreStatuses = [StoreCurrentState.FULL, StoreCurrentState.SELLING];

export const StoreProductRoute = () => {
    const storeSlug = useRouterParam('storeSlug');
    const productId = useRouterParam('productId');
    const categoryId = useRouterParam('categoryId');
    const storePath = makeUrl(RoutePath.STORE, { storeSlug });
    const storeStatus = useSelector(productStatusSelector);
    const store = useSelector(makeStoreSelector(storeSlug));
    const { t } = i18n.useTranslation();
    const canBuy =
        useSelector(authCanBuySelector) && canBuyStoreStatuses.includes(store?.currentState) && store.openForOrders;

    useActionDispatchOnMount(true, makeProductFetchRequestAction, storeSlug, categoryId, productId);

    const [openedImageIndex, setOpenedImageIndex] = useState<number | null>(null);
    const [productImages, setProductImages] = useState<ImageDto[] | null>(null);

    const handleImageOpen = useCallback(
        (imageIndex, images) => {
            setOpenedImageIndex(imageIndex);
            setProductImages(images);
        },
        [setOpenedImageIndex, setProductImages],
    );

    const history = useHistory();

    const classes = useStyles();

    const handleClose = useCallback(() => {
        history.push(storePath);
    }, [history, storePath]);

    const prevArrow = useCallback(
        ({ previousSlide }) => {
            return productImages.length > 1 ? (
                <GalleryArrow type={GalleryArrowDirection.PREV} onClick={previousSlide} color='white' />
            ) : null;
        },
        [productImages],
    );

    const nextArrow = useCallback(
        ({ nextSlide }) => {
            return productImages.length > 1 ? (
                <GalleryArrow type={GalleryArrowDirection.NEXT} onClick={nextSlide} color='white'/>
            ) : null;
        },
        [productImages],
    );

    if (storeStatus === RequestStatus.PENDING) {
        return (
            <MaterialDialog open={true} onClose={handleClose} classes={classes}>
                <Box m={5}>
                    <CircularProgress />
                </Box>
            </MaterialDialog>
        );
    }

    if (storeStatus === RequestStatus.FAILURE) {
        return (
            <MaterialDialog open={true} onClose={handleClose} classes={classes}>
                <Box m={2} mb={6}>
                    <Text typography={TextTypography.PARA_ACCENT}>{t('common:errors.noProduct')}</Text>
                </Box>
                <Box m={2}>
                    <LargeButton color={LargeButtonColor.TRANSPARENT} onClick={handleClose}>
                        {t('common:silentLoginDialog.passwordForm.back')}
                    </LargeButton>
                </Box>
            </MaterialDialog>
        );
    }

    return (
        <>
            {openedImageIndex !== null && (
                <div className={classes.imageModalContainer}>
                    <div className={classes.imageModalContent}>
                        <ClickAwayListener
                            onClickAway={() => {
                                setOpenedImageIndex(null);
                            }}
                        >
                            <Carousel 
                            wrapAround={true}
                            autoplay={false} 
                            slideIndex={openedImageIndex}
                            renderCenterLeftControls={prevArrow}
                            renderCenterRightControls={nextArrow}
                            >
                                {productImages.map(({imageId}) => (
                                        <img className={classes.imageModalImage} src={`/api/images/${imageId}`} alt="" />
                                ))}
                            </Carousel>
                        </ClickAwayListener>
                        <button
                            className={classes.imageModalCloseButton}
                            onClick={() => {
                                setOpenedImageIndex(null);
                            }}
                        >
                            <Icon component={CloseIcon} size={16} svgSize={16} />
                            <Text typography={TextTypography.BODY_ACCENT}>{t('common:settings.closeButton')}</Text>
                        </button>
                    </div>
                </div>
            )}

            <MaterialDialog open={true} onClose={handleClose} classes={classes}>
                <Hidden xsDown>
                    <StoreProduct canBuy={canBuy} onImageOpen={handleImageOpen} />
                </Hidden>
                <Hidden smUp>
                    <StoreProductMobile canBuy={canBuy} />
                </Hidden>
            </MaterialDialog>
        </>
    );
};
