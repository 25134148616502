import React from 'react';
import { Badge, IconButton } from '@material-ui/core';

import { ReactComponent as CartIcon } from '../../assets/icons/additional/cart-icon--16.svg';
import css from './ButtonBasket.module.css';

export interface ButtonBasketProps {
    productCount: number;
    onClick: () => void;
}

export const ButtonBasket = (props: ButtonBasketProps) => {
    return (
        <IconButton aria-label="number of goods" color="inherit" className={css.button} onClick={props.onClick}>
            <Badge badgeContent={props.productCount} color="secondary" overlap="circle">
                <div className={css.icon}>
                    <CartIcon />
                </div>
            </Badge>
        </IconButton>
    );
};
