import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Box, Hidden, NoSsr, useMediaQuery, useTheme } from '@material-ui/core';
import i18n from '../../../../i18n';

import { useAction } from 'core/store/store';

import { makeAuthLoginAsDifferentUserAction } from 'state/auth/actions';
import { makeUserMenuShowAction } from 'state/userMenu/actions';
import { isStoreOwnerFakeSelector, makeStoreSelector, storeActivePromosSelector } from 'state/store/selectors';
import {
    authAdminRoleSelector,
    authCanBuySelector,
    authStatusSelector,
    authStoreIdSelector,
} from 'state/auth/selectors';
import {
    makeStoreActivateTrialPeriodAction,
    makeStoreClickPhoneAction,
    makeStoreDeleteRequestAction,
    makeStoreFetchSubscriptionInfoAction,
} from 'state/store/actions';
import { StoreState } from 'state/store/reducer';
import { checkoutCartItemsQuantitySelector, checkoutCheckoutSuccessMessageSelector } from 'state/checkout/selectors';

import { LayoutFixedItem } from 'components/Layout/LayoutFixedItem';
import { LayoutFullHeightContainer } from 'components/Layout/LayoutFullHeightContainer';
import { StoreHeader } from 'components/FeedHeader/StoreHeader';
import { PromotionsBlock } from 'components/Promotions/PromotionsBlock';

import { StoreContents } from './StoreContents';
import { StoreProductRoute } from './StoreProductRoute';

import { RoutePath } from 'RoutePath';
import { StoreFooter } from './StoreFooter';
import { StoreInfo } from './StoreInfo';
import { StoreBanner } from './StoreBanner';

import { StoreCurrentState, SupportedCountryCodes, UserRole } from 'types';
import { appConfig } from 'config/app';
import { makeUrl } from 'core/utils/utils';
import { Endpoint } from 'Endpoint';
import { Text, TextTypography } from 'components/Text/Text';
import { ButtonColor, ButtonWithIcon } from 'components/Button/ButtonWithIcon';
import { Icon } from 'components/Icon/Icon';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/main-16/whatsapp--16.svg';
import { postRequest } from 'core/axios/axios';
import { loginAs } from 'core/auth/auth';
import { StoreTabs } from './StoreTabs';
import { StoreTabRoute } from 'components/StoreRoute/StoreTabsRoute';
import { InfoBlock } from 'components/Info/InfoBlock';
import { AuthStatus } from 'state/auth/reducer';
import { ExtendTrialPeriodDialog } from './ExtendTrialPeriodDialog';
import { StoreCardErrorDialog } from './StoreCardErrorDialog';
import { CreateLinkDialog } from './CreateLinkDialog';
import { SnackbarContainer } from 'components/SnackbarContainer/SnackbarContainer';
import { DeliveryBlock } from '../../../components/Delivery/DeliveryBlock';
import { PaymentBlock } from '../../../components/Payment/PaymentBlock';
import { CheckoutInfoButton } from './CheckoutInfoButton';
import { FullScreenDialog } from '../../../components/FullScreenDialog/FullScreenDialog';
import { CheckoutSuccess } from 'pages/Checkout/CheckoutSuccess';
import { ChangeRateDialog } from './ChangeRateDialog';

import css from './StoreCard.module.css';
import { CloneDialog } from './CloneDialog';
import browserCookies from 'browser-cookies';

const CheckoutDynamic = dynamic<any>(() => import('pages/Checkout/Checkout').then(mod => mod.Checkout), {
    ssr: false,
    loading: () => <p>Loading Checkout</p>,
});

export interface CategoriesNavigationDef {
    path: RoutePath;
    label: string;
}

const canBuyStoreStatuses = [StoreCurrentState.FULL, StoreCurrentState.SELLING];

const isServer = typeof window === 'undefined';

export const StoreCard = ({ store: storeState, storeSlug }: { store?: StoreState; storeSlug?: string }) => {
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const { t } = i18n.useTranslation();
    const setInfoOpen = useCallback(() => {
        setIsInfoOpen(true);
    }, [setIsInfoOpen]);
    const setInfoClosed = useCallback(() => {
        setIsInfoOpen(false);
    }, [setIsInfoOpen]);
    const action = useAction();
    const store = useSelector(makeStoreSelector(storeSlug));
    const adminRole = useSelector(authAdminRoleSelector);
    const isAdminRoleAvailable = adminRole == UserRole.ADMINISTRATOR || adminRole == UserRole.OPERATION_ADMIN;
    const isUserWithoutStore = !useSelector(authStoreIdSelector);
    const cartItemsQuantity = useSelector(checkoutCartItemsQuantitySelector);
    const isStoreOwnerFake = useSelector(isStoreOwnerFakeSelector);
    const userIsLoggedIn = useSelector(authStatusSelector) === AuthStatus.LOGGED_IN;

    const showCheckoutButton = useSelector(authCanBuySelector);
    const canBuy = showCheckoutButton && canBuyStoreStatuses.includes(store?.currentState) && store.openForOrders;
    const isActiveSubscription = (store?.subscription?.amount !== 0 && new Date(store?.subscription?.renewalDate) > new Date()) || canBuyStoreStatuses.includes(store?.currentState);

    const [errorDialogContent, setErrorDialogContent] = useState(null);

    const isoCode = !isServer ? browserCookies?.get('country') : SupportedCountryCodes.RUS;
    const marketplacePhoneNumber = isoCode !== SupportedCountryCodes.RUS
        ? appConfig.marketplacePhoneNumber
        : appConfig.marketplacePhoneNumberRu;
    const handleWhatsAppClick = useCallback(() => {
        window.open(`https://wa.me/${marketplacePhoneNumber}`, '_blank');
    }, [marketplacePhoneNumber]);

    const handleErrorDialogClose = useCallback(() => {
        setErrorDialogContent(null);
    }, [setErrorDialogContent]);

    const theme = useTheme();
    const shouldDisplayCheckoutSuccessMessage = useMediaQuery(theme.breakpoints.down('md'));

    const owningStoreError = useMemo(
        () => (
            <>
                <Text typography={TextTypography.PARA}>{t('feed:store.owningStore.linkError')}</Text>
                <div className={css.errorContactBlock}>
                    <Text typography={TextTypography.PARA}>{t('feed:store.owningStore.support')}</Text>
                    <div>
                        <ButtonWithIcon
                            onClick={handleWhatsAppClick}
                            icon={<Icon component={WhatsAppIcon} size={16} svgSize={16} color="#fff" />}
                            color={ButtonColor.GREEN}
                        >
                            <Text typography={TextTypography.BODY_ACCENT_WHITE}>{t('feed:store.whatsapp')}</Text>
                        </ButtonWithIcon>
                    </div>
                </div>
                <div className={css.errorButton}>
                    <LargeButton color={LargeButtonColor.LIGHT} onClick={handleErrorDialogClose}>
                        OK
                    </LargeButton>
                </div>
            </>
        ),
        [t, handleWhatsAppClick, handleErrorDialogClose],
    );

    const conventToSellingStoreError = useMemo(
        () => (
            <>
                <Text typography={TextTypography.PARA}>{t('feed:store.convertToSelling.convertError')}</Text>
                <div className={css.errorButton}>
                    <LargeButton color={LargeButtonColor.LIGHT} onClick={handleErrorDialogClose}>
                        OK
                    </LargeButton>
                </div>
            </>
        ),
        [t, handleErrorDialogClose],
    );

    useEffect(() => {
        if (!isStoreOwnerFake && isAdminRoleAvailable && store) {
            action(makeStoreFetchSubscriptionInfoAction, store.storeId);
        }
    }, [isAdminRoleAvailable, isStoreOwnerFake, store]);

    const handleEdit = useCallback(() => {
        action(makeAuthLoginAsDifferentUserAction, store.vendorId);
    }, [action, store]);
    const handleDelete = useCallback(() => {
        const message = t('feed:store.deleteStoreConfirmation');
        const result = window.confirm(message);
        if (result) {
            action(makeStoreDeleteRequestAction, store.storeId);
        }
    }, [action, store]);

    const promos = useSelector(storeActivePromosSelector);

    const handleOpenUserMenu = useCallback(() => {
        action(makeUserMenuShowAction);
    }, [action]);

    const canPinStoreToCurrentUser = useMemo(() => isStoreOwnerFake && userIsLoggedIn && isUserWithoutStore, [
        isStoreOwnerFake,
        userIsLoggedIn,
        isUserWithoutStore,
    ]);

    const handleClickPhone = useCallback(() => {
        action(makeStoreClickPhoneAction, store.storeId);
    }, [action, store]);

    const handlePinStoreToCurrentUser = useCallback(() => {
        const url = makeUrl(Endpoint.FROM_FAKE_USER_TO_CURRENT_USER, {}, { fakeUserId: store.vendorId });
        postRequest(url, {})
            .then(() => {
                loginAs(UserRole.VENDOR, () => {
                    action(makeStoreActivateTrialPeriodAction, store.storeId);
                });
            })
            .catch(() => {
                setErrorDialogContent(owningStoreError);
            });
    }, [store, makeStoreActivateTrialPeriodAction, setErrorDialogContent, owningStoreError]);

    const handleConvertToSellingStore = useCallback(() => {
        const url = makeUrl(
            Endpoint.STORE_SWITCH_STORE_STATUS,
            { storeId: store.storeId },
            {
                value:
                    store.currentState === StoreCurrentState.SIMPLE
                        ? StoreCurrentState.SELLING
                        : StoreCurrentState.SIMPLE,
            },
        );
        postRequest(url, {})
            .then(() => {
                window.location.reload();
            })
            .catch(() => {
                setErrorDialogContent(conventToSellingStoreError);
            });
    }, [store, setErrorDialogContent, conventToSellingStoreError]);

    const [openTrialPeriodDialog, setOpenTrialPeriodDialog] = useState(false);
    const [openCreateLinkDialog, setOpenCreateLinkDialog] = useState(false);
    const [openChangeRateDialog, setOpenChangeRateDialog] = useState(false);
    const [isCloneDialogOpen, setIsCloneDialogOpen] = useState(false);

    const handleExtendTrialPeriodDialogOpen = () => setOpenTrialPeriodDialog(true);
    const handleChangeRateDialogOpen = () => setOpenChangeRateDialog(true);
    const handleCreateLinkDialogOpen = () => setOpenCreateLinkDialog(true);
    const handleChangeRateDialogClose = () => setOpenChangeRateDialog(false);
    const handleExtendTrialPeriodDialogClose = () => setOpenTrialPeriodDialog(false);
    const handleCreateLinkDialogClose = () => setOpenCreateLinkDialog(false);
    const handleCloneDialogOpen = useCallback(() => setIsCloneDialogOpen(true), [setIsCloneDialogOpen]);
    const handleCloneDialogClose = useCallback(() => setIsCloneDialogOpen(false), [setIsCloneDialogOpen]);

    const [activeTab, setActiveTab] = useState(null);

    const showSuccessMessageOrderId = useSelector(checkoutCheckoutSuccessMessageSelector);

    return (
        <>
            {showSuccessMessageOrderId && shouldDisplayCheckoutSuccessMessage && (
                <FullScreenDialog onClose={() => {}}>
                    {() => (
                        <div className={css.successMessage}>
                            <CheckoutSuccess orderId={showSuccessMessageOrderId} />
                        </div>
                    )}
                </FullScreenDialog>
            )}

            <SnackbarContainer />
            {errorDialogContent && (
                <StoreCardErrorDialog onClose={handleErrorDialogClose}>{errorDialogContent}</StoreCardErrorDialog>
            )}
            {openTrialPeriodDialog && (
                <ExtendTrialPeriodDialog store={storeState.store} onClose={handleExtendTrialPeriodDialogClose} />
            )}
            {openChangeRateDialog && (
                <ChangeRateDialog store={storeState.store} onClose={handleChangeRateDialogClose} />
            )}
            {openCreateLinkDialog && (
                <CreateLinkDialog store={storeState.store} onClose={handleCreateLinkDialogClose} />
            )}
            {isInfoOpen && <StoreInfo showPromo={canBuy} onClose={setInfoClosed} store={storeState.store} />}
            {isCloneDialogOpen && <CloneDialog store={storeState.store} onClose={handleCloneDialogClose} />}

            <div className={css.shop}>
                <StoreHeader
                    productCount={cartItemsQuantity}
                    openUserMenu={handleOpenUserMenu}
                    store={store}
                    canBuy={isActiveSubscription}
                    isAdminRoleAvailable={isAdminRoleAvailable}
                    adminRole={adminRole}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleExtendTrialPeriod={handleExtendTrialPeriodDialogOpen}
                    handleCreateLink={handleCreateLinkDialogOpen}
                    handleChangeRate={handleChangeRateDialogOpen}
                    handleConvertToStoreStatus={handleConvertToSellingStore}
                    onClone={handleCloneDialogOpen}
                />
                <LayoutFullHeightContainer>
                    <LayoutFixedItem>
                        <StoreBanner store={store} storeSlug={storeSlug} />
                        <StoreTabs storeSlug={storeSlug} activeTabRoute={activeTab} />

                        <Switch>
                            <StoreTabRoute setActiveTab={setActiveTab} path={RoutePath.STORE_CATALOG_TAB}>
                                <div>
                                    <StoreContents
                                        openStoreInfo={setInfoOpen}
                                        storeState={storeState}
                                        storeSlug={storeSlug}
                                    />
                                </div>
                            </StoreTabRoute>
                            <StoreTabRoute setActiveTab={setActiveTab} path={RoutePath.STORE_INFO_TAB}>
                                <InfoBlock
                                    canPinStoreToCurrentUser={canPinStoreToCurrentUser}
                                    handleClickPhone={handleClickPhone}
                                    handlePinStoreToCurrentUser={handlePinStoreToCurrentUser}
                                    storeState={storeState}
                                    openStoreInfo={setInfoOpen}
                                    stor={store}
                                />
                            </StoreTabRoute>
                            <StoreTabRoute setActiveTab={setActiveTab} path={RoutePath.STORE_PROMOTIONS_TAB}>
                                <PromotionsBlock promos={promos} isoCode={store?.address?.isoCode} />
                            </StoreTabRoute>
                            <StoreTabRoute setActiveTab={setActiveTab} path={RoutePath.STORE_DELIVERY_TAB}>
                                <DeliveryBlock
                                    deliveryPromo={promos.find(
                                        e => e.results[0].discount === 100 && e.results[0].discountType === 'DELIVERY',
                                    )}
                                    isoCode={store?.address?.isoCode}
                                    deliveries={store?.storeDeliveries || []}
                                />
                                <Box
                                    className={css.deliveryComment}
                                    dangerouslySetInnerHTML={{ __html: store?.deliveryComment }}
                                />
                            </StoreTabRoute>
                            <StoreTabRoute setActiveTab={setActiveTab} path={RoutePath.STORE_PAYMENT_TAB}>
                                <PaymentBlock payment={store?.storePayments || []} />
                                <Box
                                    className={css.deliveryComment}
                                    dangerouslySetInnerHTML={{ __html: store?.paymentComment }}
                                />
                            </StoreTabRoute>
                            <Redirect to={RoutePath.STORE_CATALOG_TAB} />
                        </Switch>

                        {showCheckoutButton && (
                            <Hidden lgUp>
                                <div className={css.checkoutInfoButton}>
                                    <CheckoutInfoButton />
                                </div>
                            </Hidden>
                        )}
                    </LayoutFixedItem>
                </LayoutFullHeightContainer>
                <div className={css.shopFooter}>
                    <StoreFooter store={store} />
                </div>
            </div>

            <div className={css.shopBasket}>
                <CheckoutDynamic isFullScreen={false} />
            </div>
            <NoSsr>
                <Route path={RoutePath.STORE_PRODUCT}>
                    <StoreProductRoute />
                </Route>
            </NoSsr>
        </>
    );
};
