import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Currency } from '../Currency/Currency';
import { Text, TextTypography } from '../Text/Text';
import { T } from '../T/T';
import { TranslatableEnum } from 'components/T/GetTranslationForEnum';

import { SupportedCountryCodes, UnitType } from 'types';
import i18n from '../../../i18n';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
    },
    vertical: {
        display: 'block'
    }
}));

export interface PriceForUnitProps {
    price: number;
    unitType: UnitType;
    unitQuantity: number;
    textType?: TextTypography;
    isoCode?: SupportedCountryCodes;
}

export const PriceForUnit = (props: PriceForUnitProps) => {
    const { price, unitType, unitQuantity, textType = TextTypography.BODY_ACCENT, isoCode } = props;
    const { t } = i18n.useTranslation();
    const classes = useStyles();
    if (isoCode === SupportedCountryCodes.SRB || isoCode === SupportedCountryCodes.UZB)
        return (
            <div className={classes.container} itemProp="offers" itemType="http://schema.org/Offer">
                {price === 0 ? (
                    <Text typography={textType}>{t('common:specifyThePrice')}</Text>
                ) : (
                    <div className={classes.vertical}>
                        <meta itemProp="price" content={price.toString()} />
                        <meta itemProp="priceCurrency" content={isoCode} />
                        <div>
                            <Text typography={textType}>
                                <Currency isoCode={isoCode}>{price}</Currency>
                            </Text>
                        </div>
                        <div>
                            <Text typography={textType}>
                                <span>{unitQuantity}&nbsp;</span>
                                <T enum={TranslatableEnum.UNIT_TYPE}>{unitType}</T>
                            </Text>
                        </div>
                        <link itemProp="availability" href="http://schema.org/InStock" />
                    </div>
                )}
            </div>
        );

    return (
        <div className={classes.container} itemProp="offers" itemType="http://schema.org/Offer">
            {price === 0 ? (
                <Text typography={textType}>{t('common:specifyThePrice')}</Text>
            ) : (
                <>
                    <meta itemProp="price" content={price.toString()} />
                    <meta itemProp="priceCurrency" content={isoCode} />
                    <Text typography={textType}>
                        <Currency isoCode={isoCode}>{price}</Currency>
                    </Text>
                    &nbsp;&nbsp;•&nbsp;&nbsp;
                    <Text typography={textType}>
                        <span>{unitQuantity}&nbsp;</span>
                        <T enum={TranslatableEnum.UNIT_TYPE}>{unitType}</T>
                    </Text>
                    <link itemProp="availability" href="http://schema.org/InStock" />
                </>
            )}
        </div>
    );
};
