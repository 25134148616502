import React, { useCallback, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { DatePickerControlField } from 'components/DatePickerControl/DatePickerControl';
import { DateTime, LocaleOptions } from 'luxon';
import { Field, reduxForm } from 'redux-form';
import { Footer } from 'components/Footer/Footer';
import { Form } from 'forms/types';
import { FormErrorMessage } from 'components/FormErrorMessage/FormErrorMessage';
import { isUIEnabledSelector } from 'state/app/selectors';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { makeStyles } from '@material-ui/core/styles';
import { StoreSlice } from 'state/store/reducer';
import { Text, TextTypography } from 'components/Text/Text';
import { useFormError } from 'core/form/form';
import i18n from '../../../i18n';

export interface ExtendTrialPeriodFormData {
    renewalDate: DateTime;
}

export interface ExtendTrialPeriodFormProps {
    handleSubmit: (value: any) => void;
    initialValues?: Partial<ExtendTrialPeriodFormData>;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
    },
    controlFields: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    fieldContainer: {
        marginTop: theme.spacing(3),
    },
    renewalDateInfo: {
        marginBottom: theme.spacing(2),
    },
    dateField: {
        width: '100%',
    },
    saveButton: {
        padding: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
}));

export const TRIAL_PERIOD_DEFAULT_DATA: ExtendTrialPeriodFormData = {
    renewalDate: DateTime.fromJSDate(new Date()),
};

let ExtendTrialPeriodFormConnected: any = reduxForm({
    form: Form.EXTEND_TRIAL_PERIOD_FORM,
    initialValues: TRIAL_PERIOD_DEFAULT_DATA,
})((props: ExtendTrialPeriodFormProps) => {
    const { handleSubmit, initialValues } = props;
    const initialRenewalDate = useMemo(() => initialValues?.renewalDate, []);
    const { t } = i18n.useTranslation();
    const DatePickerValidator = useCallback(
        (v: DateTime) => {
            if (!v) {
                return t('common:validation.required');
            }

            if (v instanceof DateTime) {
                if (v.invalidReason) {
                    return t('common:validation.invalidDate');
                }
                if (v.startOf('day') < initialRenewalDate.startOf('day')) {
                    return t('common:validation.minDate', {
                        minDate: initialRenewalDate.toLocaleString(DateTime.DATE_MED as LocaleOptions),
                    });
                }
            }
        },
        [t, initialRenewalDate],
    );
    const isUIEnabled = useSelector(isUIEnabledSelector);
    const error = useFormError(Form.EXTEND_TRIAL_PERIOD_FORM);
    const classes = useStyles();
    return (
        <form onSubmit={handleSubmit} className={classes.container}>
            <div>
                <div className={classes.controlFields}>
                    <div className={classes.fieldContainer}>
                        <Text className={classes.renewalDateInfo} typography={TextTypography.BODY}>
                            {t('feed:store.extendTrialPeriod.info')}
                        </Text>
                        <Field
                            className={classes.dateField}
                            name="renewalDate"
                            component={DatePickerControlField}
                            label={t('feed:store.extendTrialPeriod.renewalDate')}
                            validate={DatePickerValidator}
                            minDate={initialRenewalDate}
                        />
                    </div>
                    {error && (
                        <div className={classes.fieldContainer}>
                            <FormErrorMessage error={error} />
                        </div>
                    )}
                </div>
            </div>
            <Footer className={classes.saveButton}>
                <LargeButton disabled={!isUIEnabled} color={LargeButtonColor.SUCCESS} type="submit">
                    {t('feed:store.extendTrialPeriod.ok')}
                </LargeButton>
            </Footer>
        </form>
    );
});

ExtendTrialPeriodFormConnected = connect((state: StoreSlice) => {
    const renewalDate = DateTime.fromISO(state.store?.subscription?.renewalDate);
    const currentDate = DateTime.fromJSDate(new Date());
    return { initialValues: { renewalDate: renewalDate < currentDate ? currentDate : renewalDate } };
})(ExtendTrialPeriodFormConnected);

export const ExtendTrialPeriodForm = ExtendTrialPeriodFormConnected;
