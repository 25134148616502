import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { snackbarMessageSelector, snackbarMessageTypeSelector, snackbarParamsSelector } from 'state/snackbar/selectors';
import { useAction } from 'core/store/store';
import i18n from '../../../i18n';
import { Snackbar } from '@material-ui/core';
import { makeSnackbarClearAction, makeSnackbarOpenAction } from 'state/snackbar/actions';
import React from 'react';
import { getCurrencyText } from '../../core/utils/utils';
import css from './SnackbarContainer.module.css';
import classNames from 'classnames';
/*
const useSnackbarStyles = makeStyles(theme => ({
    root: {
        backgroundColor: (props: { messageError: string }) =>
            props.messageError ? theme.palette.fixedDanger.main : theme.palette.fixedWhite.primary,
        color: (props: { messageError: string }) =>
            props.messageError ? theme.palette.fixedWhite.primary : theme.palette.fixedPrimary.primary,
    },
}));*/

export const SnackbarContainer = () => {
    const messageType = useSelector(snackbarMessageTypeSelector);
    const params = useSelector(snackbarParamsSelector);
    const messageError = useSelector(snackbarMessageSelector);
    const action = useAction();
    const textClass = css[messageError ? 'text_color_white' : 'text_color_dark'];
    const backgroundClass = css[messageError ? 'background_color_red' : 'background_color_white'];
    const { t } = i18n.useTranslation();
    const message = messageError
        ? t(messageError)
        : t(`common:snackbarMessages.${messageType}`, { ...params, currency: getCurrencyText() });
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            className={classNames(textClass, backgroundClass)}
            open={!!messageType || !!messageError}
            autoHideDuration={5e3}
            onClick={() => {
                action(makeSnackbarOpenAction);
            }}
            onClose={() => {
                action(makeSnackbarClearAction);
            }}
            message={message}
        />
    );
};
