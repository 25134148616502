import { Dialog } from 'components/Dialog/Dialog';
import { ErrorBoundary } from 'components/ErrorBoundary';
import React, { useCallback } from 'react';
import i18n from '../../../../i18n';
import { useSelector } from 'react-redux';
import { makeUrl } from 'core/utils/utils';
import { Endpoint } from 'Endpoint';
import { StoreDto } from 'types';
import { postRequest } from 'core/axios/axios';
import { useAction } from 'core/store/store';
import { Form } from 'forms/types';
import { updateSyncErrors } from 'redux-form';
import { makeAppUIDisableAction, makeAppUIEnableAction } from 'state/app/actions';
import { storePhoneLinkSelector } from 'state/store/selectors';
import { CreateLinkForm, CreateLinkFormData } from 'forms/CreateLinkForm/CreateLinkForm';
import { AxiosResponse } from 'axios';
import { makeSnackbarCreateAction } from 'state/snackbar/actions';
import { SnackbarMessageType } from 'state/snackbar/reducer';

interface ExtendTrialPeriodDialogProps {
    store: StoreDto;
    onClose: () => void;
}

export const CreateLinkDialog = (props: ExtendTrialPeriodDialogProps) => {
    const { store, onClose } = props;
    const action = useAction();
    const storeSubscriptionPlan = useSelector(storePhoneLinkSelector);
    const initialValues = { phone: storeSubscriptionPlan };
    const handleSubmit = useCallback(
        (formData: CreateLinkFormData) => {
            action(makeAppUIDisableAction);
            const url = makeUrl(Endpoint.VENDOR_GET_STORE_LINK, { storeId: store.storeId });
            postRequest(url, { contactPhone: formData.phone })
                .then((link: AxiosResponse<string>) => {
                    navigator.clipboard.writeText(link.data).then(
                        function () {
                            action(makeSnackbarCreateAction, SnackbarMessageType.SUCCESS_COPY, {});
                            onClose();
                        },
                        function (err) {
                            console.error('Async: Could not copy text: ', err);
                        },
                    );
                })
                .catch(e => {
                    if (e.isAxiosError && e.response) {
                        const { data, status } = e.response;
                        if ((status === 400 || status === 409) && data && data.errors) {
                            const errors: Record<string, string> = Object.keys(data.errors).reduce((acc, k) => {
                                return {
                                    ...acc,
                                    [k]: (data.errors[k] ?? []).map((item: any) => item.message).join('\n'),
                                };
                            }, {});
                            const { generic, ...rest } = errors;
                            action(updateSyncErrors, Form.CREATE_LINK_FORM, rest, generic);
                            return;
                        }
                        if (status === 500) {
                            action(updateSyncErrors, Form.CREATE_LINK_FORM, {}, 'common:errors.generic');
                        }
                    }
                })
                .finally(() => {
                    action(makeAppUIEnableAction);
                });
        },
        [storeSubscriptionPlan, store?.storeId],
    );
    const { t } = i18n.useTranslation();
    return (
        <Dialog onClose={onClose} title={t('feed:store.createLink.title')}>
            <ErrorBoundary>
                <CreateLinkForm onSubmit={handleSubmit} initialValues={initialValues} />
            </ErrorBoundary>
        </Dialog>
    );
};
