import classNames from 'classnames';
import { Dialog } from 'components/Dialog/Dialog';
import React from 'react';
import css from './StoreCardErrorDialog.module.css';

interface StoreCardErrorDialogProps {
    onClose: () => void;
    children: React.ReactNode;
    className?: string;
}

export const StoreCardErrorDialog = (props: StoreCardErrorDialogProps) => {
    const { onClose, children } = props;
    return (
        <Dialog onClose={onClose} hideCloseButton={true}>
            <div className={classNames(css.errorDialogContainer, props.className)}>{children}</div>
        </Dialog>
    );
};
