import { StoreCard } from './components/StoreCard';
import { StoreCardSkeleton } from './components/StoreCardSkeleton';
import { StoreFetchFailure } from './components/StoreFetchFailure';
import { StoreVendorWarning } from './components/StoreVendorWarning';
import { RoutePath } from 'RoutePath';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { createBrowserHistory } from 'history';
import dynamic from 'next/dynamic';
import React from 'react';
import { useSelector } from 'react-redux';
import { StaticRouter } from 'react-router';
import { Router } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { authRoleSelector, authStoreIdSelector } from 'state/auth/selectors';
import { StoreState } from 'state/store/reducer';
import { RequestStatus, UserRole } from 'types';

const CheckoutCartDialog = dynamic<any>(
    () => import('pages/Checkout/CheckoutCartDialog').then(mod => mod.CheckoutCartDialog),
    {
        ssr: false,
    },
);

const UserMenuContainer = dynamic<any>(() => import('MenuContainer').then(mod => mod.MenuContainer), {
    ssr: false,
});

const CurrentLocationModalDyn = dynamic<any>(
    () => import('components/CurrentLocationModal/CurrentLocationModal').then(mod => mod.CurrentLocationModal),
    {
        ssr: false,
    },
);

const isServer = typeof window === 'undefined';
const history = (isServer ? undefined : createBrowserHistory()) as ReturnType<typeof createBrowserHistory>;

export const Store = ({ store, storeSlug, tab = '' }: { store: StoreState; storeSlug: string; tab: string }) => {
    const userRole = useSelector(authRoleSelector);

    const storeId = useSelector(authStoreIdSelector);

    const storeStatus = store?.storeStatus;

    if (storeStatus === RequestStatus.FAILURE) {
        return <StoreFetchFailure />;
    }

    const openedStoreId = store?.store?.storeId;

    if (userRole === UserRole.VENDOR && openedStoreId && storeId && openedStoreId !== storeId) {
        return <StoreVendorWarning />;
    }
    //disabling skeletons as they are never shown
    /*
    if (!store?.store || store?.store.customUrl.toLowerCase() !== storeSlug.toLowerCase()) {
        if (!isServer) {
            return (
                <Router history={history}>
                    <Route path={RoutePath.STORE}>
                        <ErrorBoundary>
                            <CheckoutCartDialog />
                            <UserMenuContainer isSsr />
                            <StoreCardSkeleton />
                        </ErrorBoundary>
                    </Route>
                </Router>
            );
        }
        return <StoreCardSkeleton />;
    }*/

    if (!isServer) {
        return (
            <Router history={history}>
                <Switch>
                    <Route path={RoutePath.STORE}>
                        <ErrorBoundary>
                            <CheckoutCartDialog />
                            <CurrentLocationModalDyn />
                            <UserMenuContainer isSsr />
                            <StoreCard store={store} storeSlug={storeSlug} />
                        </ErrorBoundary>
                    </Route>
                </Switch>
            </Router>
        );
    }

    //SSR
    return (
        <StaticRouter location={`/${storeSlug}/${tab}`}>
            <Route path={RoutePath.STORE}>
                <StoreCard store={store} storeSlug={storeSlug} />
            </Route>
        </StaticRouter>
    );
};
