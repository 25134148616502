import { ReactComponent as BackwardIcon } from '../../assets/icons/main-16/chevron-big-left--16.svg';
import { ReactComponent as ForwardIcon } from '../../assets/icons/main-16/chevron-big-right--16.svg';
import classNames from 'classnames';
import React from 'react';
import { makeStyles, SvgIcon } from '@material-ui/core';

export enum GalleryArrowDirection {
    PREV = 'PREV',
    NEXT = 'NEXT',
}

interface Arrows {
    type: GalleryArrowDirection;
    onClick: () => void;
    color?: string;
}

const useStyles = makeStyles({
    arrowsVertical: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        height: 50,
        width: 50,
        cursor: 'pointer',
    },
    prevArrowContainer: {
        justifyContent: 'flex-start',
        left: -15
    },
    nextArrowContainer: {
        justifyContent: 'flex-end',
        right: -15
    },
    iconContainer: {
        height: 16,
        width: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: (({ color }: {color: string}) => color)
    }
});

export const GalleryArrow = ({ type, onClick, color = '#243143' }: Arrows) => {
    const classes = useStyles({color});

    let pointer;
    let arrowStyle;
    if (type === GalleryArrowDirection.PREV) {
        pointer = BackwardIcon;
        arrowStyle = classes.prevArrowContainer;
    } else {
        pointer = ForwardIcon;
        arrowStyle = classes.nextArrowContainer;
    }

    return (
        <div className={classNames(classes.arrowsVertical, arrowStyle)} onClick={onClick}>
            <div className={classes.iconContainer}>
                <SvgIcon className={classes.iconContainer} component={pointer} viewBox="0 0 16 16" />
            </div>
        </div>
    );
};
