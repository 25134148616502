import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import DefaultStoreItemImage from 'images/DefaultStoreItemImage.jpg';
import DefaultStoreProductItemImage from 'images/DefaultStoreProductItemImage.jpg';
import DefaultStoreFlowersItemImage from 'images/DefaultStoreFlowersItemImage.jpg';
import DefaultStoreBeautyItemImage from 'images/DefaultStoreFlowersItemImage.jpg';
import { StoreCategory } from 'types';
import Image from 'next/image';

export interface ProductImageProps {
    id: string;
    title: string;
    // Optional
    storeCategory?: StoreCategory;
    draggableItem?: boolean;
}

const useStyles = makeStyles(theme => ({
    productCardPhoto: {
        backgroundColor: theme.palette.fixedPrimary.lightest,
        borderRadius: theme.spacing(2.5),
        height: '100%',
        width: '100%',
        minWidth: theme.spacing(13),
        overflow: 'hidden',
        pointerEvents: (props: ProductImageProps) => (props.draggableItem ? 'none' : 'auto'),
    },
}));

// const IMAGE_SIZES = [180, 360, 720, 1080];

const sanityIoImageLoader = ({ src, width }: any) => {
    return `${src}?img_v=${width}`
}

export const ProductImage = (props: ProductImageProps) => {
    const classes = useStyles(props);
    if (!props.id) {
        switch (props.storeCategory) {
            case StoreCategory.PRODUCTS:
            case StoreCategory.RESTAURANTS:
                return (
                    <img className={classes.productCardPhoto} src={DefaultStoreProductItemImage} alt={props.title} />
                );
            case StoreCategory.FLOWERS:
                return (
                    <img className={classes.productCardPhoto} src={DefaultStoreFlowersItemImage} alt={props.title} />
                );
            case StoreCategory.BEAUTY_HEALTH:
                return <img className={classes.productCardPhoto} src={DefaultStoreBeautyItemImage} alt={props.title} />;
            default:
                return <img className={classes.productCardPhoto} src={DefaultStoreItemImage} alt={props.title} />;
        }
    }

    return (
        <Image
            className={classes.productCardPhoto}
            loader={sanityIoImageLoader}
            src={`/api/images/${props.id}`}
            alt={props.title}
            key={props.id}
            width={400}
            height={400}
            loading={'lazy'}
        />
    );
};
