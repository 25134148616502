import { ProductSlice } from './reducer';

export const productSelector = (state: ProductSlice) => {
    return state.product.product;
};

export const productStatusSelector = (state: ProductSlice) => {
    return state.product.status;
};

export const makeProductSelector = (productId: string) => (state: ProductSlice) => {
    const { product } = state.product;
    if (product && product.productId === productId) {
        return product;
    }
    return null;
};
