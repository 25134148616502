import React, { useCallback, useMemo } from 'react';
import { Grid, Hidden, ListItemText, useMediaQuery } from '@material-ui/core';

import i18n from '../../../../i18n';
import { Icon } from 'components/Icon/Icon';
import { Text, TextTypography } from 'components/Text/Text';

import { StoreDto } from 'types';

import { ReactComponent as MapIcon } from 'assets/icons/main-16/map-marker--16.svg';

import { Schedule } from './StoreSchedule';
import { StoreContacts } from './StoreContacts';
import { getDelivery, getPayment, getPaymentSchema } from '../utils';
import { ButtonColor, ButtonWithIcon } from '../../../components/Button/ButtonWithIcon';
import { ReactComponent as ArrowUp } from 'assets/icons/main-16/arrow-sort-up--16.svg';
import { StaticMap } from '../../../components/StaticMap/StaticMap';
import { ReactComponent as MainLogoIcon } from '../../../assets/logo.svg';
import { RoutePath } from '../../../RoutePath';
import { ButtonBorder } from '../../../components/Button/ButtonBorder';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { checkoutCartItemsQuantitySelector } from 'state/checkout/selectors';
import { makeUrl } from 'core/utils/utils';

import css from './StoreFooter.module.css';
import classNames from 'classnames';
import { TranslatableEnum } from 'components/T/GetTranslationForEnum';
import { getTranslationForEnum } from '../../../components/T/GetTranslationForEnum';

export interface StoreFooterProps {
    store: StoreDto;
}

const getCityText = (city: string) => {
    return city ? city.split('г')[1] : '';
};

export const StoreFooter = (props: StoreFooterProps) => {
    const { store } = props;
    const { t } = i18n.useTranslation();
    const theme = useTheme();

    const totalItemsQuantity = useSelector(checkoutCartItemsQuantitySelector);
    // const classes = useStyles({ hasCheckoutButton: !!totalItemsQuantity });

    const isLargeScreen = useMediaQuery(theme.breakpoints.up(767));
    const payment = useMemo(() => getPayment(store?.storePayments), [store])
        .map(el => t(el))
        .join(', ');
    const paymentSchema = useMemo(() => getPaymentSchema(store?.storePayments), [store])
        .map(el => t(el))
        .join(', ');
    const deliveryOptions = useMemo(() => getDelivery(store?.storeDeliveries, store?.address?.isoCode), [store]).map(
        el => {
            if (el.params?.delivery) {
                let suffix = '';
                if (el.params?.delivery?.params?.suffix) {
                    suffix = t(el.params?.delivery.params.suffix.text);
                }
                const delivery = t((el.params.delivery as any).text, {
                    ...(el.params.delivery as any).params,
                    suffix,
                });
                return t(el.text, { ...el.params, delivery });
            }
            return t(el.text, el.params);
        },
    );
    const { latitude, longitude } = store?.address?.geoCoordinates || { latitude: 0, longitude: 0 };

    const goUp = () => {
        scroll(0, 0);
        return false;
    };

    const handleGoToFeedClick = useCallback(() => {
        window.location.assign(RoutePath.FEED);
    }, []);

    const handleSetMapClick = useCallback(() => {
        const storeFeedUrl = makeUrl(RoutePath.FEED, {}, { lat: latitude, lon: longitude, storeId: store?.storeId });
        window.location.assign(storeFeedUrl);
    }, [store, latitude, longitude]);

    return (
        <div
            className={classNames(css.container, totalItemsQuantity && css.hasCheckoutButton)}
            itemScope
            itemType="https://schema.org/Organization"
        >
            {isLargeScreen ? (
                <div className={css.containerLargeScreen}>
                    <Grid>
                        <div className={css.titleFooter}>
                            <ListItemText
                                primary={
                                    <Text
                                        itemProp="paymentMethod"
                                        content={paymentSchema}
                                        typography={TextTypography.BODY}
                                    >
                                        {t('feed:store.footer.category', {
                                            name: store?.name,
                                            category: getTranslationForEnum(
                                                TranslatableEnum.STORE_CATEGORY,
                                                store?.storeCategory,
                                            ),
                                        })}
                                    </Text>
                                }
                            />
                        </div>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item xs={6}>
                            <div className={css.item}>
                                <div className={css.icon}>
                                    <Icon component={MapIcon} size={16} svgSize={16} />
                                </div>
                                <ListItemText
                                    primary={
                                        <Text itemProp="address" typography={TextTypography.BODY}>
                                            {t('feed:store.footer.address')}
                                            {store?.address.formattedAddress}
                                        </Text>
                                    }
                                />
                            </div>
                            <div>
                                <Schedule storeSchedule={store?.schedule} itemClass={css.contactsClock} />
                            </div>
                            <div className={css.item}>
                                <StoreContacts
                                    store={store}
                                    itemClass={css.contactsItem}
                                    typography={TextTypography.BODY}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={css.item}>
                                <ListItemText
                                    primary={
                                        <Text
                                            itemProp="paymentMethod"
                                            content={paymentSchema}
                                            typography={TextTypography.BODY}
                                        >
                                            {t('feed:store.footer.payment', { payment })}
                                        </Text>
                                    }
                                />
                            </div>
                            <div className={css.deliveryOptions}>
                                {deliveryOptions.map((delivery, i) => (
                                    <Text key={i} typography={TextTypography.BODY}>
                                        {delivery}
                                    </Text>
                                ))}
                            </div>
                            <div className={css.subTitleMap}>
                                <ListItemText
                                    primary={
                                        <Text
                                            itemProp="paymentMethod"
                                            content={paymentSchema}
                                            typography={TextTypography.BODY}
                                        >
                                            {t('feed:store.footer.onMap', {
                                                name: store?.name,
                                                city: getCityText(store?.address?.city?.name),
                                            })}
                                        </Text>
                                    }
                                />
                            </div>
                            <div className={css.containerWrap}>
                                <div className={css.mapWrap}>
                                    <StaticMap height={156} lat={latitude} lon={longitude} storeId={store?.storeId} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item xs={6}>
                            <MainLogoIcon className={css.logo} onClick={handleGoToFeedClick} />
                        </Grid>
                        <Grid item xs={6}>
                            <div className={css.buttonDesktopContainer}>
                                <ButtonBorder
                                    className={css.backButton}
                                    onClick={handleSetMapClick}
                                    color={ButtonColor.GRAY}
                                >
                                    {t('common:store.map')}
                                </ButtonBorder>
                                <ButtonWithIcon
                                    className={css.upDesktop}
                                    onClick={goUp}
                                    icon={
                                        <Icon component={ArrowUp} size={16} svgSize={16} className={css.buttonIcon} />
                                    }
                                    color={ButtonColor.DARK}
                                >
                                    <Text typography={TextTypography.BODY_ACCENT_WHITE}>
                                        {t('feed:store.footer.up')}
                                    </Text>
                                </ButtonWithIcon>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid>
                        <div className={css.subTitleFooter}>
                            <ListItemText
                                primary={
                                    <Text
                                        itemProp="paymentMethod"
                                        content={paymentSchema}
                                        typography={TextTypography.BODY}
                                    >
                                        {t('feed:store.footer.text', {
                                            city: getCityText(store?.address.city.name),
                                            category: getTranslationForEnum(
                                                TranslatableEnum.STORE_CATEGORY,
                                                store?.storeCategory,
                                            ),
                                        })}
                                    </Text>
                                }
                            />
                        </div>
                    </Grid>
                </div>
            ) : (
                <Grid container>
                    <Grid>
                        <div className={css.titleFooter}>
                            <ListItemText
                                primary={
                                    <Text
                                        itemProp="paymentMethod"
                                        content={paymentSchema}
                                        typography={TextTypography.BODY}
                                    >
                                        {t('feed:store.footer.category', {
                                            name: store?.name,
                                            category: getTranslationForEnum(
                                                TranslatableEnum.STORE_CATEGORY,
                                                store?.storeCategory,
                                            ),
                                        })}
                                    </Text>
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={css.item}>
                            <div className={css.icon}>
                                <Icon component={MapIcon} size={16} svgSize={16} />
                            </div>
                            <ListItemText
                                primary={
                                    <Text itemProp="address" typography={TextTypography.BODY}>
                                        {store?.address.formattedAddress}
                                    </Text>
                                }
                            />
                        </div>
                        <div>
                            <Schedule storeSchedule={store?.schedule} itemClass={css.contactsItem} />
                        </div>
                        <div className={css.item}>
                            <StoreContacts
                                store={store}
                                itemClass={css.contactsItem}
                                typography={TextTypography.BODY}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={css.item}>
                            <ListItemText
                                primary={
                                    <Text
                                        itemProp="paymentMethod"
                                        content={paymentSchema}
                                        typography={TextTypography.BODY}
                                    >
                                        {t('feed:store.footer.payment', { payment })}
                                    </Text>
                                }
                            />
                        </div>
                        <div className={css.deliveryOptions}>
                            {deliveryOptions.map((delivery, i) => (
                                <Text key={i} typography={TextTypography.BODY}>
                                    {delivery}
                                </Text>
                            ))}
                        </div>
                        <div className={css.subTitleMap}>
                            <ListItemText
                                primary={
                                    <Text
                                        itemProp="paymentMethod"
                                        content={paymentSchema}
                                        typography={TextTypography.BODY}
                                    >
                                        {t('feed:store.footer.onMap', {
                                            name: store?.name,
                                            city: getCityText(store?.address?.city?.name),
                                        })}
                                    </Text>
                                }
                            />
                        </div>
                        <div className={css.containerWrap}>
                            <div className={css.mapWrap}>
                                <StaticMap height={156} lat={latitude} lon={longitude} storeId={store?.storeId} />
                            </div>
                            <div className={css.buttonContainer}>
                                <MainLogoIcon className={css.logo} onClick={handleGoToFeedClick} />
                                <ButtonBorder
                                    className={css.backButton}
                                    onClick={handleSetMapClick}
                                    color={ButtonColor.GRAY}
                                >
                                    {t('common:store.map')}
                                </ButtonBorder>
                                <ButtonWithIcon
                                    className={css.upDesktop}
                                    onClick={goUp}
                                    icon={
                                        <Icon component={ArrowUp} size={16} svgSize={16} className={css.buttonIcon} />
                                    }
                                    color={ButtonColor.DARK}
                                >
                                    <Text typography={TextTypography.BODY_ACCENT_WHITE}>
                                        {t('feed:store.footer.up')}
                                    </Text>
                                </ButtonWithIcon>
                            </div>
                        </div>
                    </Grid>

                    <Hidden smUp>
                        <Grid item xs={12}>
                            <div className={css.buttonMobilContainer}>
                                <ButtonBorder
                                    className={css.backButton}
                                    onClick={handleSetMapClick}
                                    color={ButtonColor.GRAY}
                                >
                                    {t('common:store.map')}
                                </ButtonBorder>
                            </div>
                            <div className={css.buttonLogoContainer}>
                                <MainLogoIcon className={css.logo} onClick={handleGoToFeedClick} />
                                <ButtonWithIcon
                                    className={css.upDesktop}
                                    onClick={goUp}
                                    icon={
                                        <Icon component={ArrowUp} size={16} svgSize={16} className={css.buttonIcon} />
                                    }
                                    color={ButtonColor.DARK}
                                >
                                    <Text typography={TextTypography.BODY_ACCENT_WHITE}>
                                        {t('feed:store.footer.up')}
                                    </Text>
                                </ButtonWithIcon>
                            </div>
                        </Grid>
                        <Grid>
                            <div className={css.titleFooter}>
                                <ListItemText
                                    primary={
                                        <Text
                                            itemProp="paymentMethod"
                                            content={paymentSchema}
                                            typography={TextTypography.BODY}
                                        >
                                            {t('feed:store.footer.text', {
                                                city: getCityText(store?.address.city.name),
                                                category: getTranslationForEnum(
                                                    TranslatableEnum.STORE_CATEGORY,
                                                    store?.storeCategory,
                                                ),
                                            })}
                                        </Text>
                                    }
                                />
                            </div>
                        </Grid>
                    </Hidden>
                </Grid>
            )}
        </div>
    );
};
