import React, { FC } from 'react';
import { Button as MaterialButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import css from './ButtonWithIcon.module.css';

export enum ButtonColor {
    WHITE,
    DARK,
    SUCCESS,
    ERROR,
    GRAY,
    GREEN,
}

export interface ButtonWithIconProps {
    // Optional:
    color?: ButtonColor;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    type?: 'submit';
    icon?: any;
}

export const ButtonWithIcon: FC<ButtonWithIconProps> = props => {
    const { color, className, icon, ...rest } = props;
    const buttonColor = ButtonColor[color];
    const backgroundColorClassName = css['background_color_' + buttonColor] || css.background_color;
    const textColorClassName = css['text_' + buttonColor] || css.text;

    return (
        <MaterialButton
            className={classNames(css.containedPrimary, className, backgroundColorClassName, textColorClassName)}
            variant="contained"
            disableElevation={true}
            startIcon={icon}
            {...rest}
        />
    );
};
