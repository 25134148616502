import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { checkoutCartItemsQuantitySelector, checkoutIsoCodeSelector, checkoutTotalPriceSelector } from 'state/checkout/selectors';
import { Text, TextTypography } from 'components/Text/Text';
import i18n from '../../../../i18n';
import { useAction } from 'core/store/store';
import { makeCheckoutOpenCartAction } from 'state/checkout/actions';
import { formatCurrency } from '../../../core/utils/utils';
import css from './CheckoutInfoButton.module.css';

export const CheckoutInfoButton = () => {
    const { t } = i18n.useTranslation();
    const totalPrice = useSelector(checkoutTotalPriceSelector);
    const totalItemsQuantity = useSelector(checkoutCartItemsQuantitySelector);
    const isoCode = useSelector(checkoutIsoCodeSelector);
    const action = useAction();
    const handleOpenCheckout = useCallback(() => {
        action(makeCheckoutOpenCartAction);
    }, [action]);
    return (
        totalItemsQuantity > 0 && (
            <div className={css.container} onClick={handleOpenCheckout}>
                <div className={css.button}>
                    <Text className={css.text} typography={TextTypography.PARA_ACCENT}>
                        {t('feed:checkout.item', { count: totalItemsQuantity })}
                    </Text>
                    <Text className={css.text} typography={TextTypography.CAPTION_ACCENT}>
                        {t('feed:checkout.cart')}
                    </Text>
                    <Text className={css.text} typography={TextTypography.PARA_ACCENT}>
                        {formatCurrency(totalPrice, isoCode)}
                    </Text>
                </div>
            </div>
        )
    );
};
