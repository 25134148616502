import React from 'react';
import { DeliveryType, PromoDto, StoreDeliveryDto, SupportedCountryCodes } from '../../types';
import { useSelector } from 'react-redux';
import { checkoutCartDeliveryTypeSelector, checkoutCartSelector } from '../../state/checkout/selectors';
import { feedAddressSelector } from '../../state/feed/selectors';
import i18n from '../../../i18n';
import { ErrorBoundary } from '../ErrorBoundary';
import { Box, RadioGroup } from '@material-ui/core';
import { ReactComponent as DeliveryIcon } from '../../assets/glyphs/delivery.svg';
import { ReactComponent as PickUpIcon } from '../../assets/glyphs/pick-up.svg';
import { RadioElement } from '../RadioElement/RadioElement';
import { Text, TextTypography } from '../Text/Text';
import { formatCurrency } from '../../core/utils/utils';
import css from './DeliveryBlock.module.css';
import { PromotionsCard } from '../Promotions/PromotionsCard';

export interface DeliveryBlockProps {
    deliveries: StoreDeliveryDto[];
    deliveryPromo: PromoDto;
    isoCode: SupportedCountryCodes;
}

export const DeliveryBlock = (props: DeliveryBlockProps) => {
    const address = useSelector(feedAddressSelector);
    const { t } = i18n.useTranslation();
    const cart = useSelector(checkoutCartSelector);

    const deliveryType: DeliveryType = useSelector(checkoutCartDeliveryTypeSelector);

    const storeDeliveryInfo: Record<DeliveryType, { deliveryPrice: number }> = cart?.availableDeliveries?.reduce(
        (acc, delivery) => ({ ...acc, [delivery.deliveryType]: { deliveryPrice: delivery.deliveryPrice } }),
        {} as Record<DeliveryType, { deliveryPrice: number }>,
    );

    const storeDeliveryOrDeliveryService =
        storeDeliveryInfo && storeDeliveryInfo[DeliveryType.STORE_DELIVERY]
            ? DeliveryType.STORE_DELIVERY
            : DeliveryType.DELIVERY_SERVICE;

    const deliveryItem = (type: string) => {
        switch (type) {
            case DeliveryType.PICKUP:
                const pickupDelivery = props.deliveries?.find(item => item.type === DeliveryType.PICKUP);
                return (
                    <RadioElement
                        icon={PickUpIcon}
                        title={t('feed:checkout:shipping:PICKUP')}
                        subtitle={
                            pickupDelivery?.data[0]?.hoursToStore
                                ? t('feed:checkout:shipping:PICKUP', { time: pickupDelivery.data[0]?.hoursToStore })
                                : ''
                        }
                    />
                );
            case storeDeliveryOrDeliveryService:
                return (
                    <RadioElement
                        icon={DeliveryIcon}
                        title={t('feed:checkout:shipping:STORE_DELIVERY')}
                        subtitle={
                            storeDeliveryInfo &&
                            storeDeliveryInfo[storeDeliveryOrDeliveryService] &&
                            !!deliveryType &&
                            !!address &&
                            (storeDeliveryInfo[storeDeliveryOrDeliveryService]?.deliveryPrice > 0
                                ? `${
                                     formatCurrency(storeDeliveryInfo[storeDeliveryOrDeliveryService].deliveryPrice)
                                  }`
                                : t('vendor:userMenu.subscription.free'))
                        }
                    />
                );
            case DeliveryType.SPECIFIC_DELIVERY:
                return (
                    <RadioElement
                        icon={DeliveryIcon}
                        title={t('feed:checkout:shipping:SPECIFIC_DELIVERY')}
                        subtitle={t('feed:checkout.delivery.specific')}
                    />
                );
            default:
                return (
                    <div>
                        <Text typography={TextTypography.BODY}>{t('feed:checkout:shipping:deliveryNotChosen')}</Text>
                    </div>
                );
        }
    };

    return (
        <ErrorBoundary>
            <Box className={css.mainContainer}>
                <div className={css.containerScroll}>
                    {props.deliveryPromo && <PromotionsCard promo={props.deliveryPromo} isoCode={props.isoCode} />}
                    <RadioGroup className={css.deliveryTypesContainer} name={'deliveryType'}>
                        {props.deliveries &&
                            props.deliveries.map(delivery => {
                                return deliveryItem(delivery.type);
                            })}
                    </RadioGroup>
                </div>
            </Box>
        </ErrorBoundary>
    );
};
