import React, { FC, useCallback } from 'react';
import { CategoryDto } from 'types';
import { makeStyles } from '@material-ui/core/styles';
import { Tab } from '@material-ui/core';
import { Text, TextTypography } from 'components/Text/Text';

import css from './ProductCategoryChip.module.css';
import i18n from '../../../i18n';

export interface ProductCategoryChipProps {
    category: CategoryDto;
    onChipClick: (categoryId: string) => void;
    selected?: boolean;
}

const useStyles = makeStyles({
    root: {
        minWidth: '0px !important',
        maxWidth: '50vw !important',
        opacity: '1 !important',
        color: 'rgba(36, 49, 67, 0.6) !important',
        borderRadius: '24px !important',
        padding: '0px 16px !important',
        height: '40px !important',
        minHeight: '40px !important',
        '& h3': {
            textTransform: 'none',
        },
    },
    selected: {
        opacity: 1,
        backgroundColor: 'rgba(36, 49, 67, 0.6) !important',
        '& h3': {
            color: '#FFFFFF !important',
        },
    },
});

export const ProductCategoryChip: FC<ProductCategoryChipProps> = props => {
    const { category, selected, onChipClick } = props;
    const classes = useStyles();
    const { t } = i18n.useTranslation();
    const handleChipClick = useCallback(() => {
        onChipClick(category.categoryId);
    }, [category]);

    return (
        <Tab
            classes={classes}
            label={
                <Text shouldCollapseText typography={TextTypography.BODY_HEADING} className={css.label}>
                    {t(category.name)}
                </Text>
            }
            selected={selected}
            onClick={handleChipClick}
        />
    );
};
