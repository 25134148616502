import React, { useCallback, FC, useEffect, useRef, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { checkoutTotalPriceSelector } from 'state/checkout/selectors';
import { makeAppInitAction } from 'state/app/actions';

import { RoutePath } from 'RoutePath';
import { useAction } from 'core/store/store';

import { ReactComponent as Logo } from 'assets/logo-icon.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/main-16/elipsis-h--16.svg';

import { Icon } from 'components/Icon/Icon';
import { ButtonBasket } from '../Button/ButtonBasket';
import { Text, TextTypography } from '../Text/Text';
import { Distance } from '../Distance/Distance';
import { DeliveryInfo } from '../DeliveryInfo/DeliveryInfo';
import { StoreCategory, StoreCurrentState, StoreDto, UserRole } from '../../types';
import { makeStoreChangeModeratedStatusRequestAction } from 'state/store/actions';
import { isStoreModeratedSelector, isStoreOwnerFakeSelector, storeSubscriptionSelector } from 'state/store/selectors';

import { ReactComponent as BeautyIcon } from '../../../public/google-map-icons/shop_BEAUTY_HEALTH_active.svg';
import { ReactComponent as ClothsIcon } from '../../../public/google-map-icons/shop_CLOTHS_SHOES_active.svg';
import { ReactComponent as FlowersIcon } from '../../../public/google-map-icons/shop_FLOWERS_active.svg';
import { ReactComponent as RestaurantsIcon } from '../../../public/google-map-icons/shop_RESTAURANTS_active.svg';
import { ReactComponent as ForChildrenIcon } from '../../../public/google-map-icons/shop_FOR_CHILDREN_active.svg';
import { ReactComponent as ForHomeIcon } from '../../../public/google-map-icons/shop_FOR_HOME_active.svg';
import { ReactComponent as PetsIcon } from '../../../public/google-map-icons/shop_PETS_active.svg';
import { ReactComponent as ProductsIcon } from '../../../public/google-map-icons/shop_PRODUCTS_active.svg';
import { ReactComponent as SportsIcon } from '../../../public/google-map-icons/shop_SPORTS_active.svg';
import { ReactComponent as BuildingIcon } from '../../../public/google-map-icons/shop_BUILDING_active.svg';
import { ReactComponent as FurnitureIcon } from '../../../public/google-map-icons/shop_FURNITURE_active.svg';
import { ReactComponent as ServicesIcon } from '../../../public/google-map-icons/shop_SERVICES_active.svg';
import { ReactComponent as EducationIcon } from '../../../public/google-map-icons/shop_EDUCATION_active.svg';
import { ReactComponent as OtherIcon } from '../../../public/google-map-icons/shop_OTHER_active.svg';
import ShopWindowBadge from 'assets/shopWindowBadge.svg';
import classNames from 'classnames';
import { makeCheckoutOpenCartAction } from 'state/checkout/actions';
import { StoreContextMenu } from '../StoreContextMenu/StoreContextMenu';
import { makeUrl } from '../../core/utils/utils';
import { Endpoint } from '../../Endpoint';
import { postRequest } from '../../core/axios/axios';
import i18n from '../../../i18n';

import { SelectLanguageControl } from '../SelectLanguageControl/SelectLanguageControl';
import css from './StoreHeader.module.css';

const getCategoryIcon = (category: string) => {
    switch (category) {
        case StoreCategory.PRODUCTS:
            return ProductsIcon;
        case StoreCategory.RESTAURANTS:
            return RestaurantsIcon;
        case StoreCategory.FLOWERS:
            return FlowersIcon;
        case StoreCategory.FOR_HOME:
            return ForHomeIcon;
        case StoreCategory.FOR_CHILDREN:
            return ForChildrenIcon;
        case StoreCategory.CLOTHS_SHOES:
            return ClothsIcon;
        case StoreCategory.PETS:
            return PetsIcon;
        case StoreCategory.BEAUTY_HEALTH:
            return BeautyIcon;
        case StoreCategory.SPORTS:
            return SportsIcon;
        case StoreCategory.BUILDING:
            return BuildingIcon;
        case StoreCategory.FURNITURE:
            return FurnitureIcon;
        case StoreCategory.SERVICES:
            return ServicesIcon;
        case StoreCategory.EDUCATION:
            return EducationIcon;
        case StoreCategory.OTHER:
            return OtherIcon;
        default:
            return null;
    }
};

export interface StoreHeaderProps {
    productCount: number;
    openUserMenu: () => void;
    onSearchClick?: () => void;
    onCreateStoreClick?: () => void;
    onMapClick?: () => void;
    styles?: CSSProperties;
    isFullIcon?: boolean;
    isFromSsr?: boolean;
    hideButtons?: boolean;
    store: StoreDto;
    canBuy: boolean;
    isAdminRoleAvailable?: boolean;
    adminRole?: UserRole;
    handleEdit: () => void;
    handleDelete: () => void;
    handleExtendTrialPeriod: () => void;
    handleCreateLink: () => void;
    handleChangeRate: () => void;
    handleConvertToStoreStatus: () => void;
    onClone?: () => void;
}

export const StoreHeader: FC<StoreHeaderProps> = props => {
    const {
        productCount,
        styles,
        isFromSsr = false,
        store,
        canBuy,
        isAdminRoleAvailable,
        adminRole,
        handleEdit,
        handleDelete,
        handleExtendTrialPeriod,
        handleCreateLink,
        handleChangeRate,
        handleConvertToStoreStatus,
        onClone,
    } = props;

    const history = useHistory();
    const action = useAction();
    const { t } = i18n.useTranslation();

    useEffect(() => {
        action(makeAppInitAction); //for SSR
    }, []);

    const cartItemsPrice = useSelector(checkoutTotalPriceSelector);
    const isStoreModerated = useSelector(isStoreModeratedSelector);
    const isStoreOwnerFake = useSelector(isStoreOwnerFakeSelector);
    const storeSubscription = useSelector(storeSubscriptionSelector);

    const isSimpleStore = useMemo(() => store?.currentState === StoreCurrentState.SIMPLE, [store]);

    const onLogoClick = useCallback(() => {
        window.location.assign(RoutePath.FEED);
    }, [isFromSsr, history]);

    const onCartClick = useCallback(() => {
        if (cartItemsPrice && action) {
            action(makeCheckoutOpenCartAction);
        }
    }, [cartItemsPrice, action]);

    const menuAnchor = useRef<HTMLDivElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleMenuClose = useCallback(() => setIsMenuOpen(false), [setIsMenuOpen]);
    const handleMenuOpen = useCallback(() => setIsMenuOpen(true), [setIsMenuOpen]);

    const handleChangeStoreModeratedStatus = useCallback(() => {
        action(makeStoreChangeModeratedStatusRequestAction, store.storeId, !store.moderated);
    }, [store]);

    const handleSetStickersSent = useCallback(() => {
        const url = makeUrl(Endpoint.STORE_SET_STICKERS_SENT, { storeId: store.storeId });
        postRequest(url, {}).then(() => {
            window.location.reload();
        });
    }, [store]);

    const CategoryIcon = getCategoryIcon(store?.storeCategory);

    return (
        <Box className={css.container} style={styles} itemScope itemType="http://schema.org/WPHeader">
            <div className={css.logo} onClick={onLogoClick} itemProp="logo">
                <Logo />
            </div>
            <div className={css.storeContainer}>
                <div className={css.title}>
                    <Text typography={TextTypography.DISPLAY_CHANGE}>{store?.name}</Text>
                </div>
                {canBuy ? (
                    <div className={css.storeDeliveryInfoContainer}>
                        {store.slogan ? (
                            <div className={css.subTitle}>
                                <Text typography={TextTypography.PARA_CHANGE}>{store.slogan}</Text>
                            </div>
                        ) : (
                            <>
                                <Icon
                                    className={css.storeCategoryIcon}
                                    component={CategoryIcon}
                                    size={24}
                                    svgSize={28}
                                />
                                <Text typography={TextTypography.DISPLAY_LIGHT_CHANGE}>
                                    <Distance meters={store?.distance} />
                                    &nbsp;•&nbsp;
                                    <DeliveryInfo deliveries={store?.storeDeliveries} />
                                </Text>
                            </>
                        )}
                    </div>
                ) : (
                    <div className={classNames(css.storeDeliveryInfoContainer, css.storeDeliveryInfoCentered)}>
                        {!store?.slogan && (
                            <div className={css.distance}>
                                <Text typography={TextTypography.DISPLAY_LIGHT_CHANGE}>
                                    <Distance meters={store?.distance} />
                                </Text>
                            </div>
                        )}
                        <div className={css.shopWindowBadge} style={{ backgroundImage: `url(${ShopWindowBadge})` }}>
                            <Text className={css.badgeText} typography={TextTypography.BODY_ACCENT}>
                                {t('common:showcase')}
                            </Text>
                        </div>
                        {store?.slogan && (
                            <div className={css.slogan}>
                                <Text typography={TextTypography.BODY_ACCENT}>{store.slogan}</Text>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={css.buttonRadius}>
                <div>
                    <SelectLanguageControl />
                </div>
                <ButtonBasket productCount={productCount} onClick={onCartClick} />
            </div>
            <div className={css.admin}>
                <div />
                {/* https://3steps.atlassian.net/browse/ANY-2167 */}
                {/*<div className={css.iconBurger} onClick={() => openUserMenu()}>*/}
                {/*    <Icon color={'#222'} component={MenuIcon} size={32} />*/}
                {/*</div>*/}
                {isAdminRoleAvailable && (
                    <>
                        <div ref={menuAnchor} className={css.moreIcon} onClick={handleMenuOpen}>
                            <Icon component={MoreIcon} size={16} svgSize={16} />
                        </div>

                        <StoreContextMenu
                            adminRole={adminRole}
                            storeCountryCode={store.address.isoCode}
                            anchor={menuAnchor.current}
                            isOpen={isMenuOpen}
                            isSimpleStore={isSimpleStore}
                            isStoreModerated={isStoreModerated}
                            isStoreOwnerFake={isStoreOwnerFake}
                            hasSubscription={!!storeSubscription}
                            onClose={handleMenuClose}
                            onLogInAsAdmin={handleEdit}
                            onDelete={handleDelete}
                            onChangeStoreModeratedStatus={handleChangeStoreModeratedStatus}
                            onCreateLink={handleCreateLink}
                            onChangeRate={handleChangeRate}
                            onExtendTrialPeriod={handleExtendTrialPeriod}
                            onConvertToSellingStore={handleConvertToStoreStatus}
                            onStickersSent={handleSetStickersSent}
                            onClone={onClone}
                        />
                    </>
                )}
            </div>
        </Box>
    );
};
