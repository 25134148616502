import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSize from '@react-hook/size';
import { appConfig } from '../../config/app';
import debounce from 'lodash/debounce';
import { makeStyles } from '@material-ui/core/styles';
import { languageCodeOnly, makeUrl } from 'core/utils/utils';
import { RoutePath } from 'RoutePath';
import i18nLib from '../../../i18n';

export interface StaticMapProps {
    height: number;
    storeId: string;
    lat: number;
    lon: number;
}

const useStyles = makeStyles(theme => ({
    image: {
        borderRadius: theme.spacing(1.25),
        width: '100%',
        height: ({ height }: { height: number }) => height,
    },
}));

export const StaticMap = (props: StaticMapProps) => {
    const { height, storeId, lat, lon } = props;
    const classes = useStyles({ height });
    const container = useRef<HTMLImageElement>();
    const [containerWidth, containerHeight] = useSize(container);
    const [mapOptions, setMapOptions] = useState<Record<string, string | number>>({});
    const { i18n } = i18nLib.useTranslation();

    const handleMapClick = useCallback(() => {
        const storeFeedUrl = makeUrl(RoutePath.FEED, {}, { lat, lon, storeId });
        window.location.assign(storeFeedUrl);
    }, [lat, lon, storeId]);

    const debounedCalculateStaticMapOptions = useMemo(() => {
        return debounce(({ containerWidth, containerHeight }) => {
            const calculatedOptions = {
                center: `${lat},${lon}`,
                zoom: 17,
                size: `${containerWidth}x${containerHeight}`,
                maptype: 'roadmap',
                markers: `color:red%7C${lat},${lon}`,
                scale: 2,
                key: appConfig.googleApiKey,
                language: languageCodeOnly(i18n.language),
            };
            setMapOptions(calculatedOptions);
        }, 500);
    }, [lat, lon, height, appConfig.googleApiKey]);

    useEffect(() => {
        debounedCalculateStaticMapOptions({ containerWidth, containerHeight });
    }, [containerWidth, containerHeight]);

    const query = useMemo(
        () =>
            Object.keys(mapOptions)
                .reduce((acc, k) => [...acc, `${k}=${mapOptions[k]}`], [])
                .join('&'),
        [mapOptions],
    );

    const src = `https://maps.googleapis.com/maps/api/staticmap?${query}`;
    return (
        <div style={{ height: '100%' }} ref={container}>
            {mapOptions && query && <img className={classes.image} src={src} alt={''} onClick={handleMapClick} />}
        </div>
    );
};
