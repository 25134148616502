import React from 'react';
import { Dialog } from 'components/Dialog/Dialog';
import i18n from '../../../../i18n';
import { Text, TextTypography } from 'components/Text/Text';
import { Footer } from 'components/Footer/Footer';
import { Box, Grid } from '@material-ui/core';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';

export interface CheckoutErrorDialogProps {
    onClose: () => void;
    onSubmit: () => void;
}

export const CheckoutErrorDialog = (props: CheckoutErrorDialogProps) => {
    const { onClose, onSubmit } = props;
    const { t } = i18n.useTranslation();
    return (
        <Dialog title={t('feed:product:checkoutError:title')} onClose={onClose}>
            <Grid container direction={'column'}>
                <Box m={2}>
                    <Text typography={TextTypography.BODY}>{t('feed:product:checkoutError:text')}</Text>
                </Box>

                <Footer>
                    <Box m={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <LargeButton color={LargeButtonColor.LIGHT} onClick={onClose}>
                                    {t('feed:product:checkoutError:cancel')}
                                </LargeButton>
                            </Grid>
                            <Grid item xs={6}>
                                <LargeButton onClick={onSubmit}>{t('feed:product:checkoutError:submit')}</LargeButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Footer>
            </Grid>
        </Dialog>
    );
};
