import React, { FC } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { WrappedFieldProps } from 'redux-form';
import i18n from '../../../i18n';

import { T } from '../T/T';
import { TranslatableEnum } from 'components/T/GetTranslationForEnum';

import { DeliveryType, OrderStatus, UnitType } from 'types';

export interface SelectControlProps {
    label: string;
    enum: TranslatableEnum;
    items: (OrderStatus | DeliveryType | UnitType)[];
    // Optional:
    name?: string;
    value?: string;
    onChange?: any;
    errorMessage?: string;
    fullWidth?: boolean;
    isDisabled?: boolean;
    className?: string;
    inputClassName?: string;
}

export const SelectEnumControl: FC<SelectControlProps> = props => {
    const { label, items, enum: e, errorMessage, isDisabled, className, inputClassName, ...rest } = props;
    return (
        <TextField
            InputProps={{
                disableUnderline: true,
                className: inputClassName,
            }}
            className={className}
            label={label}
            variant="filled"
            error={!!errorMessage}
            helperText={errorMessage}
            fullWidth={true}
            size="small"
            disabled={isDisabled}
            select={true}
            {...rest}
        >
            {items.map((item: any) => (
                <MenuItem key={item} value={item}>
                    <T enum={e}>{item}</T>
                </MenuItem>
            ))}
        </TextField>
    );
};

export interface SelectEnumControlFieldOwnProps {
    label: string;
    enum: TranslatableEnum;
    items: (OrderStatus | DeliveryType | UnitType)[];
    // Optional:
    isDisabled?: boolean;
    className?: string;
    inputClassName?: string;
}

export type SelectEnumControlFieldProps = SelectEnumControlFieldOwnProps & WrappedFieldProps;

export const SelectEnumControlField = (props: SelectEnumControlFieldProps) => {
    const { t } = i18n.useTranslation();
    const {
        input,
        label,
        enum: e,
        items,
        isDisabled,
        className,
        inputClassName,
        meta: { touched, invalid, error },
    } = props;
    return (
        <SelectEnumControl
            label={label}
            enum={e}
            items={items}
            isDisabled={isDisabled}
            className={className}
            inputClassName={inputClassName}
            errorMessage={touched && invalid ? t(error) : undefined}
            {...input}
        />
    );
};
