import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { Text, TextTypography } from 'components/Text/Text';

import { RoutePath } from 'RoutePath';
import i18n from '../../../../i18n';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
});

export const StoreFetchFailure = () => {
    const classes = useStyles();
    const { t } = i18n.useTranslation();

    const onCancel = useCallback(() => window.location.assign(RoutePath.FEED), []);

    return (
        <div className={classes.container}>
            <div>
                <Box m={2} mb={6}>
                    <Text typography={TextTypography.PARA_ACCENT}>{t('common:errors.loadShop')}</Text>
                </Box>
                <Box m={2}>
                    <LargeButton color={LargeButtonColor.TRANSPARENT} onClick={onCancel}>
                        {t('common:silentLoginDialog.passwordForm.back')}
                    </LargeButton>
                </Box>
            </div>
        </div>
    );
};
