import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '../Icon/Icon';
import { Text, TextTypography } from '../Text/Text';

export interface RadioElementProps {
    icon?: any;
    title: string;
    subtitle?: any;
}

const useStyles = makeStyles(theme => ({
    radioElement: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: '#FBFEF9',
        boxShadow: '0px 0px 50px rgba(15, 24, 37, 0.05)',
        borderRadius: 20,
        marginBottom: theme.spacing(2),
    },
    radioIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    radioLabel: {
        paddingLeft: theme.spacing(2),
    },
}));

export const RadioElement = (props: RadioElementProps) => {
    const classes = useStyles();

    const { icon, title, subtitle } = props;

    return (
        <div className={classes.radioElement}>
            <div className={classes.radioIcon}>
                <Icon component={icon} size={32} svgSize={32} />
            </div>
            <div className={classes.radioLabel}>
                <Text typography={TextTypography.PARA_ACCENT}>{title}</Text>
                {subtitle && <Text typography={TextTypography.BODY}>{subtitle}</Text>}
            </div>
        </div>
    );
};
