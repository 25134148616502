import React, { FC, Fragment } from 'react';
import { ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Icon } from 'components/Icon/Icon';
import { Text, TextTypography } from 'components/Text/Text';

import { ContactDto, StoreDto, VendorContactType } from 'types';

import { ReactComponent as PhoneIcon } from 'assets/icons/main-16/phone--16.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/main-16/website--16.svg';
import { ReactComponent as InstaIcon } from 'assets/icons/main-16/instagram-o--16.svg';
import { ReactComponent as WatsAppIcon } from 'assets/icons/main-16/whatsapp--16.svg';
import i18next from 'i18next';

const getContactIcon = (contact: ContactDto) => {
    switch (contact.type) {
        case VendorContactType.PHONE:
            return PhoneIcon;
        case VendorContactType.SITE:
            return WebsiteIcon;
        case VendorContactType.INSTA:
            return InstaIcon;
        case VendorContactType.WA:
            return WatsAppIcon;
        default:
            return null;
    }
};

const isAbsoluteUrl = (url: string) => {
    return url.startsWith('//') || url.startsWith('http://') || url.startsWith('https://');
};

const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

const getContactHref = (contact: ContactDto) => {
    switch (contact.type) {
        case VendorContactType.PHONE:
            return `tel:${contact.value}`;
        case VendorContactType.SITE:
            return isAbsoluteUrl(contact.value) ? contact.value : `//${contact.value}`;
        case VendorContactType.VK:
            return URL_REGEX.test(contact.value) ? contact.value : `https://vk.com/${contact.value}`;
        case VendorContactType.INSTA:
            return URL_REGEX.test(contact.value) ? contact.value : `https://instagram.com/${contact.value}`;
        case VendorContactType.FB:
            return URL_REGEX.test(contact.value) ? contact.value : `https://facebook.com/${contact.value}`;
        case VendorContactType.WA:
            return URL_REGEX.test(contact.value)
                ? contact.value
                : `https://wa.me/${contact.value.replace(/[+()\- ]/g, '')}`;
        default:
            return null;
    }
};

const getContactValue = (contact: ContactDto) => {
    switch (contact.type) {
        case VendorContactType.VK:
        case VendorContactType.INSTA:
        case VendorContactType.WA:
        case VendorContactType.FB:
            if (URL_REGEX.test(contact.value)) {
                return contact.value
                    .split('/')
                    .filter(v => v !== '')
                    .pop();
            }
            return contact.value;
        default:
            return contact.value;
    }
};

const getItemProp = (contact: ContactDto) => {
    switch (contact.type) {
        case VendorContactType.PHONE:
            return 'telephone';
        case VendorContactType.SITE:
            return 'url';
        default:
            return null;
    }
};

const useStoreInfoStyles = makeStyles(theme => ({
    iconContainer: {
        display: 'flex',
        minWidth: 16,
        marginRight: 16,
        alignItems: 'center',
        '& svg path': {
            fill: '#243243',
        },
    },
    contactItemContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: 55,
        minHeight: 55,
        textDecoration: 'none',
        color: theme.palette.fixedPrimary.primary,
        borderBottom: '1px solid rgba(15, 24, 37, 0.05)',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
}));

interface StoreContactsProps {
    store: StoreDto;
    // Optional:
    itemClass?: string;
    typography?: TextTypography;
}

export const StoreContacts: FC<StoreContactsProps> = ({ store, itemClass, typography = TextTypography.PARA }) => {
    const classes = useStoreInfoStyles();

    return (
        <Fragment>
            {(store?.contacts || []).map((contact: ContactDto, i: number) => {
                const ContactIcon = getContactIcon(contact);
                const href = getContactHref(contact);
                const value = getContactValue(contact);
                const itemProp = getItemProp(contact);

                return (
                    <a
                        key={i}
                        className={classNames(classes.contactItemContainer, itemClass)}
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className={classes.iconContainer}>
                            {ContactIcon && <Icon component={ContactIcon} size={16} svgSize={16} />}
                        </div>
                        <div>
                            <ListItemText
                                itemProp={itemProp}
                                primary={
                                    <Text typography={typography}>
                                        {itemProp == 'telephone' && i18next.t('feed:store.footer.phone')}
                                        {value}
                                    </Text>
                                }
                            />
                        </div>
                    </a>
                );
            })}
        </Fragment>
    );
};
