import React, { FC, Ref, useCallback } from 'react';
import { CategoryDto } from 'types';
import { makeStyles } from '@material-ui/core/styles';
import { ProductCategoryChip } from './ProductCategoryChip';
import { Tabs } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        // paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2),
    },
    tabSliderContainer: {
        [theme.breakpoints.down(768)]: {
            width: theme.spacing(3),
        },
        [theme.breakpoints.down(480)]: {
            width: theme.spacing(2),
        },
    },
}));

const useTabsStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: 40,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    indicator: {
        backgroundColor: 'transparent',
        display: 'none',
        height: 3,
        justifyContent: 'center',
        '& > span': {
            backgroundColor: theme.palette.fixedPrimary.primary,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '100%',
        },
    },
}));

export interface ProductCategoriesChipsProps {
    categories: CategoryDto[];
    activeChip: number;
    setActiveChip: (value: number) => void;
    onChipClick: (categoryId: string) => void;
}

export const ProductCategoriesChips: FC<ProductCategoriesChipsProps> = props => {
    const { activeChip, setActiveChip, onChipClick, categories } = props;
    const classes = useStyles();
    const tabsClasses = useTabsStyles();
    const handleChangeActiveChip = useCallback(
        (_, v) => {
            setActiveChip(v);
        },
        [setActiveChip],
    );
    return (
        <div className={classes.root}>
            <Tabs
                classes={tabsClasses}
                indicatorColor="primary"
                value={activeChip}
                onChange={handleChangeActiveChip}
                variant="scrollable"
                scrollButtons="on"
                TabIndicatorProps={{
                    children: <span />,
                }}
                TabScrollButtonProps={{ className: classes.tabSliderContainer }}
            >
                {categories.map(category => (
                    <ProductCategoryChip key={category.categoryId} category={category} onChipClick={onChipClick} />
                ))}
            </Tabs>
        </div>
    );
};
