import React, { Fragment, useCallback } from 'react';
import { Box, makeStyles } from '@material-ui/core';

import { OptionGroupDto, SupportedCountryCodes } from 'types';

import { RadioOptionGroup } from './RadioOptionGroup';
import { CheckboxOptionGroup } from './CheckboxOptionGroup';
import { OptionsMode } from 'forms/AddProductForm/OptionGroup';
import { FormErrorMessage } from '../../../components/FormErrorMessage/FormErrorMessage';

interface OptionGroupsProps {
    value: Record<string, string[]>;
    optionGroups: OptionGroupDto[];
    validationErrors: Record<string, string>;
    isoCode: SupportedCountryCodes;
    onChange: (value: Record<string, string[]>) => void;
}

const isRadioSelection = (optionGroup: OptionGroupDto) => {
    return (
        optionGroup.optionGroupMode === OptionsMode.NUMEROUS &&
        optionGroup.minOptions === 1 &&
        optionGroup.maxOptions === 1
    );
};

const useStyles = makeStyles(theme => ({
    group: {
        padding: theme.spacing(2, 0, 2, 3),
    },
    divider: {
        minWidth: 400,
        backgroundColor: '#eee',
        height: theme.spacing(1),
        width: '100%',
    },
}));

export const OptionGroups = (props: OptionGroupsProps) => {
    const { value, optionGroups, validationErrors, isoCode, onChange } = props;

    const handleRadioChange = useCallback(
        (optionGroupId: string, v: string) => {
            onChange({
                ...value,
                [optionGroupId]: [v],
            });
        },
        [value, onChange],
    );

    const handleCheckboxChange = useCallback(
        (optionGroupId: string, v: string[]) => {
            onChange({
                ...value,
                [optionGroupId]: v,
            });
        },
        [value, onChange],
    );

    const classes = useStyles();
    return (
        <div>
            <div className={classes.divider} />
            {optionGroups.map(optionGroup => {
                if (optionGroup.options.length > 0) {
                    const { optionGroupId } = optionGroup;
                    if (isRadioSelection(optionGroup)) {
                        return (
                            <Fragment key={optionGroupId}>
                                <div className={classes.group}>
                                    <RadioOptionGroup
                                        optionGroup={optionGroup}
                                        value={value[optionGroupId][0]}
                                        onChange={handleRadioChange}
                                        isoCode={isoCode}
                                    />
                                    {validationErrors[optionGroupId] && (
                                        <Box mr={2}>
                                            <FormErrorMessage error={validationErrors[optionGroupId]} />
                                        </Box>
                                    )}
                                </div>
                                <div className={classes.divider} />
                            </Fragment>
                        );
                    }
                    return (
                        <Fragment key={optionGroup.optionGroupId}>
                            <div className={classes.group}>
                                <CheckboxOptionGroup
                                    optionGroup={optionGroup}
                                    value={value[optionGroup.optionGroupId]}
                                    onChange={handleCheckboxChange}
                                    isoCode={isoCode}
                                />
                                {validationErrors[optionGroupId] && (
                                    <Box mr={2}>
                                        <FormErrorMessage error={validationErrors[optionGroupId]} />
                                    </Box>
                                )}
                            </div>
                            <div className={classes.divider} />
                        </Fragment>
                    );
                }
            })}
        </div>
    );
};
