import classNames from 'classnames';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RoutePath } from 'RoutePath';
import { ProductList } from 'components/ProductList/ProductList';
import { makeUrl } from 'core/utils/utils';
import { checkoutCartItemsQuantitySelector } from 'state/checkout/selectors';
import { StoreState } from 'state/store/reducer';
import { makeStoreSelector, storeCategoriesSelector, storeProductsSelector } from 'state/store/selectors';
import { ProductDto, StoreCurrentState } from 'types';

import css from './StoreContents.module.css';

interface StoreContentsProps {
    openStoreInfo: () => void;
    storeState: StoreState;
    storeSlug: string;
}

function countProducts(products: Record<string, ProductDto[]>): number {
    return Object.values(products).reduce((acc, el) => acc + el.length, 0);
}

export const StoreContents: FC<StoreContentsProps> = ({ storeState, storeSlug }) => {
    const history = useHistory();

    const storeSelectorResult = useSelector(makeStoreSelector(storeSlug));
    const productsSelectorResult = useSelector(storeProductsSelector);
    const categoriesSelectorResult = useSelector(storeCategoriesSelector);

    const productsSelectorResultCount = countProducts(productsSelectorResult);

    const store = storeState?.store ?? storeSelectorResult;
    const products =
        storeState?.store &&
        productsSelectorResultCount &&
        countProducts(storeState?.products) === productsSelectorResultCount
            ? storeState?.products
            : productsSelectorResult;
    const categories =
        storeState?.store &&
        categoriesSelectorResult?.length !== 0 &&
        storeState?.categories?.length === categoriesSelectorResult.length
            ? storeState?.categories
            : categoriesSelectorResult;

    const totalItemsQuantity = useSelector(checkoutCartItemsQuantitySelector);

    return (
        <div className={css.contentContainer}>
            {store?.currentState !== StoreCurrentState.CARD && (
                <div className={classNames(css.productList, totalItemsQuantity > 0 && css.hasItems)}>
                    <ProductList
                        categories={categories}
                        products={products}
                        storeCategory={store.storeCategory}
                        onProductClick={(productId, categoryId) => {
                            const path = makeUrl(RoutePath.STORE_PRODUCT, { storeSlug, categoryId, productId });
                            if (history) {
                                history.push(path);
                            }
                        }}
                    />
                </div>
            )}
        </div>
    );
};
