import React, { useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';

import { ReactComponent as InstagramIcon } from 'assets/icons/additional/instagram--16.svg';
import { Text, TextTypography } from 'components/Text/Text';
import { Icon } from 'components/Icon/Icon';
import css from './InstagramGallery.module.css';
import classNames from 'classnames';
import { SupportedCountryCodes } from 'types';
import { authIsoCodeSelector } from 'state/auth/selectors';
import { useSelector } from 'react-redux';

export interface InstagramPost {
    id: string;
    media_url: string;
    caption: string;
}

export interface InstagramGalleryProps {
    condensed: boolean;
    posts: InstagramPost[];
    showTitle: boolean;
}

const isServer = typeof window === 'undefined';

export const InstagramGallery = (props: InstagramGalleryProps) => {
    const { condensed, posts, showTitle } = props;

    const padding = condensed ? css.container_padding_condensed : css.container_padding_not_condensed;
    const borderRadius = condensed ? css.border_radius_condensed : css.border_radius_not_condensed;

    const spacing = condensed ? 1 : 2;

    const isoCode = useSelector(authIsoCodeSelector) || SupportedCountryCodes.RUS;

    const slicedPosts = useMemo(() => {
        return posts.slice(0, 12);
    }, [posts]);

    return (
        <div className={classNames(css.container, padding, borderRadius)}>
            {isoCode !== SupportedCountryCodes.RUS && showTitle && (
                <div className={css.title}>
                    <Box mr={2}>
                        <Icon component={InstagramIcon} />
                    </Box>
                    <Text typography={TextTypography.LEAD_ACCENT}>Мы в Instagram</Text>
                </div>
            )}
            <Grid container spacing={spacing}>
                {slicedPosts.map(post => (
                    <Grid key={post.id} item xs={6} sm={3} md={2}>
                        <img
                            className={classNames(css.image, borderRadius)}
                            src={post.media_url}
                            alt={post.caption ?? ''}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};
