import {
    DeliveryType,
    OptionGroupDto,
    PaidDeliveryType,
    PaymentType,
    StoreDeliveryDto,
    StorePaymentDto,
    SupportedCountryCodes,
} from '../../types';
import { formatCurrency } from '../../core/utils/utils';
import i18next from 'i18next';

export function getPayment(paymentInfo: StorePaymentDto[] = []): string[] {
    const resultArray: string[] = [];
    paymentInfo.forEach(({ type, enabled }) => {
        if (enabled) {
            switch (type) {
                case PaymentType.CARD:
                    resultArray.push('common:enums.PAYMENT_TYPE_LOWER.CARD');
                    break;
                case PaymentType.CASH:
                    resultArray.push('common:enums.PAYMENT_TYPE_LOWER.CASH');
                    break;
                case PaymentType.OLPAY:
                    resultArray.push('common:enums.PAYMENT_TYPE_LOWER.OLPAY');
                    break;
            }
        }
    });
    return resultArray;
}

export function getPaymentSchema(paymentInfo: StorePaymentDto[] = []): string[] {
    const resultArray: string[] = [];
    paymentInfo.forEach(({ type, enabled }) => {
        if (enabled) {
            switch (type) {
                case PaymentType.CARD:
                    resultArray.push(i18next.t('common:enums.PAYMENT_TYPE.CARD'));
                    break;
                case PaymentType.CASH:
                    resultArray.push(i18next.t('common:enums.PAYMENT_TYPE.CASH'));
                    break;
                case PaymentType.OLPAY:
                    resultArray.push(i18next.t('common:enums.PAYMENT_TYPE.OLPAY'));
                    break;
            }
        }
    });
    return resultArray;
}

export function getDelivery(
    deliveryInfo: StoreDeliveryDto[] = [],
    isoCode?: SupportedCountryCodes,
): Array<{ text: string; params?: Record<string, unknown> }> {
    const deliveryArray: Array<{ text: string; params?: Record<string, unknown> }> = [];
    const pickupArray: Array<{ text: string; params?: Record<string, unknown> }> = [];
    const currency = formatCurrency(null, isoCode);
    deliveryInfo.forEach(({ type, enabled, data }) => {
        if (enabled) {
            switch (type) {
                case DeliveryType.PICKUP:
                    data.forEach(({ hoursToStore }) => {
                        pickupArray.push({ text: 'feed:store.footer.delivery.pickup', params: { hoursToStore } });
                    });
                    break;
                case DeliveryType.STORE_DELIVERY:
                case DeliveryType.DELIVERY_SERVICE:
                    data.forEach(({ price, minOrderPrice, maxDistance, pricingModel }) => {
                        const suffix =
                            pricingModel === PaidDeliveryType.PER_KM
                                ? { text: 'common:deliveryInfo.distance' }
                                : { text: '' };
                        const delivery =
                            price === 0
                                ? {
                                      text: 'feed:store.footer.delivery.freeShipping',
                                      params: {
                                          price: minOrderPrice,
                                          currency,
                                      },
                                  }
                                : {
                                      text: 'feed:store.footer.delivery.paidShipping',
                                      params: { price, currency, suffix },
                                  };
                        deliveryArray.push({
                            text: 'feed:store.footer.delivery.maxDistance',
                            params: { maxDistance, delivery },
                        });
                    });
                    break;
                case DeliveryType.SPECIFIC_DELIVERY:
                    pickupArray.push({ text: 'feed:store.footer.delivery.specific' });
            }
        }
    });
    return [...deliveryArray, ...pickupArray];
}

export const getInitialOptionsValue = (optionGroups: OptionGroupDto[] = []): Record<string, string[]> => {
    return optionGroups.reduce((acc, optionGroup) => {
        const { optionGroupId, options } = optionGroup;
        const initiallySelectedOptionIds = options
            .filter(option => option.selectedByDefault)
            .map(option => option.optionId);
        return { ...acc, [optionGroupId]: initiallySelectedOptionIds };
    }, {});
};
