import React, { useCallback } from 'react';
import { FormControlLabel, makeStyles } from '@material-ui/core';

import { Currency } from 'components/Currency/Currency';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { Text, TextTypography } from 'components/Text/Text';

import { OptionGroupDto, SupportedCountryCodes } from 'types';

interface RadioOptionGroupProps {
    optionGroup: OptionGroupDto;
    value: string;
    isoCode: SupportedCountryCodes;
    onChange: (optionGroupId: string, value: string) => void;
}

const useFormControlStyles = makeStyles({
    root: {
        width: '100%',
    },
    label: {
        display: 'inline-block',
        width: '100%',
    },
});

const useStyles = makeStyles(theme => ({
    rowContainer: {
        borderBottom: '1px solid #eee',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
    row: {
        alignItems: 'center',
        display: 'flex',
        minHeight: 48,
    },
    rowControl: {
        flex: 1,
    },
    rowPrice: {
        color: theme.palette.fixedPrimary.light,
        flexBasis: 'auto',
        padding: theme.spacing(0, 3),
    },
    optionGroupTitle: {
        display: 'flex',
    },
    optionGroupName: {
        flex: 1,
    },
    optionGroupAsterisk: {
        color: theme.palette.fixedDanger.main,
        padding: theme.spacing(0, 3, 0, 1),
    },
}));

export const RadioOptionGroup = (props: RadioOptionGroupProps) => {
    const { optionGroup, value, onChange, isoCode } = props;

    const handleRadioChange = useCallback(
        (event: any) => {
            const { value } = event.target;
            onChange(optionGroup.optionGroupId, value);
        },
        [value, onChange],
    );

    const classes = useStyles();
    const formControlClasses = useFormControlStyles();
    return (
        <div>
            <div className={classes.optionGroupTitle}>
                <Text className={classes.optionGroupName} typography={TextTypography.PARA_ACCENT}>
                    {optionGroup.name}
                </Text>
                <Text className={classes.optionGroupAsterisk} typography={TextTypography.PARA_ACCENT}>
                    ✱
                </Text>
            </div>
            {optionGroup.options.map(option => (
                <div key={option.optionId} className={classes.rowContainer}>
                    <FormControlLabel
                        classes={formControlClasses}
                        control={
                            <RadioButton
                                checked={value === option.optionId}
                                name={optionGroup.optionGroupId}
                                value={option.optionId}
                                onChange={handleRadioChange}
                            />
                        }
                        label={
                            <div className={classes.row}>
                                <div className={classes.rowControl}>
                                    <Text typography={TextTypography.BODY}>{option.name}</Text>
                                </div>
                                {option.price !== 0 &&
                                    (option.price < 0 ? (
                                        <Text className={classes.rowPrice} typography={TextTypography.BODY}>
                                            <Currency isoCode={isoCode}>{option.price}</Currency>
                                        </Text>
                                    ) : (
                                        <Text className={classes.rowPrice} typography={TextTypography.BODY}>
                                            +<Currency isoCode={isoCode}>{option.price}</Currency>
                                        </Text>
                                    ))}
                            </div>
                        }
                    />
                </div>
            ))}
        </div>
    );
};
