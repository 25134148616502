import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Footer } from 'components/Footer/Footer';
import { Form } from 'forms/types';
import { FormErrorMessage } from 'components/FormErrorMessage/FormErrorMessage';
import { isUIEnabledSelector } from 'state/app/selectors';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { makeStyles } from '@material-ui/core/styles';
import { StoreSlice } from 'state/store/reducer';
import { Text, TextTypography } from 'components/Text/Text';
import { useFormError } from 'core/form/form';
import i18n from '../../../i18n';
import { TextControlField } from 'components/TextControl/TextControl';

export interface CreateLinkFormData {
    phone: string;
}

export interface ExtendTrialPeriodFormProps {
    handleSubmit: (value: any) => void;
    initialValues?: Partial<CreateLinkFormData>;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
    },
    controlFields: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    fieldContainer: {
        marginTop: theme.spacing(3),
    },
    renewalDateInfo: {
        marginBottom: theme.spacing(2),
    },
    dateField: {
        width: '100%',
    },
    saveButton: {
        padding: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
}));

let CreateLinkFormConnected: any = reduxForm({
    form: Form.CREATE_LINK_FORM,
})((props: ExtendTrialPeriodFormProps) => {
    const { handleSubmit } = props;
    const { t } = i18n.useTranslation();

    const isUIEnabled = useSelector(isUIEnabledSelector);
    const error = useFormError(Form.CREATE_LINK_FORM);
    const classes = useStyles();

    return (
        <form onSubmit={handleSubmit} className={classes.container}>
            <div>
                <div className={classes.controlFields}>
                    <div className={classes.fieldContainer}>
                        <Text className={classes.renewalDateInfo} typography={TextTypography.BODY}>
                            {t('feed:store.createLink.info')}
                        </Text>
                        <Field name="phone" component={TextControlField} label={t('feed:store.createLink.subTitle')} />
                    </div>
                    {error && (
                        <div className={classes.fieldContainer}>
                            <FormErrorMessage error={error} />
                        </div>
                    )}
                </div>
            </div>
            <Footer className={classes.saveButton}>
                <LargeButton disabled={!isUIEnabled} color={LargeButtonColor.SUCCESS} type="submit">
                    {t('feed:store.createLink.createButton')}
                </LargeButton>
            </Footer>
        </form>
    );
});

CreateLinkFormConnected = connect((state: StoreSlice) => {})(CreateLinkFormConnected);

export const CreateLinkForm = CreateLinkFormConnected;
