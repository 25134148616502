import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
import { Text, TextTypography } from '../Text/Text';
import css from './ButtonRectangular.module.css';

export enum ButtonColor {
    WHITE,
    DARK,
    SUCCESS,
    ERROR,
    GRAY,
    GREEN,
}

export interface ButtonRectangularProps {
    // Optional:
    color?: ButtonColor;
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    type?: 'submit';
    label: string;
    icon: any;
}

export const ButtonRectangular: FC<ButtonRectangularProps> = props => {
    const { color, className, ...rest } = props;
    const buttonColor = ButtonColor[color];
    const colorClassName = css['color_' + buttonColor] || css.color;
    const backgroundColorClassName = css['background_color_' + buttonColor] || css.background_color;
    const hoverClassName = css['hov_' + buttonColor] || css.hov;
    return (
        <Box
            p={1}
            pt={1.5}
            className={classNames(css.containedPrimary, colorClassName, backgroundColorClassName, hoverClassName)}
        >
            <div>
                <Icon component={props.icon} size={16} svgSize={16} />
            </div>
            <Text typography={TextTypography.CAPTION}>{props.label}</Text>
        </Box>
    );
};
