import React, { ChangeEvent, FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, DialogContent, DialogTitle, Grid, Link as MuiLink } from '@material-ui/core';
import classNames from 'classnames';

import { Text, TextTypography } from 'components/Text/Text';

import { makeStyles } from '@material-ui/core/styles';
import i18n from '../../../../i18n';

import { useRouterParam } from 'core/router/router';
import { useAction } from 'core/store/store';
import { formatCurrency, makeUrl } from 'core/utils/utils';

import { makeProductSelector } from 'state/product/selectors';
import { makeCheckoutAddToCartAction } from 'state/checkout/actions';
import { checkoutCartStoreIdSelector } from 'state/checkout/selectors';

import { CloseButton } from 'components/CloseButton/CloseButton';
import { Icon } from 'components/Icon/Icon';
import { LargeButton } from 'components/LargeButton/LargeButton';
import { LayoutFixedItem } from 'components/Layout/LayoutFixedItem';
import { LayoutFullHeightContainer } from 'components/Layout/LayoutFullHeightContainer';
import { PriceForUnit } from 'components/PriceForUnit/PriceForUnit';
import { TextControl } from 'components/TextControl/TextControl';
import { CheckoutErrorDialog } from './CheckoutErrorDialog';

import { ReactComponent as AddCountIcon } from 'assets/icons/additional/add-count-icon.svg';
import { ReactComponent as RemoveCountIcon } from 'assets/icons/additional/remove-count-icon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/status/exclamation-circle--16.svg';

import { RoutePath } from 'RoutePath';
import { ContactDto, ImageDto, StoreCategory, VendorContactType } from 'types';
import { makeStoreSelector } from 'state/store/selectors';
import { OptionGroups } from './OptionGroups';
import { OptionsMode } from 'forms/AddProductForm/OptionGroup';
import { getInitialOptionsValue } from '../utils';
import { ButtonColor, ButtonRectangular } from 'components/Button/ButtonRectangular';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/main-16/whatsapp--16.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/main-16/phone--16.svg';
import { makeStoreClickPhoneAction } from '../../../state/store/actions';

import css from './StoreProduct.module.css';
import Carousel from 'nuka-carousel';
import { GalleryArrow, GalleryArrowDirection } from 'components/Gallery/GalleryArrow';

export interface StoreProductStyles {
    storeCategory: StoreCategory;
    isDesired: boolean;
}

const useDialogContentStyles = makeStyles({
    root: {
        paddingTop: 10,
    },
});

interface StoreProductProps {
    canBuy: boolean;
    onImageOpen: (imageIndex: number, images?: ImageDto[]) => void;
}

export const StoreProduct: FC<StoreProductProps> = ({ canBuy, onImageOpen }) => {
    const storeSlug = useRouterParam('storeSlug');
    const productId = useRouterParam('productId');
    const [quantity, setQuantity] = useState(1);
    const [comment, setComment] = useState('');
    const [checkoutErrorDialog, setCheckoutErrorDialog] = useState(false);
    const history = useHistory();
    const action = useAction();
    const { t } = i18n.useTranslation();
    const store = useSelector(makeStoreSelector(storeSlug));
    const product = useSelector(makeProductSelector(productId));
    const cartStoreId = useSelector(checkoutCartStoreIdSelector);
    const image = product?.images && product?.images[0];
    const imageId = image ? image.imageId : null;
    const storePath = makeUrl(RoutePath.STORE_CATALOG_TAB, { storeSlug });
    const dialogContentClasses = useDialogContentStyles();

    const handleAddProduct = useCallback(() => {
        if (cartStoreId && cartStoreId !== store.storeId) {
            setCheckoutErrorDialog(true);
        } else {
            action(makeCheckoutAddToCartAction, store.storeId, product, quantity, selectedOptions);
            history.push(storePath);
        }
    }, [store, storePath, action, history, cartStoreId, product, quantity]);

    const handleQuantity = useCallback(
        value => {
            if (value === 0) {
                return;
            }
            setQuantity(value);
        },
        [setQuantity],
    );

    const [selectedOptions, setSelectedOptions] = useState(getInitialOptionsValue(product?.optionGroups));
    const [totalSelectedOptionsPrice, setTotalSelectedOptionsPrice] = useState(0);
    const total = product == null ? null : quantity * (product.price + totalSelectedOptionsPrice);

    useEffect(() => {
        const optionsPrice =
            product == null
                ? 0
                : Object.entries(selectedOptions).reduce(
                      (totalOptionsPrice, [optionGroupId, selectedOptionGroupOptionIds]) => {
                          const optionGroupDto = product?.optionGroups.find(
                              optionGroup => optionGroup.optionGroupId === optionGroupId,
                          );
                          if (optionGroupDto) {
                              return (
                                  totalOptionsPrice +
                                  selectedOptionGroupOptionIds.reduce(
                                      (optionGroupPrice, optionId) =>
                                          optionGroupPrice +
                                          (optionGroupDto.options.find(option => option.optionId === optionId)?.price ||
                                              0),
                                      0,
                                  )
                              );
                          }
                          return totalOptionsPrice;
                      },
                      0,
                  );

        setTotalSelectedOptionsPrice(optionsPrice);
    }, [selectedOptions, product?.optionGroups]);

    const prevArrow = useCallback(
        ({ previousSlide }) => {
            return product?.images.length > 1 ? (
                <GalleryArrow type={GalleryArrowDirection.PREV} onClick={previousSlide} />
            ) : null;
        },
        [product],
    );

    const nextArrow = useCallback(
        ({ nextSlide }) => {
            return product?.images.length > 1 ? (
                <GalleryArrow type={GalleryArrowDirection.NEXT} onClick={nextSlide} />
            ) : null;
        },
        [product],
    );

    const validationErrors = product?.optionGroups.reduce((acc, optionGroup) => {
        const { minOptions, maxOptions, optionGroupId, optionGroupMode } = optionGroup;
        const isNumerous = optionGroupMode === OptionsMode.NUMEROUS;
        const selectedOptionsCount = selectedOptions[optionGroupId].length;

        if (isNumerous && selectedOptionsCount < minOptions) {
            return { ...acc, [optionGroupId]: t('common:validation:minCount', { minOptions }) };
        }
        if (isNumerous && selectedOptionsCount > maxOptions) {
            return { ...acc, [optionGroupId]: t('common:validation:maxCount', { maxOptions }) };
        }
        return acc;
    }, {});
    const hasValidationErrors = Object.keys(validationErrors).length > 0;

    const phoneContact = (store?.contacts || []).find((c: ContactDto) => c.type === VendorContactType.PHONE);
    const waContact = (store?.contacts || []).find((c: ContactDto) => c.type === VendorContactType.WA);

    const handleClickPhone = useCallback(() => {
        action(makeStoreClickPhoneAction, store.storeId);
    }, [action, store]);

    return (
        <Fragment>
            <DialogTitle className={css.title} disableTypography={true}>
                <Text shouldCollapseText className={css.titleText} typography={TextTypography.LEAD_ACCENT}>
                    {product?.name}
                </Text>
                <div className={css.closeButton}>
                    <CloseButton onClick={() => history.push(storePath)} />
                </div>
            </DialogTitle>
            <DialogContent className={css.content} classes={dialogContentClasses}>
                <LayoutFullHeightContainer>
                    <div style={{ flex: 1, position: 'relative' }}>
                        {imageId ? (
                            <Box m={3}>
                                <Carousel 
                                wrapAround={true} autoplay={false} 
                                renderCenterLeftControls={prevArrow}
                                renderCenterRightControls={nextArrow}
                                >
                                {product?.images.map((image, index) => (<div className={css.productPhotoContainer}><img
                                    className={css.productCardPhoto}
                                    src={`/api/images/${image.imageId}`}
                                    alt={image.imageId}
                                    key={image.imageId}
                                    onClick={() => {
                                        onImageOpen(index, product?.images);
                                    }}
                                /></div>))}
                                </Carousel>
                            </Box>
                        ) : (
                            <Box m={3}>
                                <div className={classNames(css.defaultImage, css[store?.storeCategory])} />
                            </Box>
                        )}
                        <Box>
                            <Grid container>
                                {product?.description && (
                                    <Grid item xs={12} className={css.description}>
                                        <Text typography={TextTypography.PARA}>{product.description}</Text>
                                    </Grid>
                                )}
                                {product?.optionGroups && (
                                    <Grid item xs={12}>
                                        <OptionGroups
                                            value={selectedOptions}
                                            optionGroups={product.optionGroups}
                                            onChange={setSelectedOptions}
                                            validationErrors={validationErrors}
                                            isoCode={store?.address?.isoCode}
                                        />
                                    </Grid>
                                )}
                                {product?.price !== 0 && !product?.outOfStock && (
                                    <Grid item xs={12}>
                                        <div className={css.priceAndQuantity}>
                                            <div className={css.price}>
                                                <PriceForUnit
                                                    price={product?.price}
                                                    unitType={product?.unitType}
                                                    unitQuantity={product?.unitQuantity}
                                                    textType={TextTypography.LEAD_ACCENT}
                                                    isoCode={store?.address?.isoCode}
                                                />
                                            </div>
                                            {canBuy && (
                                                <div className={css.buttons}>
                                                    <div
                                                        className={css.quantityButton}
                                                        onClick={() => handleQuantity(quantity - 1)}
                                                    >
                                                        <Icon
                                                            component={RemoveCountIcon}
                                                            color={quantity === 1 ? 'rgba(183, 183, 183)' : '#243143'}
                                                            size={24}
                                                            svgSize={24}
                                                        />
                                                    </div>
                                                    <Text
                                                        className={css.quantity}
                                                        typography={TextTypography.BODY_ACCENT}
                                                    >
                                                        {quantity}
                                                    </Text>
                                                    <div
                                                        className={css.quantityButton}
                                                        onClick={() => handleQuantity(quantity + 1)}
                                                    >
                                                        <Icon component={AddCountIcon} size={24} svgSize={24} />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Grid>
                                )}
                                {canBuy && product?.price !== 0 && !product?.outOfStock && (
                                    <Grid item xs={12} style={{ margin: 24 }}>
                                        <TextControl
                                            label={t('feed:product.comment')}
                                            value={comment}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </div>
                </LayoutFullHeightContainer>
            </DialogContent>
            {canBuy && product?.price !== 0 ? (
                !product?.outOfStock && (
                    <LayoutFixedItem>
                        <Box p={2} className={css.addToCartButton}>
                            <LargeButton disabled={total == null || hasValidationErrors} onClick={handleAddProduct}>
                                {t('feed:product.addToCart')}{' '}
                                {total != null && <span>— {formatCurrency(total, store?.address?.isoCode)}</span>}
                            </LargeButton>
                        </Box>
                    </LayoutFixedItem>
                )
            ) : (
                <div className={css.shopWindow}>
                    <div className={css.shopWindowText}>
                        <div className={css.shopWindowBadgeIcon}>
                            <Icon component={InfoIcon} size={16} svgSize={16} />
                        </div>
                        <Text typography={TextTypography.PARA}>
                            {t(
                                canBuy && product?.price === 0
                                    ? 'feed:product.shopWindowContactWithVendor'
                                    : 'feed:product.shopWindowInfo',
                            )}
                        </Text>
                    </div>

                    <Grid className={css.contactStore} container spacing={2}>
                        {waContact && (
                            <Grid item xs={6}>
                                <MuiLink
                                    href={`https://wa.me/${waContact?.value.replace(/[+()\- ]/g, '')}`}
                                    underline="none"
                                    color="inherit"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <ButtonRectangular
                                        label={t('feed:store.whatsapp')}
                                        icon={WhatsAppIcon}
                                        color={ButtonColor.GREEN}
                                    />
                                </MuiLink>
                            </Grid>
                        )}
                        {phoneContact && (
                            <Grid item xs={6}>
                                <MuiLink
                                    href={`tel:${phoneContact?.value}`}
                                    underline="none"
                                    color="inherit"
                                    onClick={handleClickPhone}
                                >
                                    <ButtonRectangular label={t('feed:store.call')} icon={PhoneIcon} />
                                </MuiLink>
                            </Grid>
                        )}
                    </Grid>
                </div>
            )}
            {checkoutErrorDialog && (
                <CheckoutErrorDialog
                    onClose={() => setCheckoutErrorDialog(false)}
                    onSubmit={() => {
                        action(makeCheckoutAddToCartAction, store.storeId, product, quantity, selectedOptions);
                        setCheckoutErrorDialog(false);
                        history.push(storePath);
                    }}
                />
            )}
        </Fragment>
    );
};
