import React, { FC, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Text, TextTypography } from 'components/Text/Text';

import { StoreDeliveryDto, StorePaymentDto } from 'types';

import { getDelivery, getPayment } from '../utils';
import i18n from '../../../../i18n';

const useStyles = makeStyles({
    container: {
        backgroundColor: 'rgba(166,73,253, 0.1)',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 24,
        padding: '16px 24px',
        paddingLeft: 0,
        borderBottom: '1px solid rgba(15, 24, 37, 0.05)',
        '&:last-child': {
            borderBottom: 'none',
        },
    },
});

interface StorePromoProps {
    paymentInfo?: StorePaymentDto[];
    deliveryInfo?: StoreDeliveryDto[];
}

export const StorePromo: FC<StorePromoProps> = ({ paymentInfo, deliveryInfo }) => {
    const classes = useStyles();
    const payment = useMemo(() => getPayment(paymentInfo), [paymentInfo]);
    const deliveryArray = useMemo(() => getDelivery(deliveryInfo), [deliveryInfo]);
    const { t } = i18n.useTranslation();

    return (
        <div className={classes.container}>
            <div className={classes.item}>
                <Text typography={TextTypography.BODY}>{t('feed:store:footer:payment', { payment })}</Text>
            </div>
            <div className={classes.item}>
                {deliveryArray.map((delivery, i) => (
                    <Text key={i} typography={TextTypography.BODY}>
                        {delivery}
                    </Text>
                ))}
            </div>
        </div>
    );
};
