import { Dialog } from 'components/Dialog/Dialog';
import { ErrorBoundary } from 'components/ErrorBoundary';
import React, { useCallback, useState } from 'react';
import i18n from '../../../../i18n';

import { Endpoint } from 'Endpoint';
import { StoreDto, SubscriptionPlanResponse } from 'types';
import { getRequest, postRequest } from 'core/axios/axios';
import { useAction } from 'core/store/store';
import { makeAppUIDisableAction, makeAppUIEnableAction } from 'state/app/actions';
import { makeStoreFetchSubscriptionInfoAction } from 'state/store/actions';
import { ChangeRateForm, ChangeRateFormData } from 'forms/ChangeRateForm/ChangeRateForm';
import { AxiosResponse } from 'axios';
import { updateSyncErrors } from 'redux-form';
import { Form } from '../../../forms/types';
import { LargeButton, LargeButtonColor } from '../../../components/LargeButton/LargeButton';
import { makeStyles } from '@material-ui/core';
import { Text, TextTypography } from '../../../components/Text/Text';
import { getTranslationForEnum, TranslatableEnum } from 'components/T/GetTranslationForEnum';
import { formatISODateTime } from '../../../core/date/date';

interface ChangeRateDialogProps {
    store: StoreDto;
    onClose: () => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    buttons: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    button: {
        width: '100%',
        marginTop: theme.spacing(1),
        '&:last-child': {
            marginLeft: theme.spacing(1),
        },
    },
}));

export const ChangeRateDialog = (props: ChangeRateDialogProps) => {
    const { store, onClose } = props;
    const action = useAction();
    const classes = useStyles();
    const [formData, setFormData] = useState<ChangeRateFormData>(null);
    const handleActivation = (formData: ChangeRateFormData) => {
        setFormData(formData);
    };
    const handleSubmit = useCallback(() => {
        action(makeAppUIDisableAction);
        getRequest(Endpoint.VENDOR_SUBSCRIPTION_PLANS, { storeId: store?.storeId }).then(
            (answer: AxiosResponse<SubscriptionPlanResponse[]>) => {
                postRequest(Endpoint.VENDOR_SUBSCRIPTION_MANUAL, {
                    storeId: store?.storeId,
                    startDate: formData.startDate.toUTC(),
                    subscriptionPlanId: answer.data.find(el => el.name === formData.rateType).subscriptionPlanId,
                })
                    .then(() => {
                        action(makeStoreFetchSubscriptionInfoAction, store.storeId);
                        onClose();
                    })
                    .catch(e => {
                        if (e.isAxiosError && e.response) {
                            const { data, status } = e.response;
                            if ((status === 400 || status === 409) && data && data.errors) {
                                const errors: Record<string, string> = Object.keys(data.errors).reduce((acc, k) => {
                                    return {
                                        ...acc,
                                        [k]: (data.errors[k] ?? []).map((item: any) => item.message).join('\n'),
                                    };
                                }, {});
                                const { generic, ...rest } = errors;
                                action(updateSyncErrors, Form.CHANGE_RATE_FORM, rest, generic);
                                return;
                            }
                            if (status === 500) {
                                action(updateSyncErrors, Form.CHANGE_RATE_FORM, {}, 'common:errors.generic');
                            }
                        }
                    })
                    .finally(() => {
                        action(makeAppUIEnableAction);
                    });
            },
        );
    }, [formData, store?.storeId]);
    const { t } = i18n.useTranslation();
    return (
        <Dialog onClose={onClose} title={t('feed:store.changeRate.title')}>
            <ErrorBoundary>
                {formData ? (
                    <div className={classes.container}>
                        <Text typography={TextTypography.PARA_ACCENT}>
                            {t('feed:store.changeRate.text', {
                                rate: getTranslationForEnum(TranslatableEnum.STORE_TYPE_RATE, formData.rateType, t),
                                date: formatISODateTime(formData.startDate.toString()),
                            })}
                        </Text>
                        <div className={classes.buttons}>
                            <div className={classes.button}>
                                <LargeButton color={LargeButtonColor.GRAY} onClick={onClose}>
                                    {t('vendor:userMenu.subscription.dialog.cancel')}
                                </LargeButton>
                            </div>
                            <div className={classes.button}>
                                <LargeButton color={LargeButtonColor.SUCCESS} onClick={handleSubmit}>
                                    {t('feed:store.changeRate.ok')}
                                </LargeButton>
                            </div>
                        </div>
                    </div>
                ) : (
                    <ChangeRateForm onSubmit={handleActivation} />
                )}
            </ErrorBoundary>
        </Dialog>
    );
};
