import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { Footer } from 'components/Footer/Footer';
import { Form } from 'forms/types';
import { FormErrorMessage } from 'components/FormErrorMessage/FormErrorMessage';
import { isUIEnabledSelector } from 'state/app/selectors';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { makeStyles } from '@material-ui/core/styles';
import { useFormError, validator } from 'core/form/form';
import i18n from '../../../i18n';

import { string } from 'yup';
import { TextControlField } from 'components/TextControl/TextControl';
import { FormAddress } from 'components/FormAddress/FormAddress';
import { MobilePhoneControlField } from 'components/MobilePhoneControl/MobilePhoneControl';
import { useAction } from '../../core/store/store';
import { transliterationForUrl } from '../../core/utils/utils';
import { customUrlWithReservedNamesValidator } from '../../core/validators/validators';
import { AddressDto } from '../../types';

export interface CloneStoreFormValue {
    name: string;
    customUrl: string;
    address: string;
    phone: string;
    coordinates: { lat: number; lon: number };
    standardAddress: AddressDto;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
    },
    controlFields: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    fieldContainer: {
        marginTop: theme.spacing(3),
    },
    renewalDateInfo: {
        marginBottom: theme.spacing(2),
    },
    saveButton: {
        padding: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
}));

const formSelector = formValueSelector(Form.CLONE_STORE_FORM);
const nameSelector = <T extends {}>(state: T) => formSelector(state, 'name');

export const CloneStoreForm = reduxForm<CloneStoreFormValue>({
    form: Form.CLONE_STORE_FORM,
    validate: validator({
        name: string().required('common:validation.required'),
        address: string().required('common:validation.required'),
        phone: string().required('common:validation.required'),
        customUrl: customUrlWithReservedNamesValidator,
    }),
})((props: any) => {
    const { form, handleSubmit } = props;

    const isUIEnabled = useSelector(isUIEnabledSelector);
    const name = useSelector(nameSelector);

    const error = useFormError(Form.CLONE_STORE_FORM);

    const { t } = i18n.useTranslation();

    const classes = useStyles();

    const action = useAction();

    const handleCoordinatesChange = useCallback(
        (coordinates: { lat: number; lon: number }) => {
            action(change, form, 'coordinates', coordinates);
        },
        [action, form],
    );

    useEffect(() => {
        if (name) {
            const url = transliterationForUrl(name);
            action(change, form, 'customUrl', url);
        }
    }, [name]);

    const handleStandardAddressChange = useCallback(
        (data: AddressDto) => {
            action(change, form, 'standardAddress', data);
        },
        [action, form],
    );

    return (
        <form onSubmit={handleSubmit} className={classes.container}>
            <div>
                <div className={classes.controlFields}>
                    <div className={classes.fieldContainer}>
                        <Field name="name" component={TextControlField} label={t('feed:store.clone.name')} />
                    </div>
                    <div className={classes.fieldContainer}>
                        <Field
                            name="address"
                            component={FormAddress}
                            label={t('feed:store.clone.address')}
                            initWithGeolocationData={false}
                            buttonText="OK"
                            onCoordinatesChange={handleCoordinatesChange}
                            headerText={t('common:addressDialog.enterShopAddress')}
                            onStandardAddressChange={handleStandardAddressChange}
                        />
                    </div>
                    <div className={classes.fieldContainer}>
                        <Field name="phone" component={MobilePhoneControlField} label={t('feed:store.clone.phone')} />
                    </div>
                    <div className={classes.fieldContainer}>
                        <Field
                            name="customUrl"
                            component={TextControlField}
                            autoCapitalize="none"
                            label={t('feed:store.clone.customUrl')}
                            adornmentStart="any.market/"
                        />
                    </div>

                    {error && (
                        <div className={classes.fieldContainer}>
                            <FormErrorMessage error={error} />
                        </div>
                    )}
                </div>
            </div>

            <Footer className={classes.saveButton}>
                <LargeButton disabled={!isUIEnabled} color={LargeButtonColor.SUCCESS} type="submit">
                    {t('feed:store.clone.submitButton')}
                </LargeButton>
            </Footer>
        </form>
    );
});
