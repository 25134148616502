import React, { FC, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface StoreTabRouteProps extends RouteProps {
    setActiveTab: (path: string) => void;
    path: string;
}

export const StoreTabRoute: FC<StoreTabRouteProps> = props => {
    const { setActiveTab, path } = props;
    useEffect(() => {
        setActiveTab(path);
    }, [path]);
    return <Route {...props} />;
};
