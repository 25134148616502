import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { useRouterParam } from 'core/router/router';

import { makeStoreSelector } from 'state/store/selectors';

import { Icon } from 'components/Icon/Icon';
import { T } from 'components/T/T';
import { TranslatableEnum } from 'components/T/GetTranslationForEnum';

import { DayOfWeek, ScheduleDayDto, TimeBreakDto } from 'types';

import { ReactComponent as PauseIcon } from 'assets/glyphs/pause.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/main-16/clock--16.svg';
import { Text, TextTypography } from '../../../components/Text/Text';
import i18n from '../../../../i18n';

const eqt = (a: TimeBreakDto, b: TimeBreakDto) => {
    return (!a && !b) || (a?.start === b?.start && a?.end === b?.end);
};

const eq = (a: ScheduleDayDto, b: ScheduleDayDto) => {
    return a.opens === b.opens && a.closes === b.closes && eqt(a.timeBreak, b.timeBreak);
};

interface ResultItem {
    schedule: ScheduleDayDto;
    days: DayOfWeek[];
}

const makeSchedule = (schedule: ScheduleDayDto[]) => {
    const result: ResultItem[] = [];
    if (schedule) {
        schedule.forEach((item, i) => {
            if (i === 0) {
                result.push({ schedule: item, days: [item.dayOfWeek] });
            } else {
                const lastItem = result[result.length - 1];
                if (eq(item, lastItem.schedule)) {
                    lastItem.days.push(item.dayOfWeek);
                } else {
                    result.push({ schedule: item, days: [item.dayOfWeek] });
                }
            }
        });
    }
    return result;
};

const useStoreScheduleDayStyles = makeStyles({
    textContainer: {
        whiteSpace: 'nowrap',
        width: 51,
    },
});

const StoreScheduleDay = ({ days }: { days: DayOfWeek[] }) => {
    const classes = useStoreScheduleDayStyles();
    return (
        <div className={classes.textContainer}>
            <T enum={TranslatableEnum.DAY_OF_WEEK}>{days[0]}</T>
            {days.length > 1 && (
                <>
                    &nbsp;–&nbsp;<T enum={TranslatableEnum.DAY_OF_WEEK}>{days[days.length - 1]}</T>
                </>
            )}
        </div>
    );
};

const hm = (time: string) => {
    return time.slice(0, -3);
};

const schemaDays = {
    [DayOfWeek.MONDAY]: 'Mo',
    [DayOfWeek.TUESDAY]: 'Tu',
    [DayOfWeek.WEDNESDAY]: 'We',
    [DayOfWeek.THURSDAY]: 'Th',
    [DayOfWeek.FRIDAY]: 'Fr',
    [DayOfWeek.SATURDAY]: 'Sa',
    [DayOfWeek.SUNDAY]: 'Su',
};

const makeSchemaDays = (days: DayOfWeek[]) => {
    return days.map(d => schemaDays[d]).join(',');
};

const makeSchemaHours = (schedule: ScheduleDayDto) => {
    return `${hm(schedule.opens)}-${hm(schedule.closes)}`;
};

const useStoreScheduleHoursStyles = makeStyles(theme => ({
    textContainer: {
        whiteSpace: 'nowrap',
        marginLeft: 30,
        [theme.breakpoints.down(480)]: {
            marginLeft: 8,
        },
    },
    pause: {
        display: 'inline-block',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        position: 'relative',
        top: 2,
    },
}));

const StoreScheduleHours = ({ item: { timeBreak, opens, closes } }: { item: ScheduleDayDto }) => {
    const classes = useStoreScheduleHoursStyles();
    const { t } = i18n.useTranslation();

    const fullTime = timeBreak === null && opens === '00:00:00' && closes === '23:59:00' && t('feed:store.workTime');

    return timeBreak && timeBreak.start && timeBreak.end ? (
        <div className={classes.textContainer}>
            {hm(opens)}&nbsp;–&nbsp;{hm(timeBreak.start)}
            <span className={classes.pause}>
                <Icon component={PauseIcon} svgSize={16} size={16} />
            </span>
            {hm(timeBreak.end)}&nbsp;–&nbsp;{hm(closes)}
        </div>
    ) : (
        <div className={classes.textContainer}>{fullTime || `${hm(opens)} – ${hm(closes)}`}</div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        borderBottom: '1px solid rgba(15, 24, 37, 0.05)',
        paddingBottom: 16,
    },
    item: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginLeft: 32,
        height: 24,
        minHeight: 24,
        marginTop: 8,
        '&:last-child': {
            borderBottom: 'none',
        },
        [theme.breakpoints.down(480)]: {
            marginRight: 0,
        },
    },
    iconContainer: {
        minWidth: 32,
        display: 'flex',
        alignItems: 'center',
    },
    titleContainer: {
        display: 'flex',
        marginTop: 16,
        alignItems: 'center',
    },
    icon: {
        marginRight: 16,
    },
}));

interface ScheduleProps {
    storeSchedule: ScheduleDayDto[];
    // Optional:
    itemClass?: string;
}

export const Schedule = (props: ScheduleProps) => {
    const classes = useStyles();
    const schedule = makeSchedule(props.storeSchedule || []);
    const { t } = i18n.useTranslation();

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <Icon className={classes.icon} component={ClockIcon} size={16} svgSize={16} />
                <div>
                    <Text itemProp="address" typography={TextTypography.BODY}>
                        {t('feed:store.workingHours')}
                    </Text>
                </div>
            </div>
            {schedule.map((item, i) => {
                const { days, schedule } = item;
                const schema = makeSchemaDays(days) + ' ' + makeSchemaHours(schedule);
                return (
                    <div className={classNames(classes.item, props.itemClass)} key={i}>
                        <meta itemProp="openingHours" content={schema} />
                        <StoreScheduleDay days={days} />
                        <StoreScheduleHours item={schedule} />
                    </div>
                );
            })}
        </div>
    );
};

export const StoreSchedule = () => {
    const storeSlug = useRouterParam('storeSlug');
    const store = useSelector(makeStoreSelector(storeSlug));
    return <Schedule storeSchedule={store?.schedule} />;
};
