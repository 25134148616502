import React from 'react';
import { useSelector } from 'react-redux';
import { DatePickerControlField } from 'components/DatePickerControl/DatePickerControl';
import { DateTime } from 'luxon';
import { Field, reduxForm } from 'redux-form';
import { Footer } from 'components/Footer/Footer';
import { Form } from 'forms/types';
import { FormErrorMessage } from 'components/FormErrorMessage/FormErrorMessage';
import { isUIEnabledSelector } from 'state/app/selectors';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { makeStyles } from '@material-ui/core/styles';
import { Text, TextTypography } from 'components/Text/Text';
import { useFormError, validator } from 'core/form/form';
import i18n from '../../../i18n';
import { SelectEnumControlField } from '../../components/SelectEnumControl/SelectEnumControl';

import { TranslatableEnum } from 'components/T/GetTranslationForEnum';
import { ShopTypeName } from '../../types';
import { string } from 'yup';

export interface ChangeRateFormData {
    startDate: DateTime;
    rateType: ShopTypeName;
}

export interface ChangeRateFormProps {
    handleSubmit: (value: any) => void;
    initialValues?: Partial<ChangeRateFormData>;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
    },
    controlFields: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
    },
    fieldContainer: {
        marginTop: theme.spacing(3),
    },
    renewalDateInfo: {
        marginBottom: theme.spacing(2),
    },
    dateField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    saveButton: {
        padding: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
}));

export const TRIAL_PERIOD_DEFAULT_DATA: ChangeRateFormData = {
    startDate: DateTime.fromJSDate(new Date()),
    rateType: ShopTypeName.ONLINE_SHOP,
};

const rateType = [ShopTypeName.ONLINE_SHOP, ShopTypeName.PRIORITY];

let ChangeRateFormConnected: any = reduxForm({
    form: Form.CHANGE_RATE_FORM,
    initialValues: TRIAL_PERIOD_DEFAULT_DATA,
    validate: validator({
        startDate: string().required('common:validation.required'),
        rateType: string().required('common:validation.required'),
    }),
})((props: ChangeRateFormProps) => {
    const { handleSubmit } = props;
    const { t } = i18n.useTranslation();
    const isUIEnabled = useSelector(isUIEnabledSelector);
    const error = useFormError(Form.CHANGE_RATE_FORM);
    const classes = useStyles();
    return (
        <form onSubmit={handleSubmit} className={classes.container}>
            <div>
                <div className={classes.controlFields}>
                    <div className={classes.fieldContainer}>
                        <Text className={classes.renewalDateInfo} typography={TextTypography.BODY}>
                            {t('feed:store.changeRate.info')}
                        </Text>
                        <Field
                            className={classes.dateField}
                            name="startDate"
                            component={DatePickerControlField}
                            label={t('feed:store.changeRate.startDate')}
                        />
                        <Field
                            name="rateType"
                            component={SelectEnumControlField}
                            label={t('feed:store.changeRate.rateType')}
                            enum={TranslatableEnum.STORE_TYPE_RATE}
                            items={rateType}
                        />
                    </div>
                    {error && (
                        <div className={classes.fieldContainer}>
                            <FormErrorMessage error={error} />
                        </div>
                    )}
                </div>
            </div>
            <Footer className={classes.saveButton}>
                <LargeButton disabled={!isUIEnabled} color={LargeButtonColor.SUCCESS} type="submit">
                    {t('feed:store.changeRate.ok')}
                </LargeButton>
            </Footer>
        </form>
    );
});

export const ChangeRateForm = ChangeRateFormConnected;
