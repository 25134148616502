import React, { FC } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { T } from 'components/T/T';
import { TranslatableEnum } from 'components/T/GetTranslationForEnum';
import { Text, TextTypography } from 'components/Text/Text';
import { Dialog } from 'components/Dialog/Dialog';
import i18n from '../../../../i18n';

import { StoreSchedule } from 'pages/Store/components/StoreSchedule';
import { StorePromo } from 'pages/Store/components/StorePromo';

import { StoreDto } from 'types';

import { StoreContacts } from './StoreContacts';

const useStoreInfoStyles = makeStyles(theme => ({
    categoryContainer: {
        padding: theme.spacing(2, 3),
    },
    container: {
        padding: 0,
        flexDirection: 'column',
    },
    title: {
        paddingBottom: 0,
    },
}));

interface StoreInfoProps {
    showPromo: boolean;
    onClose: () => void;
    store: StoreDto;
}

export const StoreInfo: FC<StoreInfoProps> = ({ onClose, store, showPromo }) => {
    const classes = useStoreInfoStyles();
    const { t } = i18n.useTranslation();

    return (
        <Dialog
            title={t('feed:store.detailInfo')}
            onClose={onClose}
            contentStyles={classes.container}
            titleStyles={classes.title}
            subTitle={
                store?.address && (
                    <Box mt={1} mb={1}>
                        <Text typography={TextTypography.BODY}>{store.address.formattedAddress}</Text>
                    </Box>
                )
            }
        >
            {store?.storeCategory && (
                <div className={classes.categoryContainer}>
                    <Text typography={TextTypography.CAPTION_ACCENT}>
                        <T enum={TranslatableEnum.STORE_CATEGORY}>{store.storeCategory}</T>
                    </Text>
                </div>
            )}
            {showPromo && <StorePromo paymentInfo={store?.storePayments} deliveryInfo={store?.storeDeliveries} />}
            <StoreSchedule />
            <StoreContacts store={store} />
        </Dialog>
    );
};
