import React, { ChangeEvent, useCallback, useState, FC, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, DialogContent, Grid, Link as MuiLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '../../../../i18n';

import { useRouterParam } from 'core/router/router';
import { useAction } from 'core/store/store';
import { formatCurrency, makeUrl } from 'core/utils/utils';

import { makeProductSelector } from 'state/product/selectors';
import { makeCheckoutAddToCartAction } from 'state/checkout/actions';
import { checkoutCartStoreIdSelector } from 'state/checkout/selectors';

import { CloseButton } from 'components/CloseButton/CloseButton';
import { Image, ImageSize } from 'components/Image/Image';
import { LargeButton } from 'components/LargeButton/LargeButton';
import { LayoutFixedItem } from 'components/Layout/LayoutFixedItem';
import { LayoutFullHeightContainer } from 'components/Layout/LayoutFullHeightContainer';
import { PriceForUnit } from 'components/PriceForUnit/PriceForUnit';
import { QuantityWithCustomButtonsControl } from 'components/QuantityWithCustomButtonsControl/QuantityWithCustomButtonsControl';
import { Text, TextTypography } from 'components/Text/Text';
import { TextControl } from 'components/TextControl/TextControl';
import { CheckoutErrorDialog } from './CheckoutErrorDialog';

import { RoutePath } from 'RoutePath';
import { makeStoreSelector } from 'state/store/selectors';
import { Icon } from 'components/Icon/Icon';
import { ReactComponent as InfoIcon } from 'assets/icons/status/exclamation-circle--16.svg';

import { OptionGroups } from './OptionGroups';
import { OptionsMode } from 'forms/AddProductForm/OptionGroup';
import { getInitialOptionsValue } from '../utils';
import { ButtonColor, ButtonRectangular } from '../../../components/Button/ButtonRectangular';
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/main-16/whatsapp--16.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/main-16/phone--16.svg';
import { ContactDto, VendorContactType } from '../../../types';
import { makeStoreClickPhoneAction } from '../../../state/store/actions';

import css from './StoreProductMobile.module.css';
import { GalleryArrow, GalleryArrowDirection } from 'components/Gallery/GalleryArrow';
import Carousel from 'nuka-carousel';

const useDialogContentStyles = makeStyles(theme => ({
    root: {
        paddingTop: '0 !important',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 10,
        },
    },
}));

interface StoreProductMobileProps {
    canBuy: boolean;
}

export const StoreProductMobile: FC<StoreProductMobileProps> = ({ canBuy }) => {
    const storeSlug = useRouterParam('storeSlug');
    const productId = useRouterParam('productId');
    const [quantity, setQuantity] = useState(1);
    const [comment, setComment] = useState('');
    const [checkoutErrorDialog, setCheckoutErrorDialog] = useState(false);
    const history = useHistory();
    const action = useAction();
    const { t } = i18n.useTranslation();
    const store = useSelector(makeStoreSelector(storeSlug));
    const product = useSelector(makeProductSelector(productId));
    const cartStoreId = useSelector(checkoutCartStoreIdSelector);
    const image = product?.images && product?.images[0];
    const imageId = image ? image.imageId : null;
    const storePath = makeUrl(RoutePath.STORE, { storeSlug });
    const dialogContentClasses = useDialogContentStyles();

    const handleAddProduct = useCallback(() => {
        if (cartStoreId && cartStoreId !== store.storeId) {
            setCheckoutErrorDialog(true);
        } else {
            action(makeCheckoutAddToCartAction, store.storeId, product, quantity, selectedOptions);
            history.push(storePath);
        }
    }, [store, storePath, action, history, cartStoreId, product, quantity]);

    const [selectedOptions, setSelectedOptions] = useState(getInitialOptionsValue(product?.optionGroups));
    const [totalSelectedOptionsPrice, setTotalSelectedOptionsPrice] = useState(0);
    const total = product == null ? null : quantity * (product.price + totalSelectedOptionsPrice);

    useEffect(() => {
        const optionsPrice =
            product == null
                ? 0
                : Object.entries(selectedOptions).reduce(
                      (totalOptionsPrice, [optionGroupId, selectedOptionGroupOptionIds]) => {
                          const optionGroupDto = product?.optionGroups.find(
                              optionGroup => optionGroup.optionGroupId === optionGroupId,
                          );
                          if (optionGroupDto) {
                              return (
                                  totalOptionsPrice +
                                  selectedOptionGroupOptionIds.reduce(
                                      (optionGroupPrice, optionId) =>
                                          optionGroupPrice +
                                          (optionGroupDto.options.find(option => option.optionId === optionId)?.price ||
                                              0),
                                      0,
                                  )
                              );
                          }
                          return totalOptionsPrice;
                      },
                      0,
                  );

        setTotalSelectedOptionsPrice(optionsPrice);
    }, [selectedOptions, product?.optionGroups]);

    const prevArrow = useCallback(
        ({ previousSlide }) => {
            return product?.images.length > 1 ? (
                <GalleryArrow type={GalleryArrowDirection.PREV} onClick={previousSlide} />
            ) : null;
        },
        [product],
    );

    const nextArrow = useCallback(
        ({ nextSlide }) => {
            return product?.images.length > 1 ? (
                <GalleryArrow type={GalleryArrowDirection.NEXT} onClick={nextSlide} />
            ) : null;
        },
        [product],
    );

    const validationErrors = product?.optionGroups.reduce((acc, optionGroup) => {
        const { minOptions, maxOptions, optionGroupId, optionGroupMode } = optionGroup;
        const isNumerous = optionGroupMode === OptionsMode.NUMEROUS;
        const selectedOptionsCount = selectedOptions[optionGroupId].length;

        if (isNumerous && selectedOptionsCount < minOptions) {
            return { ...acc, [optionGroupId]: t('common:validation:minCount', { minOptions }) };
        }
        if (isNumerous && selectedOptionsCount > maxOptions) {
            return { ...acc, [optionGroupId]: t('common:validation:maxCount', { maxOptions }) };
        }
        return acc;
    }, {});
    const hasValidationErrors = Object.keys(validationErrors).length > 0;

    const phoneContact = (store?.contacts || []).find((c: ContactDto) => c.type === VendorContactType.PHONE);
    const waContact = (store?.contacts || []).find((c: ContactDto) => c.type === VendorContactType.WA);

    const handleClickPhone = useCallback(() => {
        action(makeStoreClickPhoneAction, store.storeId);
    }, [action, store]);

    return (
        <Fragment>
            <DialogContent className={css.content} classes={dialogContentClasses}>
                <LayoutFullHeightContainer>
                    <div className={css.root}>
                        <Carousel 
                            wrapAround={true}
                            autoplay={false} 
                            renderCenterLeftControls={prevArrow}
                            renderCenterRightControls={nextArrow}
                            >
                            {product.images.map(({ imageId }) => (<Image
                                storeCategory={store?.storeCategory}
                                imageId={imageId}
                                description={product?.name}
                                size={ImageSize.LARGE}
                            />))}
                        </Carousel>
                        <div className={css.close}>
                            <CloseButton onClick={() => history.push(storePath)} />
                        </div>

                        <Box>
                            <Box m={2} className={css.textContainer}>
                                <Text typography={TextTypography.TITLE_ACCENT} className={css.text}>
                                    {product?.name}
                                </Text>
                                <Text typography={TextTypography.PARA} className={css.text}>
                                    {product?.description}
                                </Text>
                            </Box>
                            {product?.optionGroups && (
                                <OptionGroups
                                    value={selectedOptions}
                                    optionGroups={product.optionGroups}
                                    onChange={setSelectedOptions}
                                    validationErrors={validationErrors}
                                />
                            )}
                            <Box m={2}>
                                <div className={css.priceAndQuantity}>
                                    <div className={css.price}>
                                        <PriceForUnit
                                            price={product?.price}
                                            unitType={product?.unitType}
                                            unitQuantity={product?.unitQuantity}
                                            isoCode={store?.address?.isoCode}
                                        />
                                    </div>
                                    {canBuy && (
                                        <div className={css.quantity}>
                                            <QuantityWithCustomButtonsControl value={quantity} onChange={setQuantity} />
                                        </div>
                                    )}
                                </div>
                            </Box>
                            {canBuy && (
                                <Box m={2} mb={15} className={css.textControlContainer}>
                                    <TextControl
                                        label={t('feed:product.comment')}
                                        value={comment}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
                                    />
                                </Box>
                            )}
                        </Box>
                    </div>
                    {canBuy ? (
                        <LayoutFixedItem>
                            <Box p={2} className={css.addToCartButton}>
                                <LargeButton disabled={total == null || hasValidationErrors} onClick={handleAddProduct}>
                                    {t('feed:product.addToCart')}{' '}
                                    {total != null && (
                                        <span>
                                            — {formatCurrency(total, store?.address?.isoCode)}
                                        </span>
                                    )}
                                </LargeButton>
                            </Box>
                        </LayoutFixedItem>
                    ) : (
                        <div className={css.shopWindow}>
                            <div className={css.shopWindowText}>
                                <div className={css.shopWindowBadgeIcon}>
                                    <Icon component={InfoIcon} size={16} svgSize={16} />
                                </div>
                                <Text typography={TextTypography.PARA}>{t('feed:product.shopWindowInfo')}</Text>
                            </div>

                            <Grid className={css.contactStore} container spacing={2}>
                                {waContact && (
                                    <Grid item xs={6} sm={3}>
                                        <MuiLink
                                            href={`https://wa.me/${waContact?.value.replace(/[+()\- ]/g, '')}`}
                                            underline="none"
                                            color="inherit"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <ButtonRectangular
                                                label={t('feed:store.whatsapp')}
                                                icon={WhatsAppIcon}
                                                color={ButtonColor.GREEN}
                                            />
                                        </MuiLink>
                                    </Grid>
                                )}
                                {phoneContact && (
                                    <Grid item xs={6} sm={3}>
                                        <MuiLink
                                            href={`tel:${phoneContact?.value}`}
                                            underline="none"
                                            color="inherit"
                                            onClick={handleClickPhone}
                                        >
                                            <ButtonRectangular label={t('feed:store.call')} icon={PhoneIcon} />
                                        </MuiLink>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    )}
                    {checkoutErrorDialog && (
                        <CheckoutErrorDialog
                            onClose={() => setCheckoutErrorDialog(false)}
                            onSubmit={() => {
                                action(makeCheckoutAddToCartAction, store.storeId, product, quantity, selectedOptions);
                                setCheckoutErrorDialog(false);
                                history.push(storePath);
                            }}
                        />
                    )}
                </LayoutFullHeightContainer>
            </DialogContent>
        </Fragment>
    );
};
