import React from 'react';
import { Menu, MenuItem, PopoverOrigin } from '@material-ui/core';

import i18n from '../../../i18n';
import css from './StoreContextMenu.module.css';
import { UserRole } from '../../types';

interface StoreContextMenuProps {
    adminRole: UserRole;
    storeCountryCode?: string;

    anchor: Element;
    isOpen: boolean;

    isSimpleStore: boolean;
    isStoreModerated: boolean;
    isStoreOwnerFake: boolean;
    hasSubscription: boolean;

    onClose: () => void;
    onLogInAsAdmin: () => void;
    onDelete: () => void;
    onChangeStoreModeratedStatus: () => void;
    onCreateLink: () => void;
    onChangeRate: () => void;
    onExtendTrialPeriod: () => void;
    onConvertToSellingStore: () => void;
    onStickersSent: () => void;

    onClone: () => void;
}

const ANCHOR_ORIGIN: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' };

const TRANSFORM_ORIGIN: PopoverOrigin = { vertical: 'top', horizontal: 'right' };

const PAPER_PROPS = {
    style: {
        marginTop: 8,
        borderRadius: 10,
        padding: 8,
    },
};

const MENU_LIST_PROPS = {
    style: {
        padding: 0,
    },
};

export const StoreContextMenu = (props: StoreContextMenuProps) => {
    const {
        adminRole,
        storeCountryCode,
        anchor,
        isOpen,
        isSimpleStore,
        isStoreModerated,
        isStoreOwnerFake,
        hasSubscription,
        onClose,
        onLogInAsAdmin,
        onDelete,
        onChangeStoreModeratedStatus,
        onCreateLink,
        onChangeRate,
        onExtendTrialPeriod,
        onConvertToSellingStore,
        onStickersSent,
        onClone,
    } = props;

    const { t } = i18n.useTranslation();

    const logAsVisible =
        adminRole == UserRole.ADMINISTRATOR || (adminRole == UserRole.OPERATION_ADMIN && storeCountryCode);
    const deleteStoreVisible = adminRole == UserRole.ADMINISTRATOR;

    return (
        <Menu
            anchorEl={anchor}
            keepMounted
            open={isOpen}
            getContentAnchorEl={null}
            anchorOrigin={ANCHOR_ORIGIN}
            transformOrigin={TRANSFORM_ORIGIN}
            PaperProps={PAPER_PROPS}
            MenuListProps={MENU_LIST_PROPS}
            onClose={onClose}
        >
            {logAsVisible && (
                <MenuItem className={css.menuItem} onClick={onLogInAsAdmin}>
                    {t('feed:store.logInAsAdmin')}
                </MenuItem>
            )}
            {deleteStoreVisible && (
                <MenuItem className={css.menuItem} onClick={onDelete}>
                    {t('feed:store.deleteStore')}
                </MenuItem>
            )}
            <MenuItem className={css.menuItem} onClick={onChangeStoreModeratedStatus}>
                {t(isStoreModerated ? 'feed:store.downStatus' : 'feed:store.upStatus')}
            </MenuItem>
            <MenuItem className={css.menuItem} onClick={onCreateLink}>
                {t('feed:store.createLink.createLink')}
            </MenuItem>
            <MenuItem className={css.menuItem} onClick={onChangeRate}>
                {t('feed:store.changeRate.title')}
            </MenuItem>
            {!isStoreOwnerFake && hasSubscription && (
                <MenuItem className={css.menuItem} onClick={onExtendTrialPeriod}>
                    {t('feed:store.extendTrialPeriod.title')}
                </MenuItem>
            )}
            {isStoreOwnerFake && isSimpleStore && (
                <MenuItem className={css.menuItem} onClick={onConvertToSellingStore}>
                    {t('feed:store.convertToSelling.title')}
                </MenuItem>
            )}
            {!isSimpleStore && (
                <MenuItem className={css.menuItem} onClick={onConvertToSellingStore}>
                    {t('feed:store.convertToSimple.title')}
                </MenuItem>
            )}
            <MenuItem className={css.menuItem} onClick={onStickersSent}>
                {t('feed:store.stickersSent')}
            </MenuItem>
            <MenuItem className={css.menuItem} onClick={onClone}>
                {t('feed:store.cloneStore')}
            </MenuItem>
        </Menu>
    );
};
