import React, { FC, useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link as MuiLink } from '@material-ui/core';

import { ContactDto, PromoDto, StoreDto, VendorContactType } from '../../types';

import { ReactComponent as WhatsAppIcon } from '../../assets/icons/main-16/whatsapp--16.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/main-16/phone--16.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/main-16/user--16.svg';
import { ReactComponent as MapIcon } from '../../assets/icons/main-16/map-marker--16.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/web-16/star--16.svg';
import { ReactComponent as SaleIcon } from '../../assets/icons/web-16/store-sale-16.svg';
import { StoreState } from 'state/store/reducer';
import i18n from '../../../i18n';
import { Text, TextTypography } from '../Text/Text';
import { Icon } from '../Icon/Icon';
import { ButtonColor, ButtonRectangular } from '../Button/ButtonRectangular';
import { RoutePath } from 'RoutePath';
import { makeUrl } from 'core/utils/utils';
import { getInstagramPosts } from 'core/instagram/instagram';
import { InstagramGallery } from '../InstagramGallery/InstagramGallery';
import { env } from '../../env';
import css from './InfoBlock.module.css';

interface InstagramPost {
    id: string;
    media_url: string;
    caption: string;
}

export interface InfoBlockProps {
    promos?: PromoDto[];
    onClick?: () => void;
    storeState?: StoreState;
    openStoreInfo?: () => void;
    stor?: StoreDto;
    canPinStoreToCurrentUser?: boolean;
    handlePinStoreToCurrentUser?: () => void;
    handleClickPhone?: () => void;
}

export const InfoBlock: FC<InfoBlockProps> = ({
    storeState,
    stor,
    canPinStoreToCurrentUser,
    handlePinStoreToCurrentUser,
    handleClickPhone,
}) => {
    const { t } = i18n.useTranslation();
    const { store } = storeState;

    const tags = stor?.tags ?? store?.tags ?? [];

    const phoneContact = (store?.contacts || []).find((c: ContactDto) => c.type === VendorContactType.PHONE);
    const waContact = (store?.contacts || []).find((c: ContactDto) => c.type === VendorContactType.WA);

    const handleSetMapClick = useCallback(() => {
        const { address, storeId } = store;
        const storeFeedUrl = makeUrl(
            RoutePath.FEED,
            {},
            address?.geoCoordinates
                ? { lat: address.geoCoordinates.latitude, lon: address.geoCoordinates.longitude, storeId }
                : {},
        );
        window.location.assign(storeFeedUrl);
    }, [store]);

    const [instagramPosts, setInstagramPosts] = useState<InstagramPost[] | null>(null);
    useEffect(() => {
        if (env.useInstagramFeature && store.storeId) {
            getInstagramPosts(store.storeId).then(setInstagramPosts).catch();
        }
    }, [store, setInstagramPosts]);

    return (
        <div className={css.container}>
            <Grid container spacing={2}>
                {waContact && (
                    <Grid item xs={6} sm={3}>
                        <MuiLink
                            href={`https://wa.me/${waContact?.value.replace(/[+()\- ]/g, '')}`}
                            underline="none"
                            color="inherit"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <ButtonRectangular
                                label={t('feed:store.whatsapp')}
                                icon={WhatsAppIcon}
                                color={ButtonColor.GREEN}
                            />
                        </MuiLink>
                    </Grid>
                )}
                {phoneContact && (
                    <Grid item xs={6} sm={3}>
                        <MuiLink
                            href={`tel:${phoneContact?.value}`}
                            underline="none"
                            color="inherit"
                            onClick={handleClickPhone}
                        >
                            <ButtonRectangular label={t('feed:store.call')} icon={PhoneIcon} />
                        </MuiLink>
                    </Grid>
                )}
                {canPinStoreToCurrentUser && (
                    <Grid item xs={6} sm={3}>
                        <div onClick={handlePinStoreToCurrentUser}>
                            <ButtonRectangular icon={UserIcon} label={t('feed:store.owningStore.title')} />
                        </div>
                    </Grid>
                )}
                <Grid item xs={6} sm={3}>
                    <div onClick={handleSetMapClick}>
                        <ButtonRectangular icon={MapIcon} label={t('feed:feed.mapButton')} />
                    </div>
                </Grid>
            </Grid>
            {store?.shortDescription && (
                <div className={css.title}>
                    <Icon component={SaleIcon} className={css.subTitleIcon} />
                    <Text typography={TextTypography.BODY}>{store?.shortDescription}</Text>
                </div>
            )}
            {store?.sellingDescription && (
                <div className={css.description}>
                    <Text typography={TextTypography.BODY}>{store?.sellingDescription}</Text>
                </div>
            )}
            {store?.fullDescription && (
                <div className={css.description} dangerouslySetInnerHTML={{ __html: store?.fullDescription }} />
            )}
            {store?.advantages && (
                <div className={css.subTitle}>
                    <Icon component={StarIcon} className={css.subTitleIcon} />
                    <Text typography={TextTypography.BODY}>{store?.advantages}</Text>
                </div>
            )}
            <ul className={css.tags}>
                {tags.map((tag, index) => (
                    <li key={index} className={css.tag}>
                        <Text typography={TextTypography.BODY_LIGHT}>#{tag?.name}</Text>
                    </li>
                ))}
            </ul>
            {instagramPosts && instagramPosts.length > 0 && (
                <InstagramGallery condensed={false} showTitle={true} posts={instagramPosts} />
            )}
        </div>
    );
};
