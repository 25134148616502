import React, { useCallback } from 'react';
import i18n from '../../../../i18n';
import { StoreDto } from 'types';

import { Dialog } from 'components/Dialog/Dialog';
import { ErrorBoundary } from 'components/ErrorBoundary';

import { useAction } from 'core/store/store';

import { CloneStoreForm, CloneStoreFormValue } from 'forms/CloneStoreForm/CloneStoreForm';

import { makeStoreCloneAction } from 'state/store/actions';

interface CloneDialogProps {
    store: StoreDto;
    onClose: () => void;
}

export const CloneDialog = (props: CloneDialogProps) => {
    const { store, onClose } = props;

    const { t } = i18n.useTranslation();

    const action = useAction();

    const handleSubmit = useCallback(
        (formValue: CloneStoreFormValue) => {
            action(makeStoreCloneAction, store, formValue);
            onClose();
        },
        [action, store, onClose],
    );

    return (
        <Dialog onClose={onClose} title={t('feed:store.clone.title')}>
            <ErrorBoundary>
                <CloneStoreForm onSubmit={handleSubmit} />
            </ErrorBoundary>
        </Dialog>
    );
};
