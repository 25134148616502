import React from 'react';
import { Icon } from '../Icon/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { PromoDto, PromoLabel, SupportedCountryCodes } from 'types';
import { ReactComponent as GiftIcon } from 'assets/glyphs/gift.svg';
import { ReactComponent as PercentIcon } from 'assets/glyphs/percent.svg';
import { Text, TextTypography } from '../Text/Text';
import i18n from '../../../i18n';
import { formatCurrency } from '../../core/utils/utils';

export interface PromotionsCardProps {
    promo: PromoDto;
    isoCode?: SupportedCountryCodes;
    onClick?: () => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        flex: 1,
        backgroundColor: '#FDFFFC',
        boxShadow: '0px 0px 20px 8px rgba(15, 24, 37, 0.05)',
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1.5, 2),
        minWidth: 136,
    },
    icon: {
        height: '100%',
        width: 40,
        borderRadius: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    promoItemTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'initial',
        justifyContent: 'space-between',
        overflow: 'hidden',
        minHeight: 112,

        height: '100%',
    },
    promoItemText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    promoItemConditionContainer: {
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: theme.spacing(2),
    },
}));

const getPromoIcon = (promoImage: PromoLabel) => {
    switch (promoImage) {
        case PromoLabel.PERCENT:
            return PercentIcon;
        default:
            return GiftIcon;
    }
};

export const PromotionsCard = (props: PromotionsCardProps) => {
    const { promo, isoCode } = props;
    const classes = useStyles();
    const { t } = i18n.useTranslation();
    const promoIcon = getPromoIcon(promo.image);

    return (
        <div className={classes.container}>
            <div className={classes.icon}>
                <Icon color={'white'} component={promoIcon} svgSize={40} size={40} />
            </div>
            <div className={classes.promoItemTextContainer}>
                <Text className={classes.promoItemText} typography={TextTypography.BODY}>
                    {promo.description}
                </Text>
                <ul className={classes.promoItemConditionContainer}>
                    <li>
                        <Text typography={TextTypography.BODY}>
                            {t('vendor:promos.list.minPrice', {
                                minOrderPrice: formatCurrency(promo?.conditions?.minOrderPrice, isoCode)
                            })}
                        </Text>
                    </li>
                    <li>
                        <Text typography={TextTypography.BODY}>
                            {t(`common:enums.PROMO_DELIVERY_TYPE.${promo?.conditions?.deliveryType}`)}
                        </Text>
                    </li>
                </ul>
            </div>
        </div>
    );
};
