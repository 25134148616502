import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { makeUrl } from 'core/utils/utils';
import { RoutePath } from 'RoutePath';
import { Stage } from 'components/Stage/Stage';
import { StageItem } from 'components/Stage/StageItem';
import { Text, TextTypography } from 'components/Text/Text';
import i18n from '../../../../i18n';
import css from './StoreTabs.module.css';

export interface StoreTabItemProps {
    to: RoutePath;
    text: string;
    storeSlug: string;
    // Optional:
    selected?: boolean;
}

const StoreTabItem: FC<StoreTabItemProps> = ({ to, text, storeSlug, selected, ...rest }) => {
    const link = useMemo(() => makeUrl(to, { storeSlug }), [to, storeSlug]);
    return (
        <StageItem
            component={Link}
            to={link}
            className={css.storeTab}
            label={
                <Text component="span" typography={TextTypography.BODY_HEADING}>
                    {text}
                </Text>
            }
            selected={selected}
            {...rest}
        />
    );
};

export const StoreTabs = (props: { storeSlug: string; activeTabRoute: RoutePath }) => {
    const { t } = i18n.useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    useEffect(() => {
        switch (props.activeTabRoute) {
            case RoutePath.STORE_INFO_TAB: {
                setActiveTab(1);
                break;
            }
            case RoutePath.STORE_PROMOTIONS_TAB: {
                setActiveTab(2);
                break;
            }
            // case RoutePath.STORE_SERVICES_TAB: {
            //     setActiveTab(3);
            //     break;
            // }
            case RoutePath.STORE_DELIVERY_TAB: {
                setActiveTab(3);
                break;
            }
            case RoutePath.STORE_PAYMENT_TAB: {
                setActiveTab(4);
                break;
            }
            // case RoutePath.STORE_GALLERY_TAB: {
            //     setActiveTab(6);
            //     break;
            // }
            // case RoutePath.STORE_REQUISITES_TAB: {
            //     setActiveTab(7);
            //     break;
            // }
            // case RoutePath.STORE_CONTACTS_TAB: {
            //     setActiveTab(8);
            //     break;
            // }
            case RoutePath.STORE_CATALOG_TAB:
            default: {
                setActiveTab(0);
                break;
            }
        }
    }, [props.activeTabRoute]);

    return (
        <div className={css.tabs}>
            <Stage value={activeTab} onChange={setActiveTab} styles={{ width: '100%', padding: 0 }}>
                <StoreTabItem
                    to={RoutePath.STORE_CATALOG_TAB}
                    storeSlug={props.storeSlug}
                    text={t('feed:store.pages.catalog')}
                />
                <StoreTabItem
                    to={RoutePath.STORE_INFO_TAB}
                    storeSlug={props.storeSlug}
                    text={t('feed:store.pages.info')}
                />
                <StoreTabItem
                    to={RoutePath.STORE_PROMOTIONS_TAB}
                    storeSlug={props.storeSlug}
                    text={t('feed:store.pages.promotions')}
                />
                <StoreTabItem
                    to={RoutePath.STORE_DELIVERY_TAB}
                    storeSlug={props.storeSlug}
                    text={t('feed:store.pages.delivery')}
                />
                <StoreTabItem
                    to={RoutePath.STORE_PAYMENT_TAB}
                    storeSlug={props.storeSlug}
                    text={t('feed:store.pages.payment')}
                />
                {/* <StoreTabItem
                    to={RoutePath.STORE_GALLERY_TAB}
                    storeSlug={props.storeSlug}
                    text={t('feed:store.pages.gallery')}
                /> */}
            </Stage>
        </div>
    );
};
