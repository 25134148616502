import React, { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { makeUrl } from 'core/utils/utils';

import { Endpoint } from 'Endpoint';
import { StoreCategory } from 'types';
import DefaultStoreProductItemImage from 'images/DefaultProductImageStore.webp';
import DefaultStoreFlowersItemImage from 'images/DefaultFlowersImageStore.webp';
import DefaultStoreItemImage from 'images/DefaultImageStore.webp';
import DefaultStoreBeautyItemImage from 'images/DefaultBeautyImageStore.webp';

export enum ImageSize {
    NORMAL,
    LARGE,
    RECTANGULAR,
}

export interface ImageProps {
    imageId: string;
    description: string;
    // Optional:
    storeCategory?: StoreCategory;
    size?: ImageSize;
    style?: Partial<CSSProperties>;
    sizes?: string;
}

const heights = {
    [ImageSize.NORMAL]: '50vw',
    [ImageSize.LARGE]: '100vw',
    [ImageSize.RECTANGULAR]: '100%',
};

const IMAGE_SIZES = [180, 360, 720, 1080];

const useStyles = makeStyles(theme => ({
    image: {
        objectFit: 'cover',
        width: (props: ImageProps) => (props.size === ImageSize.RECTANGULAR ? '100%' : '100vw'),
        height: (props: ImageProps) => heights[props.size || ImageSize.NORMAL],
    },
    placeholder: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: ({ storeCategory }: Partial<ImageProps>) => {
            switch (storeCategory) {
                case StoreCategory.PRODUCTS:
                case StoreCategory.RESTAURANTS:
                    return `url(${DefaultStoreProductItemImage})`;
                case StoreCategory.FLOWERS:
                    return `url(${DefaultStoreFlowersItemImage})`;
                case StoreCategory.BEAUTY_HEALTH:
                    return `url(${DefaultStoreBeautyItemImage})`;
                default:
                    return `url(${DefaultStoreItemImage})`;
            }
        },
        width: (props: ImageProps) => (props.size === ImageSize.RECTANGULAR ? '100%' : '100vw'),
        height: (props: ImageProps) => heights[props.size || ImageSize.NORMAL],
    },
}));

export const Image = (props: ImageProps) => {
    const classes = useStyles(props);
    const { imageId, description, style } = props;
    if (!imageId) {
        return <div style={style} className={classes.placeholder} />;
    }
    const src = makeUrl(Endpoint.IMAGE_BY_ID, { imageId });
    return (
        <img
            style={style}
            className={classes.image}
            src={src}
            alt={description}
            srcSet={props.sizes ? IMAGE_SIZES.map(h => `${src}?img_v=${h} ${h}w`).join(', ') : undefined}
            sizes={props.sizes}
        />
    );
};
