import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as PlusIcon } from 'assets/icons/main-16/plus--16.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/main-16/minus--16.svg';
import { IconButton, Input, InputAdornment, SvgIcon } from '@material-ui/core';
import { WrappedFieldProps } from 'redux-form';
import { Icon } from 'components/Icon/Icon';

const useStylesInput = makeStyles(theme => ({
    root: {
        borderRadius: 4,
        backgroundColor: theme.palette.brandPrimary.lightest,
        height: 48,
        width: '100%',
        margin: theme.spacing(0, 2),
    },
    input: {
        fontSize: 16,
        textAlign: 'left',
        paddingLeft: theme.spacing(1),
        padding: 'initial',
    },
}));

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 232,
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        borderRadius: 100,
        border: 'none',
        backgroundColor: theme.palette.fixedPrimary.primary,
        padding: theme.spacing(1),
        height: theme.spacing(4),
        width: theme.spacing(4),
        // marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.fixedPrimary.primary,
        },
    },
    icon: {
        display: 'block',
        fill: theme.palette.fixedWhite.primary,
        width: 16,
        height: 16,
    },
}));

export interface QuantityWithCustomButtonsControlProps {
    value: number;
    onChange: (event: any) => void;
    // Optional
    isDisabled?: boolean;
    hasError?: boolean;
}

export const QuantityWithCustomButtonsControl: FC<QuantityWithCustomButtonsControlProps> = props => {
    const classes = useStyles();
    const inputClasses = useStylesInput();
    const { isDisabled, value, onChange, hasError } = props;
    const min = 0;
    const max = 48;

    return (
        <div className={classes.container}>
            <IconButton
                disabled={isDisabled}
                className={classes.button}
                onClick={() => {
                    if (value > min) {
                        onChange(value - 1);
                    }
                }}
            >
                <Icon className={classes.icon} component={MinusIcon} svgSize={16} />
            </IconButton>

            <Input
                type="number"
                disableUnderline
                endAdornment={
                    <InputAdornment position="end">
                        {/*<IconButton*/}
                        {/*    disabled={isDisabled}*/}
                        {/*    className={classes.button}*/}
                        {/*    onClick={() => {*/}
                        {/*        if (value > min) {*/}
                        {/*            onChange(value - 1);*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <Icon className={classes.icon} component={MinusIcon} svgSize={16} />*/}
                        {/*</IconButton>*/}
                        {/*<IconButton*/}
                        {/*    disabled={isDisabled}*/}
                        {/*    className={classes.button}*/}
                        {/*    onClick={() => {*/}
                        {/*        if (value < max) {*/}
                        {/*            onChange(value + 1);*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <Icon className={classes.icon} component={PlusIcon} svgSize={16} />*/}
                        {/*</IconButton>*/}
                    </InputAdornment>
                }
                disabled={isDisabled}
                inputProps={{ min, max }}
                classes={inputClasses}
                error={hasError}
                value={value}
                onChange={e => onChange(Number(e.target.value))}
            />
            <IconButton
                disabled={isDisabled}
                className={classes.button}
                onClick={() => {
                    if (value < max) {
                        onChange(value + 1);
                    }
                }}
            >
                <Icon className={classes.icon} component={PlusIcon} svgSize={16} />
            </IconButton>
        </div>
    );
};

export interface QuantityWithCustomButtonsControlFieldOwnProps {
    name: string;
    value: number;
    onChange: (event: any) => void;
    // Optional
    isDisabled?: boolean;
}

export type QuantityWithCustomButtonsControlFieldProps = QuantityWithCustomButtonsControlFieldOwnProps &
    WrappedFieldProps;

export const QuantityWithCustomButtonsControlField: FC<QuantityWithCustomButtonsControlFieldProps> = props => {
    const {
        isDisabled,
        input,
        meta: { touched, invalid },
    } = props;
    return <QuantityWithCustomButtonsControl {...input} isDisabled={isDisabled} hasError={touched && invalid} />;
};
