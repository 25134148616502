import React, { useCallback } from 'react';
import i18n from '../../../../i18n';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { loginAs } from 'core/auth/auth';

import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { Text, TextTypography } from 'components/Text/Text';

import { RoutePath } from 'RoutePath';

import { UserRole } from 'types';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
});

export const StoreVendorWarning = () => {
    const { t } = i18n.useTranslation();
    const history = useHistory();
    const classes = useStyles();

    const onLogInAsCustomer = useCallback(() => {
        loginAs(UserRole.CUSTOMER);
    }, []);

    const onCancel = useCallback(() => {
        history ? history.push(RoutePath.VENDOR_DASHBOARD) : window.open(RoutePath.VENDOR_DASHBOARD);
    }, [history]);

    return (
        <div className={classes.container}>
            <div>
                <Box m={2} mb={6}>
                    <Text typography={TextTypography.PARA_ACCENT}>{t('feed:store.logInAsCustomerDescription')}</Text>
                </Box>
                <Box m={2}>
                    <LargeButton onClick={onLogInAsCustomer}>OK</LargeButton>
                </Box>
                <Box m={2}>
                    <LargeButton color={LargeButtonColor.TRANSPARENT} onClick={onCancel}>
                        {t('feed:store.logInAsCustomerCancel')}
                    </LargeButton>
                </Box>
            </div>
        </div>
    );
};
