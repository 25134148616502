import { Dialog } from 'components/Dialog/Dialog';
import { ExtendTrialPeriodForm, ExtendTrialPeriodFormData } from 'forms/ExtendTrialPeriodForm/ExtendTrialPeriodForm';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { useCallback } from 'react';
import i18n from '../../../../i18n';
import { useSelector } from 'react-redux';
import { makeUrl } from 'core/utils/utils';
import { Endpoint } from 'Endpoint';
import { StoreDto } from 'types';
import { putRequest } from 'core/axios/axios';
import { useAction } from 'core/store/store';
import { Form } from 'forms/types';
import { updateSyncErrors } from 'redux-form';
import { makeAppUIDisableAction, makeAppUIEnableAction } from 'state/app/actions';
import { makeStoreFetchSubscriptionInfoAction } from 'state/store/actions';
import { storeSubscriptionSelector } from 'state/store/selectors';

interface ExtendTrialPeriodDialogProps {
    store: StoreDto;
    onClose: () => void;
}

export const ExtendTrialPeriodDialog = (props: ExtendTrialPeriodDialogProps) => {
    const { store, onClose } = props;
    const action = useAction();
    const storeSubscriptionPlan = useSelector(storeSubscriptionSelector);
    const handleSubmit = useCallback(
        (formData: ExtendTrialPeriodFormData) => {
            action(makeAppUIDisableAction);
            const url = makeUrl(Endpoint.VENDOR_SUBSCRIPTION_TRIAL, { storeId: store.storeId });
            putRequest(url, {
                ...storeSubscriptionPlan,
                renewalDate: formData.renewalDate.toUTC(),
            })
                .then(() => {
                    action(makeStoreFetchSubscriptionInfoAction, store.storeId);
                    onClose();
                })
                .catch(e => {
                    if (e.isAxiosError && e.response) {
                        const { data, status } = e.response;
                        if ((status === 400 || status === 409) && data && data.errors) {
                            const errors: Record<string, string> = Object.keys(data.errors).reduce((acc, k) => {
                                return {
                                    ...acc,
                                    [k]: (data.errors[k] ?? []).map((item: any) => item.message).join('\n'),
                                };
                            }, {});
                            const { generic, ...rest } = errors;
                            action(updateSyncErrors, Form.EXTEND_TRIAL_PERIOD_FORM, rest, generic);
                            return;
                        }
                        if (status === 500) {
                            action(updateSyncErrors, Form.EXTEND_TRIAL_PERIOD_FORM, {}, 'common:errors.generic');
                        }
                    }
                })
                .finally(() => {
                    action(makeAppUIEnableAction);
                });
        },
        [storeSubscriptionPlan, store?.storeId],
    );
    const { t } = i18n.useTranslation();
    return (
        <Dialog onClose={onClose} title={t('feed:store.extendTrialPeriod.title')}>
            <ErrorBoundary>
                <ExtendTrialPeriodForm onSubmit={handleSubmit} />
            </ErrorBoundary>
        </Dialog>
    );
};
