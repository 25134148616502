import React from 'react';
import { PromoDto, SupportedCountryCodes } from '../../types';
import { PromotionsCard } from './PromotionsCard';
import { Text, TextTypography } from '../Text/Text';
import i18n from '../../../i18n';
import css from './PromotionsBlock.module.css';

export interface PromotionsBlockProps {
    promos: PromoDto[];
    isoCode?: SupportedCountryCodes;
    onClick?: () => void;
}

export const PromotionsBlock = (props: PromotionsBlockProps) => {
    const { t } = i18n.useTranslation();
    return (
        <div className={css.container}>
            {props.promos.length > 0 ? (
                props.promos.map(promo => {
                    return (
                        <div className={css.promoItemContainer} key={promo.promoId}>
                            <PromotionsCard promo={promo} isoCode={props.isoCode} />
                        </div>
                    );
                })
            ) : (
                <div className={css.promoContainer}>
                    <Text typography={TextTypography.BODY}>{t('feed:store.promotions.text')}</Text>
                </div>
            )}
        </div>
    );
};
