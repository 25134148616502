import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import maxBy from 'lodash/maxBy';
import useSize from '@react-hook/size';

import { LayoutFullHeightContainer } from '../Layout/LayoutFullHeightContainer';
import { ProductListItem } from './ProductListItem';
import { Text, TextTypography } from '../Text/Text';

import { CategoryDto, ProductDto, StoreCategory } from 'types';
import classNames from 'classnames';
import { ProductCategoriesChips } from './ProductCategoriesChips';
import i18n from '../../../i18n';

interface MakeStyleProps {
    containerWidth: number;
}

const useStyles = makeStyles(theme => ({
    stickyHeader: {
        position: 'sticky',
        height: 48,
        width: (props: MakeStyleProps) => props.containerWidth || 'auto',
        backgroundColor: '#fafafa',
        top: 0,
        left: 0,
        zIndex: 1,
    },
    stickyHeaderChip: {
        position: 'sticky',
        minHeight: 40,
        width: (props: MakeStyleProps) => props.containerWidth || 'auto',
        backgroundColor: '#fafafa',
        top: 0,
        left: 0,
        zIndex: 1,
        marginTop: theme.spacing(2),
    },
    pinToTop: {
        top: 0,
    },
    list: {
        height: '100%',
        padding: '0 40px',
        [theme.breakpoints.down(640)]: {
            padding: '0 24px',
        },
        [theme.breakpoints.down(460)]: {
            padding: '0 16px',
        },
    },
    listItem: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        minWidth: 0,
        [theme.breakpoints.down(500)]: {
            height: '100%',
            width: '100%',
        },
    },
    title: {
        display: 'flex',
        marginTop: theme.spacing(5),
        [theme.breakpoints.down(641)]: {
            marginTop: theme.spacing(3),
        },
    },
    subtitle: {
        color: 'rgba(36, 49, 67, 0.4)',
        marginLeft: theme.spacing(1),
    },
    product: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
    },
}));

export interface ProductListProps {
    categories: CategoryDto[];
    products: Record<string, ProductDto[]>;
    // Optional:
    storeCategory?: StoreCategory;
    onProductClick?: (productId: string, categoryId: string) => void;
    pinToTopCategories?: boolean;
}

export const ProductList = (props: ProductListProps) => {
    const [activeChip, setActiveChip] = useState(0);
    const anchorRefs = useRef<Record<string, HTMLDivElement>>({});
    const { t } = i18n.useTranslation();
    const nonEmptyCategories = props.categories.filter(c => {
        const categoryProducts = props.products[c.categoryId];
        return categoryProducts && categoryProducts.length > 0;
    });

    const activeChipChangedManually = useRef(false);
    const setActiveChipManually = useCallback(
        newActiveChipIndex => {
            activeChipChangedManually.current = true;
            setActiveChip(newActiveChipIndex);
        },
        [activeChipChangedManually, setActiveChip],
    );

    useEffect(() => {
        const onScroll = debounce(() => {
            if (!activeChipChangedManually.current) {
                const visibleAreas = Object.values(anchorRefs.current).map((div, index) => {
                    if (!div) {
                        return { area: -1, index };
                    }
                    const rect = div.getBoundingClientRect();
                    const a = Math.max(0, rect.top);
                    const b = Math.min(window.outerHeight, rect.bottom);
                    return { area: b - a, index };
                });
                const maxByVisibleAreas = maxBy(visibleAreas, 'area');
                setActiveChip(maxByVisibleAreas?.index);
            }
            activeChipChangedManually.current = false;
        }, 150);
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [nonEmptyCategories, activeChipChangedManually]);

    const container = useRef<HTMLDivElement>();
    const [containerWidth] = useSize(container);

    const classes = useStyles({ containerWidth });

    return (
        <div ref={container}>
            <LayoutFullHeightContainer>
                <div className={classNames(classes.stickyHeaderChip, props.pinToTopCategories && classes.pinToTop)}>
                    <ProductCategoriesChips
                        activeChip={activeChip}
                        setActiveChip={setActiveChipManually}
                        categories={nonEmptyCategories}
                        onChipClick={(categoryId: string) => {
                            const ref = anchorRefs.current[categoryId];
                            if (ref) {
                                ref.scrollIntoView({
                                    block: 'center',
                                });
                            }
                        }}
                    />
                </div>

                <div className={classes.list} itemType="http://schema.org/BreadcrumbList">
                    {nonEmptyCategories.map(category => {
                        const { categoryId } = category;
                        return (
                            <div
                                key={categoryId}
                                ref={r => {
                                    anchorRefs.current[categoryId] = r;
                                }}
                                itemProp="itemListElement"
                                itemType="http://schema.org/ListItem"
                            >
                                <Box mb={3} className={classes.title}>
                                    <Text
                                        typography={TextTypography.TITLE_ACCENT}
                                        shouldCollapseText={true}
                                        itemProp="name"
                                    >
                                        {t(category.name)}
                                    </Text>
                                </Box>
                                <Grid container direction={'row'} justify={'flex-start'} wrap={'wrap'} spacing={3}>
                                    {props.products[category.categoryId].map((product, i) => (
                                        <Grid item xs={6} sm={4} md={3} xl={2} className={classes.listItem} key={i}>
                                            <ProductListItem
                                                product={product}
                                                index={i}
                                                storeCategory={props.storeCategory}
                                                onProductClick={(productId, categoryId) => {
                                                    if (props.onProductClick) {
                                                        props.onProductClick(productId, categoryId);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        );
                    })}
                </div>
            </LayoutFullHeightContainer>
        </div>
    );
};
