import { makeStyles } from '@material-ui/core';
import { BannerCarousel } from 'components/BannerCarousel/BannerCarousel';
import { FC, useCallback } from 'react';
import { StoreCategory, StoreDto } from 'types';
import DefaultProductImageStore from 'images/DefaultProductImageStore.webp';
import DefaultFlowersImageStore from 'images/DefaultFlowersImageStore.webp';
import DefaultBeautyImageStore from 'images/DefaultFlowersImageStore.webp';
import DefaultImageStore from 'images/DefaultImageStore.webp';
import { makeUrl } from 'core/utils/utils';
import { RoutePath } from 'RoutePath';
import { useHistory } from 'react-router';
import css from './StoreBanner.module.css';
import classNames from 'classnames';

interface StoreBannerProps {
    store: StoreDto;
    storeSlug: string;
}

const useStyles = makeStyles(theme => ({
    defaultImageBackground: {
        backgroundImage: ({ storeCategory }: { storeCategory: StoreCategory }) => {
            switch (storeCategory) {
                case StoreCategory.PRODUCTS:
                case StoreCategory.RESTAURANTS:
                    return `url(${DefaultProductImageStore})`;
                case StoreCategory.FLOWERS:
                    return `url(${DefaultFlowersImageStore})`;
                case StoreCategory.BEAUTY_HEALTH:
                    return `url(${DefaultBeautyImageStore})`;
                default:
                    return `url(${DefaultImageStore})`;
            }
        },
    },
}));

export const StoreBanner = (props: StoreBannerProps) => {
    const { store, storeSlug } = props;
    const classes = useStyles({ storeCategory: store?.storeCategory });
    const history = useHistory();
    const isDefaultImage = !(store?.carouselData || []).length;

    const handleBannerClick = useCallback(() => {
        const storePromoTabUrl = makeUrl(RoutePath.STORE_PROMOTIONS_TAB, { storeSlug });
        history.push(storePromoTabUrl);
    }, [history]);
    return (
        <>
            {isDefaultImage ? (
                <div className={css.carouselContainer}>
                    <div
                        title={store?.name}
                        className={classNames(classes.defaultImageBackground, css.default_image)}
                    ></div>
                </div>
            ) : (
                <BannerCarousel
                    carouselItems={store?.carouselData}
                    className={css.carouselContainer}
                    onBannerClick={handleBannerClick}
                />
            )}
        </>
    );
};
