import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PaymentType, StorePaymentDto } from '../../types';
import i18n from '../../../i18n';
import { ErrorBoundary } from '../ErrorBoundary';
import { Box } from '@material-ui/core';
import { ReactComponent as CashIcon } from '../../assets/glyphs/cash.svg';
import { ReactComponent as CardsIcon } from '../../assets/glyphs/cards.svg';
import { ReactComponent as OnlineIcon } from '../../assets/glyphs/online.svg';
import { RadioElement } from '../RadioElement/RadioElement';
import { Text, TextTypography } from '../Text/Text';
import css from './PaymentBlock.module.css';

export interface DeliveryBlockProps {
    payment?: StorePaymentDto[];
}

export const PaymentBlock = (props: DeliveryBlockProps) => {
    const { t } = i18n.useTranslation();

    const paymentItems = (type: string) => {
        switch (type) {
            case PaymentType.CASH:
                return <RadioElement icon={CashIcon} title={t('feed:checkout:payment:CASH')} />;
            case PaymentType.OLPAY:
                return <RadioElement icon={OnlineIcon} title={t('feed:checkout:payment:OLPAY')} />;
            case PaymentType.CARD:
                return <RadioElement icon={CardsIcon} title={t('feed:checkout:payment:CARD')} />;
            default:
                return (
                    <div>
                        <Text typography={TextTypography.BODY}>{t('feed:checkout:payment:noInfo')}</Text>
                    </div>
                );
        }
    };

    return (
        <ErrorBoundary>
            <Box className={css.mainContainer}>
                <div className={css.containerScroll}>
                    {props.payment &&
                        props.payment.map((item: any) => {
                            return paymentItems(item.type);
                        })}
                </div>
            </Box>
        </ErrorBoundary>
    );
};
