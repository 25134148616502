import React, { useMemo } from 'react';
import { Checkbox as MaterialCheckbox, useTheme } from '@material-ui/core';

export const Checkbox = (props: any) => {
    const { disabled, ...rest } = props;
    const theme = useTheme();

    const style = useMemo(
        () => ({
            color: disabled ? theme.palette.fixedPrimary.light : 'rgb(36, 49, 67)',
        }),
        [disabled],
    );
    return <MaterialCheckbox disabled={disabled} style={style} {...rest} />;
};
