import React, { useCallback, useMemo, useState } from 'react';

import { PriceForUnit } from '../PriceForUnit/PriceForUnit';
import { ProductImage } from './ProductImage';
import { Text, TextTypography } from '../Text/Text';

import { ProductDto, StoreCategory, StoreCurrentState } from 'types';
import { Icon } from '../Icon/Icon';

import { ReactComponent as GiftIcon } from 'assets/glyphs/gift.svg';
import { Button, ButtonColor } from '../Button/Button';


import { makeCheckoutAddToCartAction } from '../../state/checkout/actions';
import { useAction } from '../../core/store/store';
import { useSelector } from 'react-redux';
import { makeStoreSelector, storeSubscriptionSelector } from '../../state/store/selectors';
import { useRouterParam } from '../../core/router/router';
import { checkoutCartStoreIdSelector } from '../../state/checkout/selectors';
import { getInitialOptionsValue } from '../../pages/Store/utils';
import { makeUrl } from '../../core/utils/utils';
import { RoutePath } from '../../RoutePath';
import { authCanBuySelector, authIsoCodeSelector } from '../../state/auth/selectors';
import { CheckoutErrorDialog } from '../../pages/Store/components/CheckoutErrorDialog';
import { NoSsr, Tooltip } from '@material-ui/core';

import css from './ProductListItem.module.css';
import i18n from '../../../i18n';
import { subscriptionCurrentSelector } from 'state/subscription/selectors';

export interface ProductListProps {
    product: ProductDto;
    index: number;
    // Optional
    onProductClick?: (productId: string, categoryId: string) => void;
    storeCategory?: StoreCategory;
    draggableItem?: boolean;
}
const canBuyStoreStatuses = [StoreCurrentState.FULL, StoreCurrentState.SELLING];

export const ProductListItem = (props: ProductListProps) => {
    const { product, storeCategory, draggableItem = false } = props;
    const image = product.images && product.images.find(image => image.defaultImage);
    const imageId = image ? image.imageId : null;
    const { t } = i18n.useTranslation();

    const promoaction = product.promo?.promo;

    const action = useAction();
    const storeSlug = useRouterParam('storeSlug');
    const store = useSelector(makeStoreSelector(storeSlug));
    const isoCode = useSelector(authIsoCodeSelector);
    const cartStoreId = useSelector(checkoutCartStoreIdSelector);
    const storePath = makeUrl(RoutePath.STORE_CATALOG_TAB, { storeSlug });

    const selectedOptions = useMemo(() => getInitialOptionsValue(product?.optionGroups), [product]);

    const [checkoutErrorDialog, setCheckoutErrorDialog] = useState(false);

    const handleAddProduct = useCallback(
        (e: Event) => {
            e.stopPropagation();
            if (cartStoreId && cartStoreId !== store.storeId) {
                setCheckoutErrorDialog(true);
            } else {
                action(makeCheckoutAddToCartAction, store.storeId, product, 1, selectedOptions);
            }
        },
        [store, storePath, action, cartStoreId, selectedOptions],
    );

    const canBuy =
        useSelector(authCanBuySelector) && canBuyStoreStatuses.includes(store?.currentState) && store.openForOrders;
    const isActiveSubscription = (store?.subscription?.amount !== 0 && new Date(store?.subscription?.renewalDate) > new Date()) || canBuyStoreStatuses.includes(store?.currentState);

    const handleCheckoutErrorDialogSubmit = useCallback(() => {
        action(makeCheckoutAddToCartAction, store.storeId, product, 1, selectedOptions);
        setCheckoutErrorDialog(false);
    }, [action, store, product, selectedOptions, setCheckoutErrorDialog]);

    const handleCheckoutErrorDialogClose = useCallback(() => setCheckoutErrorDialog(false), [setCheckoutErrorDialog]);
    return (
        <div className={css.container}>
            <div
                itemType="http://schema.org/Product"
                className={css.productCard}
                onClick={e => {
                    e.preventDefault();
                    // TODO: double-check that `categoryId` is always defined
                    if (!isActiveSubscription && props.index > 2) {
                        return
                    }
                    props.onProductClick(product.productId, product.category.categoryId);
                }}
            >
                {(props.index > 2 && !isActiveSubscription) && (
                    <div>
                        <div className={css.windowLayer} />
                        <div className={css.shopWindowBadge}>
                            {t('common:showcase')}
                        </div>
                    </div>
                    )}
                {draggableItem && <div className={css.dndCoverLayer} />}
                <a itemProp="image" href={`/api/images/${imageId}`} style={{ display: 'none' }} />
                <div className={css.images}>
                    <ProductImage
                        id={imageId}
                        storeCategory={storeCategory}
                        title={product?.name}
                        draggableItem={draggableItem}
                    />
                    {promoaction && (
                        <div className={css.promotion}>
                            <Icon color={'white'} component={GiftIcon} svgSize={40} size={40} />
                        </div>
                    )}
                    {product.outOfStock && (
                        <>
                            <div className={css.outOfStock} />
                            <div className={css.outOfStockText}>{t('common:outOfStock')}</div>
                        </>
                    )}
                </div>
                <div className={css.productCardDetails}>
                    <footer>
                        <PriceForUnit
                            price={product.price}
                            unitType={product.unitType}
                            unitQuantity={product.unitQuantity}
                            textType={TextTypography.BODY_ACCENT}
                            isoCode={isoCode ?? store?.address?.isoCode}
                        />
                    </footer>
                    <Tooltip title={product?.name}>
                        <div>
                            <Text className={css.title} typography={TextTypography.BODY} itemProp="name">
                                {product?.name}
                            </Text>
                        </div>
                    </Tooltip>
                </div>
                <NoSsr>
                    {canBuy && product?.price !== 0 && !product?.outOfStock && (
                        <Button
                            type="submit"
                            color={ButtonColor.SUCCESS}
                            onClick={handleAddProduct}
                            className={css.button}
                        >
                            {t('common:toOrder')}
                        </Button>
                    )}
                </NoSsr>
            </div>

            {checkoutErrorDialog && (
                <CheckoutErrorDialog
                    onClose={handleCheckoutErrorDialogClose}
                    onSubmit={handleCheckoutErrorDialogSubmit}
                />
            )}
        </div>
    );
};
