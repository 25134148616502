import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import i18n from '../../../i18n';

import { Icon } from 'components/Icon/Icon';
import { LargeButton, LargeButtonColor } from 'components/LargeButton/LargeButton';
import { Text, TextTypography } from 'components/Text/Text';

import { ReactComponent as ExpandMoreIcon } from 'assets/icons/main-16/chevron-down--16.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/main-16/add--16.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/main-16/trash--16.svg';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    InputAdornment,
    RadioGroup,
    TextField,
} from '@material-ui/core';
import { TextControl } from 'components/TextControl/TextControl';
import { HorizontalDivider } from 'components/HorizontalDivider/HorizontalDivider';
import { makeStyles } from '@material-ui/core/styles';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { formatCurrency, selectAllTextInInputById } from 'core/utils/utils';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { useSelector } from 'react-redux';
import { authIsoCodeSelector } from '../../state/auth/selectors';
interface OptionGroupProps {
    onChange: (optionGroup: OptionGroup) => void;
    onRemove: () => void;
    index: number;
    optionGroup?: OptionGroup;
    isEditable?: boolean;
}

const useStyles = makeStyles(theme => ({
    accordionContainer: {
        width: '100%',
    },
    name: {
        marginBottom: theme.spacing(3),
    },
    optionDescription: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
    modeRadioButtons: {
        marginBottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
    },
    radioButton: {
        width: 86,
        marginRight: 4,
        marginLeft: 4,
    },
    option: {
        margin: theme.spacing(3, 0),
    },
    optionParams: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(0.5),
    },
    optionPrice: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    addOptionButton: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    addIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(1),
    },
    removeButton: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(3),
    },
}));

export enum OptionsMode {
    ALL = 'ALL',
    NUMEROUS = 'NUMEROUS',
}

interface ProductOption {
    name: string;
    price?: number;
    selectedByDefault?: boolean;
}

export interface OptionGroup {
    maxOptions: number;
    minOptions: number;
    name: string;
    options: ProductOption[];
    optionGroupMode: OptionsMode;
}

const disableUnderline = { disableUnderline: true };

export const DEFAULT_OPTION: ProductOption = { name: '', price: 0, selectedByDefault: false };

export const OptionGroup = (props: OptionGroupProps) => {
    const { t } = i18n.useTranslation();
    const { onChange, onRemove, isEditable, index } = props;
    const classes = useStyles();

    const fromInputProps = { min: 0, id: `${index}from` };
    const toInputProps = { min: 1, id: `${index}to` };

    const isoCode = useSelector(authIsoCodeSelector);

    const [name, setName] = useState<string>(props.optionGroup?.name);
    const [optionGroup, setOptionGroup] = useState<OptionGroup>(props.optionGroup);
    const [options, setOptions] = useState<ProductOption[]>(props.optionGroup?.options || [{ ...DEFAULT_OPTION }]);

    const handleSetOptionGroup = useCallback(
        (optionGroup: OptionGroup) => {
            setOptionGroup(optionGroup);
            onChange(optionGroup);
        },
        [setOptionGroup, onChange],
    );

    const handleSetOptions = useCallback(
        (options: ProductOption[]) => {
            setOptions(options);
            handleSetOptionGroup({ ...optionGroup, options });
        },
        [optionGroup, setOptions, handleSetOptionGroup],
    );

    const handleChangeOptionsName = useCallback(
        (i: number, value: string) => {
            const newOptions: ProductOption[] = [];
            options.forEach(el => newOptions.push({ ...el }));
            newOptions[i].name = value;
            handleSetOptions(newOptions);
        },
        [options, handleSetOptions],
    );

    const handleChangeOptionsPrice = useCallback(
        (i: number, value: number) => {
            const newOptions: ProductOption[] = [];
            options.forEach(el => newOptions.push({ ...el }));
            newOptions[i].price = Number(value);
            handleSetOptions(newOptions);
        },
        [options, handleSetOptions],
    );

    const handleAddOption = useCallback(() => {
        const newOptions: ProductOption[] = [];
        options.forEach(el => newOptions.push({ ...el }));
        newOptions.push({ ...DEFAULT_OPTION });
        handleSetOptions(newOptions);
    }, [options, handleSetOptions]);

    const handleRemoveOption = useCallback(
        index => {
            if (options.length !== 1) {
                const newOptions = options.filter((el, i) => i !== index);
                handleSetOptions(newOptions);
            }
        },
        [options, handleSetOptions],
    );
    const handleSetName = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value);
            handleSetOptionGroup({ ...optionGroup, name: e.target.value });
        },
        [setName, handleSetOptionGroup, optionGroup],
    );

    const isRadioSelection =
        optionGroup.optionGroupMode === OptionsMode.NUMEROUS &&
        optionGroup.minOptions === 1 &&
        optionGroup.maxOptions === 1;

    // const resetDefaultValue = useCallback(() => {
    //     const newOptions: any[] = [];
    //     options.forEach((o: any, i) => {
    //         if (i === 0) {
    //             newOptions.push({
    //                 ...o,
    //                 selectedByDefault: isRadioSelection,
    //             });
    //         } else {
    //             newOptions.push({ ...o, selectedByDefault: false });
    //         }
    //     });
    //     handleSetOptions(newOptions);
    // }, [isRadioSelection, options, handleSetOptions]);

    const handleSetMinOptions = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = Number(e.target.value);
            handleSetOptionGroup({
                ...optionGroup,
                minOptions: value > fromInputProps.min ? value : fromInputProps.min,
            });
            // resetDefaultValue();
        },
        [handleSetOptionGroup, optionGroup],
    );

    const handleSetMaxOptions = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = Number(e.target.value);
            handleSetOptionGroup({
                ...optionGroup,
                maxOptions: value > toInputProps.min ? value : toInputProps.min,
            });
            // resetDefaultValue();
        },
        [handleSetOptionGroup, optionGroup],
    );

    const handleSetMode = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handleSetOptionGroup({ ...optionGroup, optionGroupMode: e.target.value as OptionsMode });
            // resetDefaultValue();
        },
        [handleSetOptionGroup, optionGroup],
    );

    const handleDefaultValueChange = useCallback(
        (e: any, index: number) => {
            const { checked } = e.target;
            const newOptions: ProductOption[] = [];
            options.forEach((o, i) => {
                if (index === i) {
                    newOptions.push({
                        ...o,
                        selectedByDefault: checked,
                    });
                } else {
                    const option = isRadioSelection ? { ...o, selectedByDefault: false } : o;
                    newOptions.push(option);
                }
            });
            handleSetOptions(newOptions);
        },
        [isRadioSelection, options, handleSetOptions],
    );

    return (
        <div>
            <Accordion defaultExpanded={!isEditable} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Text typography={TextTypography.LEAD_ACCENT}>{t(name)}</Text>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.accordionContainer}>
                        <div className={classes.name}>
                            <TextControl
                                id={`groupName${index}`}
                                label={t('onboarding:card.groupName')}
                                value={t(name)}
                                onClick={() => selectAllTextInInputById(`groupName${index}`)}
                                onChange={handleSetName}
                                disableAutocomplete={true}
                                autoFocus={false}
                            />
                        </div>
                        <HorizontalDivider />
                        <div className={classes.optionDescription}>
                            <Text typography={TextTypography.PARA}>{t('onboarding:card.optionsCount')}</Text>
                        </div>
                        <RadioGroup
                            className={classes.modeRadioButtons}
                            name={'deliveryType'}
                            style={{ width: '100%' }}
                            value={optionGroup.optionGroupMode}
                            onChange={handleSetMode}
                        >
                            <FormControlLabel
                                value={OptionsMode.ALL}
                                control={<RadioButton />}
                                label={t('onboarding:card.allOptions')}
                            />
                            <FormControlLabel
                                value={OptionsMode.NUMEROUS}
                                control={<RadioButton />}
                                label={
                                    <>
                                        <TextField
                                            className={classes.radioButton}
                                            type="number"
                                            disabled={optionGroup.optionGroupMode === OptionsMode.ALL}
                                            inputProps={fromInputProps}
                                            InputProps={{
                                                ...disableUnderline,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {t('onboarding:card.from')}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onClick={() => selectAllTextInInputById(fromInputProps.id)}
                                            value={optionGroup.minOptions}
                                            onChange={handleSetMinOptions}
                                        />
                                        <TextField
                                            className={classes.radioButton}
                                            type="number"
                                            disabled={optionGroup.optionGroupMode === OptionsMode.ALL}
                                            inputProps={toInputProps}
                                            InputProps={{
                                                ...disableUnderline,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {t('onboarding:card.to')}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={optionGroup.maxOptions}
                                            onClick={() => selectAllTextInInputById(toInputProps.id)}
                                            onChange={handleSetMaxOptions}
                                        />
                                    </>
                                }
                            />
                        </RadioGroup>
                        <HorizontalDivider />
                        <div>
                            {options.map((option, index) => {
                                return (
                                    <div key={index} className={classes.option}>
                                        <div className={classes.optionParams}>
                                            <TextControl
                                                value={t(options[index].name)}
                                                label={t('onboarding:card.optionName')}
                                                onChange={e => handleChangeOptionsName(index, e.target.value)}
                                                disableAutocomplete={true}
                                                error={!options[index].name && t('onboarding:card:optionNameError')}
                                                autoFocus={false}
                                            />
                                            <TextField
                                                id={`${props.index}price${index}`}
                                                className={classes.optionPrice}
                                                InputProps={disableUnderline}
                                                value={options[index].price}
                                                onClick={() => selectAllTextInInputById(`${props.index}price${index}`)}
                                                type="number"
                                                variant="filled"
                                                size="small"
                                                label={
                                                    t('onboarding:card.priceOptions') + formatCurrency(null, isoCode)
                                                }
                                                autoFocus={false}
                                                onChange={e => handleChangeOptionsPrice(index, Number(e.target.value))}
                                            />
                                            <div onClick={() => handleRemoveOption(index)}>
                                                <Icon
                                                    component={TrashIcon}
                                                    size={16}
                                                    svgSize={16}
                                                    color={options.length !== 1 ? '#EA1103' : '#243143'}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            {isRadioSelection ? (
                                                <RadioButton
                                                    checked={(option as any).selectedByDefault}
                                                    onChange={(e: any) => handleDefaultValueChange(e, index)}
                                                />
                                            ) : (
                                                <Checkbox
                                                    checked={(option as any).selectedByDefault}
                                                    onChange={(e: any) => handleDefaultValueChange(e, index)}
                                                />
                                            )}
                                            {t('onboarding:card.isDefaultOption')}
                                        </div>
                                    </div>
                                );
                            })}
                            <div onClick={handleAddOption} className={classes.addOptionButton}>
                                <div className={classes.addIcon}>
                                    <Icon component={PlusIcon} size={16} svgSize={16} />
                                </div>
                                <Text typography={TextTypography.PARA_ACCENT}>{t('onboarding:card.addOption')}</Text>
                            </div>
                        </div>
                        <div className={classes.removeButton} onClick={onRemove}>
                            <LargeButton color={LargeButtonColor.ERROR}>{t('onboarding:card.removeGroup')}</LargeButton>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};
