import React, { FC, FocusEvent, useCallback, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import i18n from '../../../i18n';
import { EventOrValueHandler, WrappedFieldProps } from 'redux-form';
import { DateTime } from 'luxon';

export interface DatePickerControlOwnProps {
    label: string;
    // Optional:
    value?: DateTime | string;
    onChange?: (date: any) => void;
    onBlur?: EventOrValueHandler<FocusEvent<any>>;
    isDisabled?: boolean;
    disableFuture?: boolean;
    disablePast?: boolean;
    enableToolbar?: boolean;
    format?: string;
    minDate?: Date;
    required?: boolean;
    error?: string;
}

export const DatePickerControl: FC<DatePickerControlOwnProps> = props => {
    const { value, onChange, isDisabled, error, format, onBlur, enableToolbar, ...rest } = props;
    const [date, setDate] = useState(value);

    const handleOnBlurAction = useCallback(() => {
        if (onBlur) {
            onBlur(date);
        }
    }, [date, onBlur]);
    const handleOnChangeAction = useCallback(
        (date: DateTime, displayedValue: string) => {
            onChange(!displayedValue ? null : date);
            setDate(date);
        },
        [onChange, setDate],
    );
    return (
        <KeyboardDatePicker
            disableToolbar={!enableToolbar}
            autoOk
            value={value}
            format={format || 'dd.MM.yyyy'}
            variant="inline"
            inputVariant="filled"
            InputProps={{
                disableUnderline: true,
            }}
            disabled={isDisabled}
            error={!!error}
            helperText={error}
            {...rest}
            onBlur={handleOnBlurAction}
            onChange={handleOnChangeAction}
        />
    );
};

export type DatePickerControlProps = DatePickerControlOwnProps & WrappedFieldProps;

export const DatePickerControlField = (props: DatePickerControlProps) => {
    const { t } = i18n.useTranslation();
    const {
        input,
        meta: { touched, invalid, error },
        ...rest
    } = props;
    return <DatePickerControl error={touched && invalid ? t(error) : undefined} {...input} {...rest} />;
};
