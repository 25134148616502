import { getRequest, postRequest } from 'core/axios/axios';
import { RoutePath } from 'RoutePath';
import { env } from '../../env';
import { makeUrl } from '../utils/utils';
import { InstagramPost } from '../../components/InstagramGallery/InstagramGallery';

const INSTAGRAM_ACTION_KEY = 'instagram_connect';

export const authorizeInstagram = () => {
    sessionStorage.setItem(INSTAGRAM_ACTION_KEY, 'true');

    const { origin } = window.location;
    const url = makeUrl(
        'https://api.instagram.com/oauth/authorize',
        {},
        {
            response_type: 'code',
            client_id: env.instagramAccountId,
            redirect_uri: encodeURIComponent(origin + RoutePath.VENDOR_MENU_STORE_INFO),
            scope: encodeURIComponent('user_profile,user_media'),
        },
    );

    location.assign(url);
};

export const connectInstagram = async (storeId: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const instagramCode = urlParams.get('code');
    const shouldConnectInstagram = sessionStorage.getItem(INSTAGRAM_ACTION_KEY);
    if (instagramCode && shouldConnectInstagram) {
        sessionStorage.removeItem(INSTAGRAM_ACTION_KEY);
        await postRequest(`/api/instagram?code=${instagramCode}&storeId=${storeId}`, {});
    }
};

export const getInstagramPosts = (storeId: string): Promise<InstagramPost[]> => {
    return getRequest(`/api/instagram/posts?storeId=${storeId}`).then(response => response.data);
};

export const updateInstagramPosts = async (storeId: string) => {
    await postRequest(`/api/instagram/posts?storeId=${storeId}`, {});
};

export const getConnectedInstagram = (storeId: string): Promise<boolean> => {
    return getRequest(`/api/instagram?storeId=${storeId}`).then(response => response.data);
};
